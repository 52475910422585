import { sortBy } from 'lodash';

import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export enum CountryPhoneCodes {
  UK = 44,
  USA = 1,
  Algeria = 213,
  Andorra = 376,
  Angola = 244,
  Anguilla = 1264,
  AntiguaBarbuda = 1268,
  Argentina = 54,
  Armenia = 374,
  Aruba = 297,
  Australia = 61,
  Austria = 43,
  Azerbaijan = 994,
  Bahamas = 1242,
  Bahrain = 973,
  Bangladesh = 880,
  Barbados = 1246,
  Belarus = 375,
  Belgium = 32,
  Belize = 501,
  Benin = 229,
  Bermuda = 1441,
  Bhutan = 975,
  Bolivia = 591,
  BosniaHerzegovina = 387,
  Botswana = 267,
  Brazil = 55,
  Brunei = 673,
  Bulgaria = 359,
  BurkinaFaso = 226,
  Burundi = 257,
  Cambodia = 855,
  Cameroon = 237,
  Canada = 1,
  CapeVerdeIslands = 238,
  CaymanIslands = 1345,
  CentralAfricanRepublic = 236,
  Chile = 56,
  China = 86,
  Colombia = 57,
  Comoros = 269,
  Congo = 242,
  CookIslands = 682,
  CostaRica = 506,
  Croatia = 385,
  Cuba = 53,
  CyprusNorth = 90392,
  CyprusSouth = 357,
  CzechRepublic = 42,
  Denmark = 45,
  Djibouti = 253,
  Dominica = 1809,
  DominicanRepublic = 1809,
  Ecuador = 593,
  Egypt = 20,
  ElSalvador = 503,
  EquatorialGuinea = 240,
  Eritrea = 291,
  Estonia = 372,
  Ethiopia = 251,
  FalklandIslands = 500,
  FaroeIslands = 298,
  Fiji = 679,
  Finland = 358,
  France = 33,
  FrenchGuiana = 594,
  FrenchPolynesia = 689,
  Gabon = 241,
  Gambia = 220,
  Georgia = 7880,
  Germany = 49,
  Ghana = 233,
  Gibraltar = 350,
  Greece = 30,
  Greenland = 299,
  Grenada = 1473,
  Guadeloupe = 590,
  Guam = 671,
  Guatemala = 502,
  Guinea = 224,
  GuineaBissau = 245,
  Guyana = 592,
  Haiti = 509,
  Honduras = 504,
  HongKong = 852,
  Hungary = 36,
  Iceland = 354,
  India = 91,
  Indonesia = 62,
  Iran = 98,
  Iraq = 964,
  Ireland = 353,
  Israel = 972,
  Italy = 39,
  Jamaica = 1876,
  Japan = 81,
  Jordan = 962,
  Kazakhstan = 7,
  Kenya = 254,
  Kiribati = 686,
  KoreaNorth = 850,
  KoreaSouth = 82,
  Kuwait = 965,
  Kyrgyzstan = 996,
  Laos = 856,
  Latvia = 371,
  Lebanon = 961,
  Lesotho = 266,
  Liberia = 231,
  Libya = 218,
  Liechtenstein = 417,
  Lithuania = 370,
  Luxembourg = 352,
  Macao = 853,
  Macedonia = 389,
  Madagascar = 261,
  Malawi = 265,
  Malaysia = 60,
  Maldives = 960,
  Mali = 223,
  Malta = 356,
  MarshallIslands = 692,
  Martinique = 596,
  Mauritania = 222,
  Mauritius = 230,
  Mayotte = 269,
  Mexico = 52,
  Micronesia = 691,
  Moldova = 373,
  Monaco = 377,
  Mongolia = 976,
  Montserrat = 1664,
  Morocco = 212,
  Mozambique = 258,
  Myanmar = 95,
  Namibia = 264,
  Nauru = 674,
  Nepal = 977,
  Netherlands = 31,
  NewCaledonia = 687,
  NewZealand = 64,
  Nicaragua = 505,
  Niger = 227,
  Nigeria = 234,
  Niue = 683,
  NorfolkIslands = 672,
  NorthernMarianas = 670,
  Norway = 47,
  Oman = 968,
  Palau = 680,
  Panama = 507,
  PapuaNewGuinea = 675,
  Paraguay = 595,
  Peru = 51,
  Philippines = 63,
  Poland = 48,
  Portugal = 351,
  PuertoRico = 1787,
  Qatar = 974,
  Reunion = 262,
  Romania = 40,
  Russia = 7,
  Rwanda = 250,
  SanMarino = 378,
  SaoTomePrincipe = 239,
  SaudiArabia = 966,
  Senegal = 221,
  Serbia = 381,
  Seychelles = 248,
  SierraLeone = 232,
  Singapore = 65,
  SlovakRepublic = 421,
  Slovenia = 386,
  SolomonIslands = 677,
  Somalia = 252,
  SouthAfrica = 27,
  Spain = 34,
  SriLanka = 94,
  StHelena = 290,
  StKitts = 1869,
  StLucia = 1758,
  Sudan = 249,
  Suriname = 597,
  Swaziland = 268,
  Sweden = 46,
  Switzerland = 41,
  Syria = 963,
  Taiwan = 886,
  Tajikstan = 7,
  Thailand = 66,
  Togo = 228,
  Tonga = 676,
  TrinidadTobago = 1868,
  Tunisia = 216,
  Turkey = 90,
  Turkmenistan = 7,
  TurkmenistanB = 993,
  TurksCaicosIslands = 1649,
  Tuvalu = 688,
  Uganda = 256,
  Ukraine = 380,
  UnitedArabEmirates = 971,
  Uruguay = 598,
  Uzbekistan = 7,
  Vanuatu = 678,
  VaticanCity = 379,
  Venezuela = 58,
  Vietnam = 84,
  VirginIslandsBritish = 1284,
  VirginIslandsUS = 1340,
  WallisFutuna = 681,
  YemenNorth = 969,
  YemenSouth = 967,
  Zambia = 260,
  Zimbabwe = 263,
}

const sortedKeys = Object.keys(CountryPhoneCodes)
  .filter(k => typeof CountryPhoneCodes[k as any] === 'number')
  .sort((a, b) => a.localeCompare(b));

const groupedCodes: Record<number, string[]> = {};

sortedKeys.forEach(country => {
  const code = CountryPhoneCodes[country as keyof typeof CountryPhoneCodes];
  if (!groupedCodes[code]) {
    groupedCodes[code] = [];
  }
  groupedCodes[code].push(country);
});

export const CountryCodes: IRadioButtonOption<number>[] = sortBy(
  Object.entries(groupedCodes),
  ([, countries]) => countries[0]
).map(([code, countries]) => ({
  label: `+${code} (${countries
    .reverse()
    .join(', ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .trim()})`,
  value: Number(code),
  displayValue: `+${code}`,
}));

export const LatviaValidPhoneNumberLength = 8;
export const FinlandValidPhoneNumberLength = 9;
export const ValidPhoneNumberLength_10 = 10;
