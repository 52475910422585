import { Injectable } from '@angular/core';

import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';

import { BooleanQuestion, EmojiDetails, ResidentInspectionItem, SurveyPage } from '../pages/inspect/inspect.model';

@Injectable({
  providedIn: 'root',
})
export class ResidentInspectionsService {
  generateAnswer(items: ResidentInspectionItem[], itemUuid: string): string {
    const updatedSurvey = items[0]?.surveyJSON;

    if (!updatedSurvey) return;

    items.find((updatedItem: ResidentInspectionItem) => {
      const matchingPage = updatedSurvey.pages.find((page: SurveyPage) => page.uuid === updatedItem.uuid);
      if (matchingPage) {
        matchingPage.elements.find((element, elementIndex) => {
          switch (element.type) {
            case 'comment':
              matchingPage.elements[elementIndex].value = updatedItem.comment || '';
              if (updatedItem.uuid === itemUuid) {
                matchingPage.elements[elementIndex].value = updatedItem.comment || ' ';
              }
              break;
            case 'boolean':
              if (updatedItem.uuid === itemUuid) {
                const updatedBoolean = updatedItem.booleanQuestions?.find(
                  (q: BooleanQuestion) => q.guid === element.guid
                );
                if (updatedBoolean && updatedBoolean.answer !== 'undefined') {
                  matchingPage.elements[elementIndex].value = String(updatedBoolean.answer) || '';
                }
              }
              break;
            case 'propertystate':
              if (updatedItem.uuid === itemUuid) {
                if (element.items) {
                  updatedItem.emojiDetails?.forEach((updatedEmoji: EmojiDetails) => {
                    const matchingItem = element.items.find(
                      (itemWrapper: ResidentInspectionItem) =>
                        itemWrapper.item.emoji === updatedEmoji.emojiCode && updatedEmoji.isDefault
                    );
                    if (matchingItem && matchingItem.item.id) {
                      matchingPage.elements[elementIndex].value = String(matchingItem.item.id);
                    }
                  });
                }
              }
              break;
            case 'file':
              if (Array.isArray(updatedItem.fileUploadIds)) {
                matchingPage.mainImage = updatedItem.fileUploadIds.map(id => ({ fileUploadId: id }));
              }
              break;
          }
        });
      }
    });

    return JSON.stringify(updatedSurvey, (key, value) => (Array.isArray(value) && value.length === 0 ? null : value));
  }

  generateDefaultAnswer(items: ResidentInspectionItem[]): string {
    const defaultSurvey = items[0]?.surveyJSON;

    if (!defaultSurvey) return;

    items.forEach((defaultItem: ResidentInspectionItem) => {
      const matchingPage = defaultSurvey.pages.find((page: SurveyPage) => page.uuid === defaultItem.uuid);
      if (matchingPage) {
        matchingPage.elements.forEach((element, elementIndex) => {
          switch (element.type) {
            case 'comment':
              if (defaultItem.comment !== undefined && defaultItem.comment !== '') {
                matchingPage.elements[elementIndex].value = defaultItem.comment;
              }
              break;
            case 'boolean':
              const updatedBoolean = defaultItem.booleanQuestions?.find(
                (q: BooleanQuestion) => q.guid === element.guid
              );
              if (
                updatedBoolean &&
                updatedBoolean.answer !== 'undefined' &&
                updatedBoolean.answer !== null &&
                updatedBoolean.answer !== ''
              ) {
                matchingPage.elements[elementIndex].value = String(updatedBoolean.answer);
              } else {
                if (
                  updatedBoolean &&
                  updatedBoolean.defaultAnswer !== 'undefined' &&
                  updatedBoolean.defaultAnswer !== null &&
                  updatedBoolean.defaultAnswer !== ''
                ) {
                  matchingPage.elements[elementIndex].value = String(updatedBoolean.defaultAnswer);
                }
              }
              break;
            case 'propertystate':
              if (element.items) {
                let matchingEmojiSet = false;
                defaultItem.emojiDetails?.forEach((updatedEmoji: EmojiDetails) => {
                  const matchingItem = element.items.find(
                    (itemWrapper: ResidentInspectionItem) =>
                      itemWrapper.item.emoji === updatedEmoji.emojiCode && updatedEmoji.isDefault
                  );
                  if (matchingItem && matchingItem.item.id) {
                    matchingPage.elements[elementIndex].value = String(matchingItem.item.id);
                    matchingEmojiSet = true;
                  }
                });
                if (!matchingEmojiSet) {
                  matchingPage.elements[elementIndex].value = matchingPage.elements[elementIndex].value || null;
                }
              }
              break;
            case 'file':
              if (Array.isArray(defaultItem.fileUploadIds) && defaultItem.fileUploadIds.length > 0) {
                matchingPage.mainImage = defaultItem.fileUploadIds.map(id => ({ fileUploadId: id }));
              }
              break;
          }
        });
      }
    });

    return JSON.stringify(defaultSurvey, (key, value) => (Array.isArray(value) && value.length === 0 ? null : value));
  }

  getCompletedItem(item: ResidentInspectionItem): boolean {
    const hasValidValue = item.emojiDetails?.length === 0 || !!item.value;
    const chosenEmoji = item.emojiDetails?.find(emoji => emoji.isDefault);

    if (!hasValidValue) return false;

    const allBooleanQuestionsAnswered =
      item.booleanQuestions?.length === 0 ||
      item.booleanQuestions.every(q => q.answer === 'true' || q.answer === 'false');

    if (!allBooleanQuestionsAnswered) return false;

    let emojiRequiresPhoto = true;
    let emojiRequiresComment = true;

    if (item.emojiDetails.length > 0) {
      emojiRequiresPhoto = chosenEmoji?.onSelect?.includes('photoRequired') || false;
      emojiRequiresComment = chosenEmoji?.onSelect?.includes('commentRequired') || false;
    } else {
      emojiRequiresPhoto = item.photoRequired;
      emojiRequiresComment = item.commentRequired;
    }

    const hasValidFileUploads = emojiRequiresPhoto ? item.fileUploadIds?.length > 0 : true;

    if (!hasValidFileUploads) return false;

    const hasValidComment = emojiRequiresComment ? item.comment && item.comment.trim() !== '' : true;

    if (!hasValidComment) return false;

    const hasAnything =
      item.booleanQuestions?.length === 0 && item.emojiDetails?.length === 0
        ? item.fileUploadIds?.length > 0 || !!item.comment
        : true;

    return hasValidValue && allBooleanQuestionsAnswered && hasValidFileUploads && hasValidComment && hasAnything;
  }

  isNotReadyToSubmitItem(item: ResidentInspectionItem): boolean {
    const hasValue =
      item.emojiDetails.length === 0
        ? true
        : item.emojiDetails.length > 0
        ? item.emojiDetails?.some((emoji: EmojiDetails) => emoji.isDefault === true)
        : false;

    if (!hasValue) return true;

    const hasAnswers =
      item.booleanQuestions.length === 0
        ? true
        : item.booleanQuestions.length > 0
        ? item.booleanQuestions.every(q => q.answer === 'true' || q.answer === 'false') ||
          item.booleanQuestions.every(q => q.defaultAnswer === 'true' || q.defaultAnswer === 'false')
        : false;

    if (!hasAnswers) return true;

    let hasPhotos = false;

    if (item.emojiDetails.length === 0) {
      hasPhotos = item.fileUploadIds.length > 0 ? true : item.photoRequired ? false : true;
    } else {
      hasPhotos =
        item.fileUploadIds.length > 0
          ? true
          : item.emojiDetails
              ?.find((emoji: EmojiDetails) => emoji.isDefault === true)
              ?.onSelect?.includes('photoRequired')
          ? item.fileUploadIds.length > 0
          : true;
    }

    if (!hasPhotos) return true;

    let hasComment = false;

    if (item.emojiDetails.length === 0) {
      hasComment = item.comment && item.comment !== '' ? true : item.commentRequired ? false : true;
    } else {
      hasComment =
        item.comment && item.comment !== ''
          ? true
          : item.emojiDetails
              ?.find((emoji: EmojiDetails) => emoji.isDefault === true)
              ?.onSelect?.includes('commentRequired')
          ? item.comment && item.comment !== ''
          : true;
    }

    if (!hasComment) return true;

    return !(hasValue && hasAnswers && hasPhotos && hasComment);
  }

  generateItemSpecificKey(item: ResidentInspectionItem): string {
    const inspectionId = item.inspectionId;
    const areaId = item.id;
    const itemId = item.uuid;
    return `${LocalStorageDataEnum.UNSAVED_DATA}-${inspectionId}-${areaId}-${itemId}`;
  }
}
