import { createAction, props } from '@ngrx/store';

import { TurnoverSummaryFilters } from '@app/services/turnovers.service';
import { TurnoverStepTimer } from '@main-application/turnovers/interfaces/timers.interface';
import { ValidationParams } from '@main-application/turnovers/interfaces/validation-params.interface';
import { TurnoverAttachmentType } from '@shared/enums/attachment-type';
import { TicketType } from '@shared/enums/ticket-type';
import { TurnoverTransitionSource } from '@shared/enums/turnover-transition-source';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestApplianceModel } from '@shared/interfaces/appliance.interface';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { RestBidModel, RestTypedBidAttachment } from '@shared/interfaces/bid.interface';
import { RestWorkflowStepCommentModel } from '@shared/interfaces/comments.interface';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { KanbanListItem } from '@shared/interfaces/kanban-list-item.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestWorkflowStepAssignmentModel } from '@shared/interfaces/rest-workflow-step-assignment-model.interface';
import {
  PostTurnoverModel,
  RestTicketModel,
  RestTurnoverDataModel,
  RestTypedTicketAttachment,
  RestTypedTurnoverAttachment,
  RestWorkflowInstanceModel,
  TurnoverStepInfo,
  UpdateTypedAttachmentToEntityModel,
} from '@shared/interfaces/turnover.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { WorkflowAction } from '@shared/interfaces/workflow-action.interface';
import {
  SummaryStatsGroup,
  TurnoversSummaryStatsDetails,
} from '@ui-components/components/summary-expandable-table/summary-expandable-table.model';

/**
 * Create new Turnover
 */
export const createNewTurnover = createAction(
  '[Turnover] Create new Turnover',
  props<{ postTurnoverModel: PostTurnoverModel }>()
);
export const createNewTurnoverSuccess = createAction(
  '[Turnover] Create new Turnover success',
  props<{ turnoverData: RestTurnoverDataModel }>()
);
export const createNewTurnoverFailed = createAction('[Turnover] Create new Turnover failed');
export const resetCreateNewTurnover = createAction('[Turnover] Reset create new Turnover');

/**
 * Get Turnover Data
 */
export const clearTurnoverData = createAction('[Turnover] clear turnover data');
export const getTurnoverData = createAction('[Turnover] get turnover data', props<{ turnoverId: number }>());
export const getTurnoverDataSuccess = createAction(
  '[Turnover] get turnover data success',
  props<{ turnoverData: RestTurnoverDataModel }>()
);
export const getTurnoverDataFailed = createAction('[Turnover] get turnover data failed');

/**
 * set current workflow step
 */
export const setCurrentWorkflowStep = createAction(
  '[Turnover] set current workflow step',
  props<{ currentWorkflowStep: WorkflowStepEnumType }>()
);

export const setCurrentWorkflowStepModel = createAction(
  '[Turnover] set current workflow step model',
  props<{ currentWorkflowStepModel: RestWorkflowInstanceModel }>()
);

export const resetWorkflowStepStatus = createAction('[Turnover] set current workflow step');

/**
 * Get Turnover Data
 */
export const setTurnoverData = createAction(
  '[Turnover] set turnover data',
  props<{ turnoverData: RestTurnoverDataModel }>()
);
export const setTurnoverDataSuccess = createAction('[Turnover] set turnover data success');
export const setTurnoverDataFailed = createAction('[Turnover] set turnover data failed');

/**
 * Set workflow step
 */
export const setWorkflowStepSuccess = createAction(
  '[Turnover] set workflow step success',
  props<{ turnoverId: number; nextWorkflowStep: WorkflowStepEnumType }>()
);
/**
 * set appliance type list
 */
export const setApplianceTypeList = createAction(
  '[Turnover] set appliance type list',
  props<{ applianceTypeList: IRadioButtonOption<number>[] }>()
);

/**
 * set color type list
 */
export const setColorTypeList = createAction(
  '[Turnover] set color type list',
  props<{ colorTypeList: IRadioButtonOption<number>[] }>()
);

/**
 * set user list
 */
export const setUserList = createAction(
  '[Turnover] set user list',
  props<{ userList: IRadioButtonOption<number>[] }>()
);

export const setUserListModel = createAction(
  '[Turnover] set user list model',
  props<{ userListModel: RestUserModel[] }>()
);

/**
 * set all user list
 */
export const setAllUserRadioList = createAction(
  '[Turnover] set All User Radio List',
  props<{ allUserRadioList: IRadioButtonOption<number>[] }>()
);
export const setAllUserList = createAction('[Turnover] set All User List', props<{ allUserList: RestUserModel[] }>());

/**
 * set company radio list
 */
export const setCompanyRadioList = createAction(
  '[Turnover] set company radio List',
  props<{ companyRadioList: IRadioButtonOption<number>[] }>()
);

export const setCompanyNameRadioList = createAction(
  '[Turnover] set company name radio List',
  props<{ companyNameRadioList: IRadioButtonOption<number>[] }>()
);

export const setCompanyEmailRadioList = createAction(
  '[Turnover] set company email radio List',
  props<{ companyEmailRadioList: IRadioButtonOption<number>[] }>()
);

/**
 * set attachment to turnover
 */
export const setAttachmentToTurnover = createAction(
  '[Turnover] attachment to turnover',
  props<{ attachmentToEntity: UpdateTypedAttachmentToEntityModel; preventReloadList?: boolean }>()
);
export const setAttachmentsToTurnover = createAction(
  '[Turnover] set attachments to turnover',
  props<{
    turnoverId: number;
    attachmentType: TurnoverAttachmentType;
    attachments: UpdateTypedAttachmentToEntityModel[];
    preventReloadList?: boolean;
  }>()
);
export const setAttachmentToTurnoverSuccess = createAction(
  '[Turnover] attachment to turnover Success',
  props<{ addedAttachment: RestGenericTypedAttachment }>()
);
export const setAttachmentToTurnoverFailed = createAction('[Turnover] attachment to turnover Failed');
export const clearAddedAttachment = createAction('[Turnover] clear Added Attachment');

/**
 * update turnover attachment
 */
export const updateTurnoverAttachment = createAction(
  '[Turnover] update turnover attachment',
  props<{ attachmentToEntity: UpdateTypedAttachmentToEntityModel }>()
);
export const updateTurnoverAttachmentSuccess = createAction('[Turnover] update turnover attachment Success');
export const updateTurnoverAttachmentFailed = createAction('[Turnover] update turnover attachment Failed');

/**
 * get turnover tickets
 */
export const getTurnoverTickets = createAction('[Turnover] get turnover tickets', props<{ turnoverId: number }>());
export const getTurnoverTicketsSuccess = createAction(
  '[Turnover] get turnover tickets success',
  props<{ turnoverTicketList: RestTicketModel[] }>()
);
export const getTurnoverTicketsFailed = createAction('[Turnover] get turnover tickets error');

/**
 * add new turnover ticket
 */
export const addNewTurnoverTicket = createAction(
  '[Turnover] add new turnover tickets',
  props<{ turnoverTicket: Partial<RestTicketModel> }>()
);
export const addNewTurnoverTicketSuccess = createAction(
  '[Turnover] add new turnover tickets success',
  props<{ newTurnoverTicket: RestTicketModel }>()
);
export const addNewTurnoverTicketFailed = createAction('[Turnover] add new turnover tickets error');
export const resetNewTurnoverTicket = createAction('[Turnover] reset new turnover ticket');

/**
 * update turnover ticket
 */
export const updateTurnoverTicket = createAction(
  '[Turnover] update turnover tickets',
  props<{ turnoverTicket: RestTicketModel }>()
);
export const updateTurnoverTicketSuccess = createAction(
  '[Turnover] update turnover tickets success',
  props<{ editedTicket: RestTicketModel }>()
);
export const updateTurnoverTicketFailed = createAction('[Turnover] update turnover tickets error');

/**
 * update ticket preview
 */
export const updateTicketPreview = createAction(
  '[Turnover] update turnover preview',
  props<{ turnoverTicket: RestTicketModel }>()
);
export const updateTicketPreviewSuccess = createAction(
  '[Turnover] update turnover preview success',
  props<{ editedTicket: RestTicketModel }>()
);
export const updateTicketPreviewFailed = createAction('[Turnover] update turnover preview error');

/**
 * get selected ticket
 */
export const getSelectedTicket = createAction('[Turnover] get selected ticket', props<{ ticketId: number }>());
export const getSelectedTicketSuccess = createAction(
  '[Turnover] get selected ticket success',
  props<{ ticket: RestTicketModel }>()
);
export const getSelectedTicketFailed = createAction('[Turnover] get selected ticket error');

/**
 * delete turnover ticket
 */
export const deleteTurnoverTicket = createAction('[Turnover] delete turnover tickets', props<{ ticketId: number }>());
export const deleteTurnoverTicketSuccess = createAction('[Turnover] delete turnover ticket success');
export const deleteTurnoverTicketFailed = createAction('[Turnover] delete turnover ticket error');

/**
 * delete turnover ticket attachment
 */
export const deleteTurnoverTicketAttachment = createAction(
  '[Turnover] delete turnover tickets attachment',
  props<{ ticketId: number; attachment: RestGenericTypedAttachment; ticketType: TicketType }>()
);
export const deleteTurnoverTicketAttachmentSuccess = createAction(
  '[Turnover] delete turnover ticket attachment success'
);
export const deleteTurnoverTicketAttachmentFailed = createAction('[Turnover] delete turnover ticket attachment error');

/**
 * set turnover attachment
 */
export const setTicketAttachment = createAction(
  '[Turnover] set ticket attachment',
  props<{ ticketType: TicketType; attachmentToEntity: UpdateTypedAttachmentToEntityModel }>()
);
export const setTicketAttachmentSuccess = createAction(
  '[Turnover] set ticket attachment success',
  props<{ addedTicketAttachment: RestTypedTicketAttachment }>()
);
export const setTicketAttachmentFail = createAction('[Turnover] set ticket attachment fail');
export const clearAddedTicketAttachment = createAction('[Turnover] clear Added ticket Attachment');

/**
 * get company list
 */
export const getCompanyList = createAction('[Turnover] get company list');
export const getCompanyListSuccess = createAction(
  '[Turnover] get company list success',
  props<{ companyList: RestCompanyModel[] }>()
);
export const getCompanyListFail = createAction('[Turnover] get company list fail');

/**
 * BIDS
 */

/**
 * get bid turnover list
 */
export const getBidTurnoverList = createAction('[Turnover] get bid turnover list', props<{ turnoverId: number }>());
export const getBidTurnoverListSuccess = createAction(
  '[Turnover] get bid turnover list success',
  props<{ bidList: RestBidModel[] }>()
);
export const getBidTurnoverListFail = createAction('[Turnover] get bid turnover list fail');

/**
 * get bid company list
 */
export const getBidCompanyList = createAction('[Turnover] get bid Company list', props<{ companyId: number }>());
export const getBidCompanyListSuccess = createAction(
  '[Turnover] get bid Company list success',
  props<{ bidList: RestBidModel[] }>()
);
export const getBidCompanyListFail = createAction('[Turnover] get bid Company list fail');

/**
 * get all bid list
 */
export const getAllBidList = createAction('[Turnover] get all bid list');
export const getAllBidListSuccess = createAction(
  '[Turnover] get all bid list success',
  props<{ bidList: RestBidModel[] }>()
);
export const getAllBidListFail = createAction('[Turnover] get all bid list fail');

/**
 * get bid
 */
export const getBid = createAction('[Turnover] get bid', props<{ bidId: number }>());
export const clearBid = createAction('[Turnover] clear bid');
export const getBidSuccess = createAction('[Turnover] get bid success', props<{ bid: RestBidModel }>());
export const getBidFail = createAction('[Turnover] get bid fail');

/**
 * add new bid
 */
export const addNewBid = createAction('[Turnover] add new bid', props<{ bidList: RestBidModel[] }>());
export const addNewBidSuccess = createAction('[Turnover] add new bid success', props<{ newBidList: RestBidModel[] }>());
export const addNewBidFail = createAction('[Turnover] add new bid fail');
export const clearNewBidList = createAction('[Turnover] clear New Bid List');
export const newBidInProgress = createAction(
  '[Turnover] new Bid In Progress',
  props<{ newBidListInProgress: boolean }>()
);

/**
 * update bid
 */
export const updateBid = createAction('[Turnover] update bid', props<{ bid: RestBidModel }>());
export const updateBidSuccess = createAction('[Turnover] update bid success', props<{ updatedBid: RestBidModel }>());
export const updateBidFail = createAction('[Turnover] update bid fail');
export const clearUpdatedBid = createAction('[Turnover] clear updated bid');

/**
 * reject bid
 */
export const rejectBid = createAction('[Turnover] reject bid', props<{ id: number }>());
export const rejectBidSuccess = createAction('[Turnover] reject bid success', props<{ updatedBid: RestBidModel }>());
export const rejectBidFail = createAction('[Turnover] reject bid fail');

/**
 * update bid comment
 */
export const updateBidComment = createAction('[Turnover] update bid comment', props<{ bid: RestBidModel }>());
export const updateBidCommentSuccess = createAction(
  '[Turnover] update bid comment success',
  props<{ updatedBid: RestBidModel }>()
);
export const updateBidCommentFail = createAction('[Turnover] update bid comment fail');

/**
 * set bid attachment
 */
export const setBidAttachment = createAction(
  '[Turnover] set bid attachment',
  props<{ attachmentToEntity: UpdateTypedAttachmentToEntityModel }>()
);
export const setBidAttachmentSuccess = createAction(
  '[Turnover] set bid attachment success',
  props<{ addedBidAttachment: RestTypedBidAttachment }>()
);
export const setBidAttachmentFail = createAction('[Turnover] set bid attachment fail');
export const clearAddedBidAttachment = createAction('[Turnover] clear Added bid Attachment');

/**
 * delete bid attachment
 */
export const deleteBidAttachment = createAction(
  '[Turnover] delete bid attachment',
  props<{ bidId: number; attachment: RestTypedBidAttachment }>()
);
export const deleteBidAttachmentSuccess = createAction('[Turnover] delete bid attachment success');
export const deleteBidAttachmentFailed = createAction('[Turnover] delete bid attachment error');

/**
 * get bid attachment list
 */
export const getBidAttachmentList = createAction('[Turnover] get bid attachment list', props<{ bidId: number }>());
export const getBidAttachmentListSuccess = createAction(
  '[Turnover] get bid attachment list success',
  props<{ allAttachments: RestTypedBidAttachment[] }>()
);
export const getBidAttachmentListFailed = createAction('[Turnover] get bid attachment list error');

/**
 * get appliance
 */
export const getAppliance = createAction('[Turnover] get appliance', props<{ applianceId: number }>());
export const getApplianceSuccess = createAction(
  '[Turnover] get appliance success',
  props<{ appliance: RestApplianceModel }>()
);
export const getApplianceFailed = createAction('[Turnover] get appliance failed');

/**
 * get workflow step comment list
 */
export const getWorkflowStepCommentList = createAction(
  '[Turnover] get workflow step comment list',
  props<{ turnoverId: number }>()
);
export const clearWorkflowStepCommentList = createAction('[Turnover] clear workflow step comment list');
export const getWorkflowStepCommentListSuccess = createAction(
  '[Turnover] get workflow step comment list success',
  props<{ workflowStepCommentList: RestWorkflowStepCommentModel[] }>()
);
export const getWorkflowStepCommentListFailed = createAction('[Turnover] get workflow step comment list failed');

/**
 * add workflow step comment
 */
export const addWorkflowStepComment = createAction(
  '[Turnover] add workflow step comment',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);
export const addWorkflowStepCommentSuccess = createAction(
  '[Turnover] add workflow step comment success',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);
export const addWorkflowStepCommentFailed = createAction('[Turnover] add workflow step comment failed');

export const addWorkflowStepCommentReaction = createAction(
  '[Turnover] addWorkflowStepCommentReaction',
  props<{ commentItem: RestWorkflowStepCommentModel; emoji: string }>()
);

export const addWorkflowStepCommentReactionSuccess = createAction(
  '[Turnover] addWorkflowStepCommentReactionSuccess',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);

export const removeWorkflowStepCommentReaction = createAction(
  '[Turnover] removeWorkflowStepCommentReaction',
  props<{ commentItem: RestWorkflowStepCommentModel; emoji: string }>()
);

export const removeWorkflowStepCommentReactionSuccess = createAction(
  '[Turnover] removeWorkflowStepCommentReactionSuccess',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);

/**
 * update workflow step comment
 */
export const updateWorkflowStepComment = createAction(
  '[Turnover] update workflow step comment',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);
export const updateWorkflowStepCommentSuccess = createAction(
  '[Turnover] update workflow step comment success',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);
export const updateWorkflowStepCommentFailed = createAction('[Turnover] update workflow step comment failed');

/**
 * delete workflow step comment
 */
export const deleteWorkflowStepComment = createAction(
  '[Turnover] delete workflow step comment',
  props<{ commentItem: RestWorkflowStepCommentModel }>()
);
export const deleteWorkflowStepCommentSuccess = createAction('[Turnover] delete workflow step comment success');
export const deleteWorkflowStepCommentFailed = createAction('[Turnover] delete workflow step comment failed');

/**
 * delete turnover
 */
export const deleteTurnover = createAction(
  '[Turnover] delete turnover',
  props<{ turnoverId: number; unitName: string; disableAutoTurnCreation?: boolean }>()
);

export const deleteTurnoverSuccess = createAction('[Turnover] delete turnover success');
export const deleteTurnoverFailed = createAction('[Turnover] delete turnover failed');

/**
 * delete turnover attachment
 */
export const deleteTurnoverAttachment = createAction(
  '[Turnover] delete turnover attachment',
  props<{ attachment: RestTypedTurnoverAttachment }>()
);
export const deleteTurnoverAttachmentSuccess = createAction('[Turnover] delete turnover attachment success');
export const deleteTurnoverAttachmentFailed = createAction('[Turnover] delete turnover attachment failed');

/**
 * delete file from repository
 */
export const deleteFileFromRepository = createAction(
  '[Turnover] delete file from repository',
  props<{ fileId: number }>()
);
export const deleteFileFromRepositorySuccess = createAction('[Turnover] delete file from repository success');
export const deleteFileFromRepositoryFailed = createAction('[Turnover] delete file from repository failed');

/**
 * get turnover attachments
 */
export const getTurnoverAttachments = createAction(
  '[Turnover] get turnover attachments',
  props<{ turnoverId: number }>()
);
export const getTurnoverAttachmentsSuccess = createAction(
  '[Turnover] get turnover attachments success',
  props<{ allAttachments: RestTypedTurnoverAttachment[] }>()
);
export const getTurnoverAttachmentsFailed = createAction('[Turnover] get turnover attachments failed');
export const clearTurnoverAttachments = createAction('[Turnover] clear turnover attachments');

/**
 * get ticket
 */
export const getTicket = createAction('[Turnover] get ticket', props<{ ticketId: number }>());
export const getTicketSuccess = createAction(
  '[Turnover] get ticket success',
  props<{ restTicketModel: RestTicketModel }>()
);
export const getTicketFailed = createAction('[Turnover] get ticket failed');

/**
 * get ticket attachments
 */
export const getTicketAttachments = createAction('[Turnover] get ticket attachments', props<{ ticketId: number }>());
export const getTicketAttachmentsSuccess = createAction(
  '[Turnover] get ticket attachments success',
  props<{ ticketAttachments: RestTypedTicketAttachment[] }>()
);
export const getTicketAttachmentsFailed = createAction('[Turnover] get ticket attachments failed');

/**
 * clear ticket preview
 */
export const clearTicketPreview = createAction('[Turnover] clear ticket preview');

/**
 * set step Info
 */
export const setStepInfo = createAction('[Turnover] set step Info', props<{ stepInfo: TurnoverStepInfo }>());

/**
 * clean store
 */
export const cleanTurnoverStore = createAction('[Turnover] clean store');

// export const loadWorkflowAssignmentsByPropertyAndStepId = createAction(
//   '[Turnover] get workflow assigments by propertyId and stepId',
//   props<{ stepId: WorkflowStepEnumType; propertyId: number }>()
// );

export const workflowAssignmentsByPropertyAndStepIdSucceded = createAction(
  '[Turnover] loaded workflow assigments by propertyId and stepId succeeded',
  props<{ workflowStepAssigneeId: number }>()
);
export const workflowAssignmentsByPropertyAndStepIdFailure = createAction(
  '[Turnover] loaded workflow assigments by propertyId and stepId failure'
);

/**
 * assign default Workflow Step Assignee
 */
export const assignDefaultWorkflowStepAssignee = createAction(
  '[Turnover] assign default Workflow Step Assignee',
  props<{ turnoverData: RestTurnoverDataModel; workflowStep: WorkflowStepEnumType; propertyId: number }>()
);
export const assignDefaultWorkflowStepAssigneeSuccess = createAction(
  '[Turnover] assign default Workflow Step Responsible Party success',
  props<{ turnoverData: RestTurnoverDataModel }>()
);
export const assignDefaultWorkflowStepAssigneeFailed = createAction(
  '[Turnover] assign default Workflow Step Responsible Party failed'
);

export const reloadAppliancesNeeded = createAction('[Turnover] reload appliances needed');

export const reloadAppliancesNeededEnd = createAction('[Turnover] reload appliances needed end');
export const emptyAction = createAction('[Turnover] empty action');

export const inspectTurnover = createAction('[Turnover] inspect Turnover', props<{ inspectTurnoverId: number }>());

export const updateValidationParams = createAction(
  '[Turnover] update Validation Params',
  props<{ validationParams: ValidationParams }>()
);

/**
 * set step assignee
 */
export const setStepAssignee = createAction(
  '[Turnover] set step assignee',
  props<{ workflowAction: WorkflowAction }>()
);
export const setStepAssigneeSuccess = createAction('[Turnover] set step assignee success');
export const setStepAssigneeFailed = createAction('[Turnover] set step assignee failed');

/**
 * get default turnovers assignees
 */
export const getDefaultTurnoversAssignees = createAction(
  '[Turnover] get default turnovers assignees',
  props<{ propertyId: number }>()
);
export const getDefaultTurnoversAssigneesSuccess = createAction(
  '[Turnover] get default turnovers assignees success',
  props<{ defaultTurnoversAssignees: RestWorkflowStepAssignmentModel[] }>()
);
export const getDefaultTurnoversAssigneesFailed = createAction('[Turnover] get default turnovers assignees failed');

/**
 * counters
 */
export const resetKanbanCounters = createAction('[Turnover] reset Kanban Counters');

export const setAllPropertyIds = createAction('[Turnover] set All Property Ids', props<{ allPropertyIds: number[] }>());

export const toggleAllSections = createAction('[Turnover] toggle All Sections', props<{ active: boolean }>());

export const toggleSingleSection = createAction(
  '[Turnover] toggle Single Section',
  props<{ propertyId: number; active: boolean }>()
);

export const setActiveSections = createAction(
  '[Turnover] set active Sections',
  props<{ activePropertyIds: number[] }>()
);

export const toggleInactiveFilteredSingleSection = createAction(
  '[Turnover] toggle Filtered Single Section',
  props<{ propertyId: number; active: boolean }>()
);

export const setInactiveFilteredSections = createAction(
  '[Turnover] set filtered inactive Sections',
  props<{ inactiveFilteredPropertyIds: number[] }>()
);

export const setDisplayedPropertyList = createAction(
  '[Turnover] set Displayed Property List',
  props<{ displayedPropertyList: KanbanListItem[] }>()
);

export const setSectionLostRevenue = createAction(
  '[Turnover] set section lost revenue',
  props<{ propertyId: number; lostRevenue: number }>()
);

export const loadTurnoverTimers = createAction('[Turnover] load timers', props<{ turnoverId: number }>());
export const clearTurnoverTimers = createAction('[Turnover] clear timers');
export const turnoverTimersLoaded = createAction(
  '[Turnover] timers loaded',
  props<{ turnoverId: number; timers: TurnoverStepTimer[] }>()
);
export const setTurnoverTimers = createAction(
  '[Turnover] set timers',
  props<{ turnoverId: number; timers: TurnoverStepTimer[] }>()
);
export const setTurnoverTimersSuccess = createAction('[Turnover] setTurnoverTimers success');
export const setTurnoverTimersFailed = createAction('[Turnover] setTurnoverTimers failed');

export const setWorkflowStepAssignee = createAction(
  '[Turnover] setWorkflowStepAssignee',
  props<{ turnoverId: number; step: WorkflowStepEnumType; assigneeId: number }>()
);
export const setEmptyWorkflowStepAssignee = createAction(
  '[Turnover] setEmptyWorkflowStepAssignee',
  props<{ turnoverId: number; step: WorkflowStepEnumType; assigneeId: number; rememberIt: boolean }>()
);

export const transitEmptyWorkflowStepAssignee = createAction(
  '[Turnover] transitEmptyWorkflowStepAssignee',
  props<{ turnoverId: number; step: WorkflowStepEnumType; source: TurnoverTransitionSource; assigneeId: number }>()
);

export const setWorkflowStepAssigneeSuccess = createAction('[Turnover] setWorkflowStepAssigneeSuccess');
export const setWorkflowStepAssigneeFailed = createAction('[Turnover] setWorkflowStepAssigneeFailed');

export const loadTurnoverSummaryStats = createAction(
  '[Turnover] Load Turnover Summary Stats',
  props<{ filter: TurnoverSummaryFilters }>()
);

export const loadTurnoverSummaryStatsSuccess = createAction(
  '[Turnover] Load Turnover Summary Stats Success',
  props<{ stats: SummaryStatsGroup[] }>()
);

export const loadTurnoverSummaryStatsFailed = createAction(
  '[Turnover] Load Turnover Summary Stats Failed',
  props<{ error: any }>()
);

export const loadTurnoverSummaryDetails = createAction(
  '[Turnover] Load Turnover Summary Details',
  props<{ filter: TurnoverSummaryFilters }>()
);

export const loadTurnoverSummaryDetailsSuccess = createAction(
  '[Turnover] Load Turnover Summary Details Success',
  props<{ details: TurnoversSummaryStatsDetails }>()
);

export const loadTurnoverSummaryDetailsFailed = createAction(
  '[Turnover] Load Turnover Summary Details Failed',
  props<{ error: any }>()
);
