import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, catchError, firstValueFrom, mergeMap } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';

import { FileUploadsService } from '@app/services/file-uploads.service';
import { RestapiService } from '@app/services/restapi.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import { InspectionSummaryNotifyAgainPayload } from '@main-application/inspections/components/inspections-list/components/inspections-summary/inspections-summary.model';
import { InspectionsType } from '@main-application/inspections/components/inspections-list/inspections-list.component';
import {
  InspectionSummaryDownloadReport,
  InspectionSummaryFilters,
  InspectionSummaryStatsResponse,
} from '@main-application/inspections/models/inspection-summary.model';
import {
  InspectionBackUrl,
  InspectionBaseUrlParamsKeys,
  InspectionModel,
} from '@main-application/inspections/models/inspection.model';
import { InspectionsHeaderViewEnum } from '@main-application/inspections/models/inspections-header.model';
import { InspectionViewEnum } from '@main-application/inspections/models/inspections-views';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';
import { RegeneratedInspections } from '@main-application/turnovers/interfaces/inspection.interface';
import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';
import { RoutePath } from '@shared/enums/route-path.enum';
import { RestAddInspectionTicket, RestUpdateInspectionTicket } from '@shared/interfaces/ticket.interface';
import { RestTicketModel } from '@shared/interfaces/turnover.interface';
import { setStorageItem } from '@shared/utils/extensions';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { ModalsService } from '@ui-components/modals/modals.service';

import { SendInspectionComponent } from '../components/send-inspection/send-inspection.component';
import {
  InspectionAppointment,
  InspectionDelegateReqI,
  InspectionStatus,
  InspectionType,
  RestInspectionContents,
  RestInspectionCreateModel,
  RestInspectionUpdateModel,
  RestInspectionsModel,
  RestTypedInspectionAttachment,
  SendInspectionReportRequest,
} from '../models/rest-inspections-model.interface';

interface SignaturePayload {
  residentSigned?: boolean;
  residentSignName?: string;
}

const DO_CHECK_INTERVAL = 3000;

@UntilDestroy()
@Injectable()
export class InspectionService extends PuSubscribable {
  private _currentSurvey: RestInspectionContents | undefined;
  downloadOptions$ = new Subject<RegeneratedInspections>();
  fetchOneTimeInspectionList$ = new Subject<void>();
  private submissionQueue$ = new Subject<RestInspectionContents>();
  private queueInitialized = false;

  get survey() {
    return this._currentSurvey;
  }

  constructor(
    private readonly snackbarService: SnackbarService,
    private restApiService: RestapiService,
    private readonly matDialog: MatDialog,
    private modalService: ModalsService,
    private router: Router,
    private fileUploadService: FileUploadsService,
    private timezoneService: TimezoneService
  ) {
    super();
  }

  private static fixTimezoneForRestInspectionsModelList(data: RestInspectionsModel[], timezone: RestTimezone) {
    data.forEach(e => TimezoneEntityHelper.fixTimezoneForInspectionDataModelToClient(e, timezone));
    return data;
  }

  list(
    portfolioId?: number,
    startDate?: Date,
    endDate?: Date,
    inspectionType?: InspectionsType,
    returnStandardAndResidentBoth?: boolean,
    includeArchived?: boolean
  ): Observable<RestInspectionsModel[]> {
    if (!startDate && (inspectionType === InspectionsType.User || inspectionType === InspectionsType.Resident)) {
      startDate = new Date(2022, 0, 1);
    }

    let url = `inspections/list?portfolioId=${portfolioId}&doNotReturnContent=true`;

    if (inspectionType === InspectionsType.User || inspectionType === InspectionsType.Resident) {
      url += `&startDate=${startDate.toISOString()}&endDate=${endDate?.toISOString() || ''}`;
    }

    if (inspectionType === InspectionsType.Resident) {
      url += `&isResident=${true}`;
    }

    if (returnStandardAndResidentBoth) {
      url += `&returnStandardAndResidentBoth=${returnStandardAndResidentBoth}`;
    }

    if (includeArchived) {
      url += `&includeArchived=${includeArchived}`;
    }

    return this.restApiService.getData<RestInspectionsModel[]>(
      url,
      InspectionService.fixTimezoneForRestInspectionsModelList
    );
  }

  inReviewList(
    portfolioId?: number,
    isResident?: boolean,
    returnStandardAndResidentBoth?: boolean,
    includeArchived?: boolean
  ): Observable<RestInspectionsModel[]> {
    let url = `inspections/list?portfolioId=${portfolioId}&doNotReturnContent=true&inReviewOnly=true`;

    if (typeof isResident !== 'undefined') {
      url += `&isResident=${isResident}`;
    }

    if (returnStandardAndResidentBoth) {
      url += `&returnStandardAndResidentBoth=${returnStandardAndResidentBoth}`;
    }

    if (includeArchived) {
      url += `&includeArchived=${includeArchived}`;
    }

    return this.restApiService.getData<RestInspectionsModel[]>(
      url,
      InspectionService.fixTimezoneForRestInspectionsModelList
    );
  }

  repeating() {
    return this.restApiService.getData<RestInspectionsModel[]>(`inspections/recurring?doNotReturnContent=true`);
  }

  updateCalendarView(inspectionModel: RestInspectionsModel) {
    return this.restApiService.post<void>(
      `inspections/updateCalendarView/${inspectionModel.id}`,
      {
        calendarPosition: inspectionModel.calendarPosition,
        dueDate: inspectionModel.dueDate,
      },
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer
    );
  }

  updateInspectionComment(inspectionId: number, contentId: number, pageIndex: number, newComment: string) {
    return this.restApiService.post<void>(`inspections/${inspectionId}/${contentId}/updateComment`, {
      pageIndex,
      newComment,
    });
  }

  create(inspectionModel: RestInspectionCreateModel): Observable<RestInspectionsModel> {
    return this.restApiService.create<RestInspectionsModel>(
      `inspections/add`,
      inspectionModel
      // TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer,
      // TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToClient
    );
  }

  update(inspectionModel: RestInspectionUpdateModel): Observable<RestInspectionsModel> {
    return this.restApiService.create<RestInspectionsModel>(
      `inspections/${inspectionModel.id}`,
      inspectionModel
      // TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer
    );
  }

  extendExpiration(reqData: { newDate: Date }, id: number): Observable<RestInspectionsModel> {
    return this.restApiService.create<RestInspectionsModel>(
      `inspections/extendExpireDate/${id}`,
      reqData,
      TimezoneEntityHelper.fixTimezoneForExtendExpirationModelToServer,
      TimezoneEntityHelper.fixTimezoneForExtendExpirationModelToClient
    );
  }

  delete(inspectionId: number): Observable<any> {
    return this.restApiService.delete<void>(`inspections/${inspectionId}`);
  }

  regenerate(inspectionId: number): Observable<void> {
    return this.restApiService.create<void>(`inspections/${inspectionId}/regeneratePdf`, {});
  }

  getById(inspectionId: number) {
    return this.restApiService.getData<RestInspectionsModel>(`inspections/${inspectionId}`);
  }

  getBeUnitId(unitId: number) {
    return this.restApiService.getData<RestInspectionsModel[]>(`inspections/ByUnit/${unitId}`);
  }

  async openSendDialog(id: number, inspectionTitle: string) {
    const config: MatDialogConfig = {
      minWidth: '500px',
      panelClass: 'send-inspection-report-modal-container',
      data: inspectionTitle,
    };

    const sendRequest = await firstValueFrom(
      this.matDialog
        .open<SendInspectionComponent, typeof config, SendInspectionReportRequest>(SendInspectionComponent, config)
        .afterClosed()
    );

    if (sendRequest) {
      this.sendReport(id, sendRequest)
        .subscribe({
          next: () => this.snackbarService.success(SnackbarErrorMessage.InspectionReportSentSuccessfully),
          error: error => {
            this.snackbarService.error(SnackbarErrorMessage.ErrorSendingInspectionReport);
            console.error(error);
          },
        })
        .untilDestroyed(this);
    }
  }

  sendReport(inspectionId: number, request: SendInspectionReportRequest) {
    return this.restApiService.post<RestInspectionsModel>(`inspections/${inspectionId}/sendReport`, request);
  }

  onRegenerate(inspection: InspectionModel, regenerateAction: string): void {
    if (
      inspection.entityDetails.status !== InspectionStatus.Completed &&
      inspection.entityDetails.status !== InspectionStatus.Expired
    ) {
      this.snackbarService.error(SnackbarErrorMessage.InspectionMustBeCompleted);
      return;
    }
    this.regenerate(inspection.id)
      .pipe(
        catchError(err => {
          this.snackbarService.error('Error regenerating PDF report');
          throw err;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.snackbarService.dismiss();
        setTimeout(() => {
          this.snackbarService.snackbarLoadingSpinner$.next(true);
        }, 200);
        this.snackbarService.snackbarLoadingSpinner$.next(true);
        this.snackbarService.info('Your report is generating…', null, false);

        this.doCheckReportIsReady(inspection.id, inspection.entityDetails.attachments.attachment, regenerateAction);
      })
      .untilDestroyed(this);
  }

  private doCheckReportIsReady(
    inspectionId: number,
    attachment: RestTypedInspectionAttachment,
    regenerateAction: string
  ) {
    this.getById(inspectionId)
      .pipe(untilDestroyed(this))
      .subscribe(inspection => {
        if (inspection?.attachments?.attachment?.url || inspection?.attachments?.autoScopeReport?.url) {
          const inspectionAttachmentType = inspection?.attachments?.attachment?.inspectionAttachmentType || null;
          const autoScopeInspectionAttachmentType =
            inspection?.attachments?.autoScopeReport?.inspectionAttachmentType || null;

          this.downloadOptions$.next({ inspectionAttachmentType, autoScopeInspectionAttachmentType, inspectionId });
          this.snackbarService.snackbarLoadingSpinner$.next(false);
          this.snackbarService.dismiss();

          if (inspectionAttachmentType && autoScopeInspectionAttachmentType) {
            this.snackbarService.success(
              `Report ready! <a class="cursor-pointer underline text-color white"><span data-toast-action="${regenerateAction}" data-toast-action-param="${inspectionId}">Download standard report</span></a>&nbsp;&nbsp;
                                     <a class="cursor-pointer underline text-color white"><span data-toast-action="${regenerateAction}" data-toast-action-param="${inspectionId}">Download auto scope report</span></a>`,
              null,
              null
            );
          } else {
            this.snackbarService.success(
              `Report ready! <a class="cursor-pointer underline text-color white"><span data-toast-action="${regenerateAction}" data-toast-action-param="${inspectionId}">Download</span></a>`,
              null,
              null
            );
          }
        } else {
          setTimeout(() => {
            this.doCheckReportIsReady(inspectionId, attachment, regenerateAction);
          }, DO_CHECK_INTERVAL);
        }
      })
      .untilDestroyed(this);
  }

  public notifyAgainWithParams(payload: InspectionSummaryNotifyAgainPayload): Observable<void> {
    return this.restApiService.post<void>(`inspections/notifyAgain`, payload);
  }

  public onDownloadInspection(inspectionId: number): void {
    const download = (inspection: InspectionModel) => {
      if (
        inspection.entityDetails.status !== InspectionStatus.Completed &&
        inspection.entityDetails.status !== InspectionStatus.Expired
      ) {
        this.snackbarService.error(SnackbarErrorMessage.InspectionMustBeCompleted);
        return;
      }

      if (inspection.entityDetails.attachments?.autoScopeReport) {
        const { fileUploadId } = inspection.entityDetails.attachments?.autoScopeReport;
        this.fileUploadService.downloadFile(fileUploadId);
      }

      if (inspection.entityDetails.attachments?.attachment) {
        const { fileUploadId } = inspection.entityDetails.attachments?.attachment;
        this.fileUploadService.downloadFile(fileUploadId);
      }

      if (
        !inspection.entityDetails.attachments?.attachment?.url &&
        !inspection.entityDetails.attachments?.autoScopeReport?.url
      ) {
        this.snackbarService.error(SnackbarErrorMessage.InspectionPdfNotFound);
      }
    };

    this.getById(inspectionId)
      .pipe(untilDestroyed(this))
      .subscribe(inspection => {
        this.snackbarService.resetSnackbarActions();
        download(new InspectionModel(inspection, null));
      })
      .untilDestroyed(this);
  }

  deleteInspection(inspection: InspectionModel, noConfirm = false) {
    const deleteFn = this.delete(inspection.id).pipe(
      catchError(err => {
        this.snackbarService.error('Error deleting inspection');
        throw err;
      })
    );

    if (noConfirm) {
      return deleteFn;
    }

    return this.modalService
      .openConfirmationModal(
        {
          title: `Delete inspection?`,
          content: `Delete ${inspection.inspectionTemplate?.name || 'inspection'} for ${inspection.propertyName} - ${
            inspection.unitName
          }?`,
          confirmColor: 'warn',
          confirmLabel: 'Delete',
        },
        'sm'
      )
      .afterClosed()
      .pipe(
        filter(result => result === DialogResult.Success),
        mergeMap(() => deleteFn)
      );
  }

  tableSortPredicate(inspection: InspectionModel, sortHeaderId: string) {
    switch (sortHeaderId) {
      case 'unit.name':
        return inspection.entityDetails.unit ? inspection.entityDetails.unit.name?.toLocaleLowerCase() : 'all units';
      case 'turboMode':
        return inspection.entityDetails.isTurboMode;
      case 'unit.propertyName':
        return inspection.propertyName?.toLocaleLowerCase();
      case 'inspectionType':
        return (inspection.inspectionTemplate?.name ?? inspection.inspectionType)?.toLocaleLowerCase();
      case 'rooms':
        return inspection.completedOrSkippedAreasCount;
      case 'assignee':
        return inspection.entityDetails.assigneeUser?.displayName?.toLocaleLowerCase();
      case 'createdAt':
        return inspection.entityDetails.createdAt;
      case 'completedAt':
        return inspection.entityDetails.completedAt;
      case 'dueDate':
        return inspection.entityDetails.dueDate;
      case 'expired':
        return inspection.entityDetails.autoExpiredOn;
      case 'reviewer':
        return inspection.reviewer?.displayName?.toLocaleLowerCase();
      case 'reviewCompletedByUser':
        return inspection.reviewer?.displayName?.toLocaleLowerCase();
      default:
        return inspection[sortHeaderId];
    }
  }

  open(inspection: InspectionModel, backInspectionUrl?: InspectionBackUrl) {
    inspection.entityDetails.inspectionType === InspectionType.CustomInspection
      ? this.router.navigate([RoutePath.CUSTOM_INSPECTIONS, inspection.entityDetails.id], {
          queryParamsHandling: 'merge',
          queryParams: { [InspectionBaseUrlParamsKeys.backInspectionUrl]: backInspectionUrl || null },
        })
      : this.router.navigate([RoutePath.INSPECTIONS, inspection.entityDetails.id], { queryParamsHandling: 'merge' });
  }

  share(inspection: InspectionModel, evt?: Event) {
    if (evt) {
      evt.preventDefault();
    }

    if (inspection.entityDetails.status === InspectionStatus.Completed) {
      this.openSendDialog(
        inspection.entityDetails.id,
        `${inspection.inspectionType} inspection: ${inspection.entityDetails.unit.propertyName} - ${inspection.entityDetails.unit.name}`
      );
    } else {
      this.snackbarService.error(`You can only share a completed inspection`);
    }
  }

  download(inspectionId: number): void {
    this.onDownloadInspection(inspectionId);
  }

  createTicket(ticket: RestAddInspectionTicket) {
    return this.restApiService.create<void>('tickets/v2/add', ticket);
  }

  updateTicket(ticket: RestUpdateInspectionTicket) {
    return this.restApiService.create<void>('tickets/v2/' + ticket.id, ticket);
  }

  getTicketsForInspection(inspectionId: number): Observable<RestTicketModel[]> {
    return this.restApiService.getData<RestTicketModel[]>('tickets/ByInspectionId/' + inspectionId);
  }

  getResidentInspections(): Observable<RestInspectionsModel[]> {
    return this.restApiService.getData<RestInspectionsModel[]>('inspections/residentInspections');
  }

  getSummaryStats(payload: InspectionSummaryFilters): Observable<InspectionSummaryStatsResponse> {
    return this.restApiService.post<InspectionSummaryStatsResponse>(
      'inspections/summaryStats',
      payload,
      TimezoneEntityHelper.fixTimezoneForSummaryStatsToServer
    );
  }

  markAsCompleted(inspectionId: number, residentSigned?: boolean, residentSignName?: string): Observable<any> {
    const payload: SignaturePayload = {};

    if (residentSigned && residentSignName) {
      payload.residentSigned = residentSigned;
      payload.residentSignName = residentSignName;
    }

    return this.restApiService.post(`inspections/${inspectionId}/complete`, payload);
  }

  archiveInspection(inspectionId: number): Observable<RestInspectionsModel> {
    return this.restApiService.post(
      `inspections/${inspectionId}/archive`,
      null,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToClient
    );
  }

  unArchiveInspection(inspectionId: number): Observable<RestInspectionsModel> {
    return this.restApiService.post(
      `inspections/${inspectionId}/unarchive`,
      null,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToClient
    );
  }

  inspectionDelegate(id: number, model: InspectionDelegateReqI): Observable<RestInspectionsModel> {
    return this.restApiService.create<RestInspectionsModel>(
      `inspections/${id}/delegate`,
      model,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToClient
    );
  }

  finishReview(id: number): Observable<RestInspectionsModel> {
    return this.restApiService.create<RestInspectionsModel>(
      `inspections/${id}/finishReview`,
      null,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToServer,
      TimezoneEntityHelper.fixTimezoneForRestInspectionsModelToClient
    );
  }

  private initializeSubmissionQueue(): void {
    if (!this.queueInitialized) {
      this.queueInitialized = true;
      this.submissionQueue$
        .pipe(concatMap(payload => this.submitAnswers(payload)))
        .subscribe()
        .untilDestroyed(this);
    }
  }

  queueSubmitAnswers(payload: RestInspectionContents): void {
    if (!this.queueInitialized) {
      this.initializeSubmissionQueue();
    }
    this.submissionQueue$.next(payload);
  }

  submitAnswers(payload: RestInspectionContents): Observable<any> {
    return this.restApiService.post<RestInspectionContents>('inspections/submitAnswers', payload);
  }

  notifyAgain(inspectionId: number): Observable<void> {
    return this.restApiService.post<void>(`inspections/${inspectionId}/notifyAgain`);
  }

  onDownloadInspectionSummary(payload: InspectionSummaryDownloadReport): Observable<any> {
    return this.restApiService.downloadWithPost<any>('inspections/summaryreport', payload);
  }

  updateAppointment(inspectionId: number, payload: InspectionAppointment): Observable<void> {
    return this.restApiService.post<void>(`inspections/${inspectionId}/appointment`, payload);
  }

  getAppointmentHours(date?: Date): { value: Date; label: string }[] {
    const today = date || this.timezoneService.getCurrentDateOnly();
    const appointments: { value: Date; label: string }[] = [];

    appointments.push({
      value: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
      label: '12:00 AM',
    });

    for (let hour = 1; hour <= 11; hour++) {
      const label = `${hour}:00 AM`;
      const appointmentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hour, 0, 0);
      appointments.push({ value: appointmentDate, label });
    }

    appointments.push({
      value: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0),
      label: '12:00 PM',
    });

    for (let hour = 1; hour <= 11; hour++) {
      const label = `${hour}:00 PM`;
      const appointmentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hour + 12, 0, 0);
      appointments.push({ value: appointmentDate, label });
    }

    return appointments;
  }

  public updateInspectionsViewModeInStorage(type: InspectionsHeaderViewEnum): void {
    setStorageItem(LocalStorageDataEnum.INSPECTION_VIEW_MODE, {
      [InspectionViewEnum.INSPECTION_VIEW]: type,
    });
  }
}
