import { Pipe, PipeTransform } from '@angular/core';

import { BoardType } from '@main-application/boards/interfaces/board.enums';

@Pipe({
  name: 'boardType',
})
export class BoardTypePipe implements PipeTransform {
  transform(value: number): string {
    if (value in BoardType) {
      switch (BoardType[value]) {
        case 'MakeReady':
          return 'Make Ready';
        case 'NonPms':
          return 'Non-PMS';
        case 'MoveOut':
          return 'Move-out';
        case 'MoveIn':
          return 'Move-in';
        case 'LeaseUp':
          return 'Available';
        case 'Prelease':
          return 'Pre-lease';
        default:
          return BoardType[value];
      }
    }
    return 'Unknown';
  }
}
