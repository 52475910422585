import { RestTurnStepScheduleModel } from '@main-application/boards/interfaces/board';
import { BoardProgressType, BoardSourceType } from '@main-application/boards/interfaces/board.enums';
import { TicketAttachmentType, TurnoverAttachmentType } from '@shared/enums/attachment-type';
import { MoveOutType } from '@shared/enums/move-out-type.enum';
import { PreleaseAmountPaidType } from '@shared/enums/prelease-amount-paid-type';
import { PriorityType } from '@shared/enums/priority-type';
import { TicketStatus, TicketStatusType } from '@shared/enums/ticket-status-type';
import { TicketType } from '@shared/enums/ticket-type';
import { UnitConditionType } from '@shared/enums/unit-condition.enum';
import { UserType } from '@shared/enums/user-type';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { RestBidModel } from '@shared/interfaces/bid.interface';
import { RestWorkflowStepCommentModel } from '@shared/interfaces/comments.interface';
import { RestListingPlatformModel } from '@shared/interfaces/listing-platform.interface';
import { RestPortfolioModel, RestPropertyModel } from '@shared/interfaces/property.interface';
import { RestTimerInstanceModel } from '@shared/interfaces/rest-timer-instance-model';
import { RestUnitModel } from '@shared/interfaces/unit.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { AttachmentType } from '@shared/types/attachment-type.type';

export interface TurnoverHeaderData {
  turnoverId: number;
  propertyName: string;
  unitName: string;
  currentTurnoverStep: WorkflowStepEnumType;
  moveOutType: MoveOutType;
}

export interface RestAddressModel {
  propertyToAddressLinkId: number;
  Id: number;
  addressType: number;
  addressTypeUrn: string;
  title: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  url: string;
}

export interface RestWorkflowInstanceModel {
  id: number;
  turnoverId?: number;
  templateId?: number;
  isActive: boolean;
  isCompleted: boolean;
  completedUserId?: number;
  dateStarted?: Date;
  dateCompleted?: string;
  parentStepId?: number;
  workflowStepTemplateType: number;
  workflowStepEnumType: WorkflowStepEnumType;
  //int AssignmentRuleId: number          // 0: N/A, 1: individual, 2: role: 3: creator
  //int? AssignmentObjectId?: number
  name: string;
  fullyQualifiedUrn: string;
  sortOrder: number;
  responsiblePartyId: number;
  responsiblePartyType: UserType;
  dueDate?: string;
  priority?: PriorityType;
}

export interface RestMailingAddressModel {
  id: number;
  mailAddress: string;
  companyId: number;
}

export interface RestTypedTurnoverAttachment {
  id: number;
  dateCreated?: string;
  dateUpdated?: string;
  isActive?: boolean;
  fileUploadId?: number;
  internalName?: string;
  originalFileName?: string;
  size?: number;
  url?: string;
  attachmentTypeUrn?: string;
  title?: string;
  description?: string;
  turnoverAttachmentType?: TurnoverAttachmentType;
  externalUrl?: string;
}

export interface RestTurnoverAttachmentList {
  summary: RestTypedTurnoverAttachment;
  scopeOfWork: RestTypedTurnoverAttachment;
  photo: RestTypedTurnoverAttachment;
  other: RestTypedTurnoverAttachment;
  none: RestTypedTurnoverAttachment[];
}

export interface TurnoverStepInfo {
  isStepNotAvailable: boolean;
  isStepCompleted: boolean;
  isTurnoverDeleted: boolean;
  isTurnoverComplete: boolean;
  turnoverDateDeleted?: Date | string;
  turnoverDateCompleted?: Date | string;
}

export function isRestTurnoverDataModel(object: any): object is RestTurnoverDataModel {
  return (
    typeof object === 'object' &&
    typeof object.id === 'number' &&
    typeof object.unitId === 'number' &&
    typeof object.unitName === 'string'
  );
}

export interface RestTurnoverDataModel {
  id: number;
  unitId: number;
  createdById: number;
  activeWorkflowStepId: number;
  propertyManagerId: number;
  propertyManager: RestUserModel;
  bulkScopeAndBidId: number;
  turnoverPriorityType: PriorityType;
  unitName: string;
  activeWorkflowStepType: WorkflowStepEnumType;
  constructionCost?: number;
  newRent?: number;
  newRentOverride?: number;
  oldRent?: number;
  oldRentOverride?: number;
  advertisedRent?: number;
  advertisedRentOverride?: number;
  marketRent?: number;
  marketRentOverride?: number;
  additionalData?: any;

  dateCreated: Date | string;
  dateUpdated: Date | string;
  dateCompleted: Date | string;
  dateCanceled: Date | string;
  dateMoveOut: Date;
  pmsMoveOutDate?: Date | string;
  pmsMoveInDate?: Date | string;
  dateMoveIn: Date;
  dateShowable: Date | string;
  turnoverStartDate: Date | string;
  turnoverEndDate: Date | string;
  lastRentDate: Date | string;

  isCompleted: boolean;
  isCanceled: boolean;
  isMoveOutCompleted: boolean;
  isMoveInCompleted: boolean;
  isAutoCreated: boolean;

  isUnitDown: boolean;
  isUnitDownReason: string;

  isUnitOnHold: boolean;
  isUnitOnHoldReason: string;

  // MOVE OUT
  isOccupiedInspected?: boolean;
  isVacantInspected?: boolean;
  dateOccupiedInspected: Date | string;
  dateVacantInspected: Date | string;
  unitStatus: MoveOutType;
  unitConditionType: UnitConditionType;

  // MAKE READY
  // BID
  finish: number;
  newFinish: number;
  oldFinish: number;
  sendingType: number;
  mailingTemplateBody: string;
  dateOffersSentOut: Date | string;
  dateOfferExpires: Date | string;

  // RENO
  // PUNCH
  // APPROVAL

  // MARKETING
  listingDescription: string;
  arePhotosNeeded: boolean;
  rentRate: number;
  securityDeposit: number;
  dateAvailable: Date | string;
  isSecurityDepositNegotiable: boolean;

  // LEASE UP
  leaseExecutionDate: Date | string;

  // MOVE IN
  isRentPaid: boolean;
  areKeysExchanged: boolean;
  isDepositPaid: boolean;
  isDocumentationAddedToExternalSystem: boolean;
  firstMonthRent: Date | string;
  additionalRentOrFees: string;

  // additional fields
  propertyAddress: string;
  state: string;
  postalCode: string;

  preleaseTenantEmail: string;
  preleaseTenantFirstName: string;
  preleaseTenantSurname: string;

  createdBy: RestUserModel;
  unit: RestUnitModel;
  property: RestPropertyModel;
  portfolio: RestPortfolioModel;
  workflow: RestWorkflowInstanceModel[];
  workflowStepComments: RestWorkflowStepCommentModel[];
  tickets: RestTicketModel[];
  ticketInfos: RestTicketInfo[];
  listingPlatforms: RestListingPlatformModel[];
  bids: RestBidModel[];
  mailingList: RestMailingAddressModel[];
  attachments: RestTurnoverAttachmentList;
  allAttachments: RestGenericTypedAttachment[];
  timerInstance: RestTimerInstanceModel;
  turnStepSchedules: RestTurnStepScheduleModel[];

  ticketsAllCount: number;
  ticketsInProgressCount: number;
  totalTimeElapsed: string;

  hasMoveOutLabel: boolean;
  hasMoveInLabel: boolean;
  hasOccupiedLabel: boolean;
  hasAvailableOnLabel: boolean;
  hasEvictLabel: boolean;

  moveOutCountdown: number;
  moveInCountdown: number;
  occupiedCountdown: number;
  evictCountDown: number;
  availableOnCountdown: number;

  boardConnections: BoardConnection[];
  inspectionSummary: InspectionSummary;
}

export interface RestAssociateTicketInfo {
  ticketId: number;
  boardLayoutId: number;
  columnId: string;
  turnoverId: number;
}

export interface RestTicketInfo {
  assigneeId: number;
  status: TicketStatus;
  ticketStatusType: TicketStatusType;
  ticketId: number;
  title: string;
  turnStepScheduleId?: number;
}

export enum PreleaseFilterMode {
  Not,
  Kinda,
  Strict,
}

export interface InspectionSummary {
  id: number;
  inspectionId: number;
  lastCompletedAreaName: string;
  lastCompletedAreaAt: Date;
  completedAreas: number;
  completedAt: Date;
  deletedAt: Date;
  isCompleted: false;
  isDeleted: false;
  totalNumberOfAreas: number;
  turnoverId: number;
}

export interface BoardConnection {
  boardDataSource: BoardSourceType;
  boardProgressType: BoardProgressType;
  boardId: number;
  boardName: string;
  completedStepSchedules: number;
  isCompleted: boolean;
  stepSchedulesTotal: number;
  lastCompletedScheduleDate: string;
  lastCompletedScheduleName: string;
  nextScheduleDate: string;
  nextScheduleName: string;
}

export interface PostTurnoverModel {
  unitId: number;
  propertyId?: number;
  turnoverStartDate?: Date;
  turnoverEndDate?: Date;
  dateMoveOut?: Date;
  startWorkflowStepType: WorkflowStepEnumType;
}

export interface NonPmsModelModel {
  id?: number;
  propertyId?: number;
  name: string;
}

export enum AppointmentTypeEnum {
  NotRequired = 0,
  Call = 1,
  PermissionGiven = 2,
  PermissionNotGiven = 3,
}

export interface RestTicketModel {
  id: number;
  workflowPhaseType?: WorkflowPhaseType;
  workflowStepEnumType?: WorkflowStepEnumType;
  status?: TicketStatusType;
  ticketStatusType?: TicketStatusType;
  ticketPriorityType?: PriorityType;
  name?: string;
  detailedInformation?: string;
  partyName?: string; // Company name if Assignee.UserType == Contractor
  dueDate?: Date | string;
  dateCreated?: Date | string;
  dateUpdated?: Date | string;
  dateCompleted?: Date | string;
  startDate?: Date | string;
  endDate?: Date | string;
  createdById?: number;
  createdBy?: RestUserModel;
  assigneeId?: number; // aka ResponsiblePartyId
  assignee?: RestUserModel; // aka ResponsibleParty
  turnoverId?: number;
  turnoverData?: RestTurnoverDataModel;
  attachments?: RestTicketAttachmentList;
  allAttachments?: RestGenericTypedAttachment[];
  unitName?: string;
  unitId?: number;
  ticketType?: TicketType;
  propertyName?: string;
  propertyId?: number;
  surveyPageId?: number;

  permissionToEnter?: boolean;
  appointmentType: AppointmentTypeEnum;
  havePets?: boolean;
  ticketCategoryId?: number;
  ticketCategory?: RestTicketCategoryModel;
  ticketTopicId?: number;
  ticketTopic?: RestTicketSubCategoryModel;

  clientFeedbackRating: number;
  clientFeedbackSubmittedOn: Date;
  clientFeedbackRequestdOn: Date;
  clientFeedbackComment: string; //8142 - max
  clientFeedbackRequestReminderSent: number;

  inspectionId?: number;
  inspectionContentId?: number;
  displayedInColumnName?: string;
  pmsWorkOrderId?: string;
  pmsWorkOrderNumber?: string;

  pushToPMS: boolean;

  accessNotes?: string;
  problemDescription?: string;
  technicalNotes?: string;
  resolutionComment?: string;

  totalCost?: number;
  timeSpentLoggedManually?: number;

  boardLayoutsId?: number;
  boardColumnUID?: string;
  totalCostStatus?: TotalCostStatus;
  paymentStatus?: PaymentStatus;
  turnStepScheduleId?: number;
  attachFileIds?: number[];
  removeFileIds?: number[];

  sparePartId?: number;
  quantity?: number;
  responsibleParty?: any;
}

export class RestTicketCategoryModel {
  id: number;
  name: string;
  isResidentPortalEnabled: boolean;
  topics: RestTicketSubCategoryModel[];
}

export class RestTicketSubCategoryModel {
  id: number;
  name: string;
  ticketCategoryId: number;
}

export interface TicketListItem extends RestTicketModel {
  ableToResolve?: boolean;
}

export interface RestTicketAttachmentList {
  ticketCustomCategory: RestTypedTicketAttachment;
  Uncategorized: RestTypedTicketAttachment[];
}

export interface RestTypedTicketAttachment {
  id: number;
  dateCreated?: string;
  dateUpdated?: string;
  isActive?: true;
  fileUploadId?: number;
  internalName?: string;
  originalFileName?: string;
  size?: number;
  url?: string;
  attachmentTypeUrn?: string;
  title?: string;
  description?: string;
  ticketAttachmentType?: TicketAttachmentType;
}

export interface UpdateTypedAttachmentToEntityModel {
  attachmentId?: number;
  unitId?: number;
  propertyId?: number;
  portfolioId?: number;
  turnoverIds?: number[];
  applianceId?: number;
  bidId?: number;
  ticketId?: number;
  attachmentType?: AttachmentType;
  fileUploadId?: number;
  title: string;
  description: string;
  externalUrl: string;
}

export interface RestTurnoverTaskModel {
  id?: number;
  portfolioId?: number;
  turnoverDataId?: number;
  turnoverData?: RestTurnoverDataModelNew;
  dueDate?: string;
  workflowPhaseType?: WorkflowPhaseType;
  unitName?: string;
  propertyName?: string;
  propertyId?: number;
  assigneeId?: number;
  assigneeName?: string;
  phasePriority?: PriorityType;
  stepPriority?: PriorityType;
  vacancyLoss: number;
  moveInCalendarPosition: number;
  moveOutCalendarPosition: number;
  pmsType?: number;
  isNonPms?: boolean; //ToDo: remove it, temporary field until non-PMS is not moved to unit
  isNew?: boolean; //FE field - flag for detecting just adding turns
  unitId?: number;
  unitType?: string;
}

export type RestTurnoverDataModelNew = Omit<
  RestTurnoverDataModel,
  'property' | 'listingPlatforms' | 'mailingList' | 'unit'
>;

export function isRestTurnoverTaskModel(object: any): object is RestTurnoverTaskModel {
  return typeof object === 'object' && typeof object.id === 'number' && isRestTurnoverDataModel(object.turnoverData);
}

export interface RestWorkflowTemplate {
  id: number;
  name: string;
  fullyQualifiedUrn: string;
  sortOrder: number;
  workflowStepEnumType: WorkflowStepEnumType;
}

export interface RestArchivedTurnover {
  id: number;
  newFinish: number;
  oldFinish?: number;
  propertyName: string;
  propertyId: number;
  turnoverEndDate: string | Date;
  turnoverStartDate: string | Date;
  unitName: string;
  unitId: number;
  unitType: string;
  workflow: RestWorkflowInstanceModel[];
}

export interface MarkAsPreLEasedFormValue {
  responsibleParty: number;
  moveInDate: Date;
  rentAmount: number;
  securityDeposit: number;
  amountPaid: number;
  paymentMethod: PreleaseAmountPaidType;
  paymentDate: Date;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export enum TotalCostStatus {
  Estimate = 0,
  Final = 1,
}

export enum PaymentStatus {
  Unpaid = 0,
  Paid = 1,
}
