<div class="display-flex flex-column">
  <label class="label-inner" [ngClass]="[labelRequired ? 'label-required' : '']" *ngIf="attrLabel && !displaySkeleton">
    {{ attrLabel }}
  </label>
  <ng-container *ngIf="attrLabel && displaySkeleton">
    <div class="label-placeholder skeleton-loader" [ngStyle]="{ 'width.px': attrLabel.length * 10 }"></div>
  </ng-container>
  <div class="display-flex grid-gap-8">
    <app-radio-button-dropdown
      class="w-30"
      [attrAppendTo]="'body'"
      [attrPlaceholder]="'Code'"
      [unsupportedValueLabel]="'Code'"
      [allowClear]="false"
      [allowSearch]="true"
      [autoSortList]="false"
      [items]="countryCodes"
      [formControl]="phoneCodeControl"
      [displaySkeleton]="displaySkeleton"
      [attrDisable]="phoneNumberControl.disabled"></app-radio-button-dropdown>
    <app-input
      class="w-70"
      [attrMask]="maskPattern"
      [attrDisable]="phoneNumberControl.disabled"
      [contentCss]="'display-flex flex-column fill'"
      [formControl]="phoneNumberControl"
      [displaySkeleton]="displaySkeleton"
      [attrPlaceholder]="'Enter phone number'"></app-input>
  </div>
  <app-input-error
    *ngIf="displayErrorContent && (errors || (phoneNumberControl.value == '' && phoneNumberAsIdentity))"
    [errors]="{
      phoneNumber: notValidFormat,
      phoneNumberAsIdentity: phoneNumberControl.value == '' && phoneNumberAsIdentity
    }"
    [visible]="
      (errors || (phoneNumberControl.value == '' && phoneNumberAsIdentity)) && showValidationErrorOrSubmitted
    "></app-input-error>
</div>
