import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';

import { BoardCurrencyPipe } from '@shared/pipes/board-currency.pipe';
import { ConditionalDatePipe } from '@shared/pipes/conditional-date.pipe';
import { CustomCurrencyDotsCommasPipe } from '@shared/pipes/custom-currency-dots-commas.pipe';
import { EnumerationUserPipe } from '@shared/pipes/enumeration-user.pipe';
import { FileMimeTypePipe } from '@shared/pipes/file-mime-type.pipe';
import { FindByPropPipe } from '@shared/pipes/find-by-prop.pipe';
import { FormatToAvatarPipe } from '@shared/pipes/format-to-avatar.pipe';
import { IsSaveGeneralRoleDisabledPipe } from '@shared/pipes/is-save-general-role-disabled.pipe';
import { IsSubmitRolesAndUnitsDisabledPipe } from '@shared/pipes/is-submit-roles-and-units-disabled.pipe';
import { IsUserExistingPipe } from '@shared/pipes/is-user-existing.pipe';
import { LabelAvailableDayInStepPipe } from '@shared/pipes/label-available-day-in-step.pipe';
import { LabelDaySinceStepPipe } from '@shared/pipes/label-day-since-step.pipe';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
import { PluralizationPipe } from '@shared/pipes/pluralization.pipe';
import { PriorityIconPipe } from '@shared/pipes/priority-icon.pipe';
import { PriorityToCssClassPipe } from '@shared/pipes/priority-to-css-class.pipe';
import { PropertiesToRadioInputPipe } from '@shared/pipes/properties-to-radio-input.pipe';
import { TurnoverPhaseToStatusPipe } from '@shared/pipes/turnover-phase-to-status.pipe';
import { UserDisplayNamePipe } from '@shared/pipes/user-display-name.pipe';
import { ValueLengthPipe } from '@shared/pipes/value-length.pipe';
import { WorkflowPhaseToProgressValuePipe } from '@shared/pipes/workflow-phase-to-progress-value.pipe';
import { WorkflowStepNamePipe } from '@shared/pipes/workflow-step-name.pipe';

import { BidStatusTypePipe } from './bid-status-type.pipe';
import { BoardTypePipe } from './board-type.pipe';
import { BooleanToStringPipe } from './boolean-to-string.pipe';
import { CompactPipe } from './compact.pipe';
import { CompareOperatorPipe } from './compare-operator.pipe';
import { CustomCurrencyShortWithSuffixPipe } from './custom-currency-short-with-suffix.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { TodayDatePipe } from './date.pipe';
import { DayFromHoursPipe } from './day-from-date.pipe';
import { DaysAgoPipe } from './days-ago.pipe';
import { DaysInStepPipe } from './days-in-step.pipe';
import { DaysPastPipe } from './days-past.pipe';
import { DelinquencyGroupInvalidPipe } from './delinquency-group-invalid.pipe';
import { DelinquencyPristineGroupPipe } from './delinquency-pristine-group.pipe';
import { ElapsedDatePipe } from './elapsed-date.pipe';
import { ElapsedDaysMidRangePipe } from './elapsed-days-mid-range.pipe';
import { EnumValueToDisplayTextPipe } from './enum-value-to-display-text.pipe';
import { EnumerationValuePipe } from './enumeration-value.pipe';
import { EscapeHtmlPipe } from './escape-html.pipe';
import { FieldTypePipe } from './field-type.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FileToObjectUrlPipe } from './file-to-object-url.pipe';
import { FindByIdPipe } from './find-by-id.pipe';
import { FirstPipe } from './first.pipe';
import { GetCurrentWorkflowStepPipe } from './get-current-workflow-step.pipe';
import { GetFromGroupPipe } from './get-from-group.pipe';
import { GetPercentageValuePipe } from './get-percentage-value.pipe';
import { GetWorkflowStepPipe } from './get-workflow-step.pipe';
import { HoursPastPipe } from './hours-past.pipe';
import { HumanizePipe } from './humanize.pipe';
import { IsIncludePipe } from './is-include.pipe';
import { IsOddPipe } from './is-odd.pipe';
import { KanbanHeaderWorkflowPhasePipe } from './kanban-header-workflow-phase.pipe';
import { KanbanHeaderWorkflowStepPipe } from './kanban-header-workflow-step.pipe';
import { KpiHeaderPipe } from './kpi-header.pipe';
import { KpiTaskValuePipe } from './kpi-task-value.pipe';
import { KpiValuePipe } from './kpi-value.pipe';
import { LabelDayInStepPipe } from './label-day-in-step.pipe';
import { LostRevenuePipe } from './lost-revenue.pipe';
import { MeasureTypePipe } from './measure-type.pipe';
import { MentionCommentPipe } from './mention-comment.pipe';
import { NotEmptyPropsAmountPipe } from './not-empty-props-amount.pipe';
import { NumberValidationPipe } from './number-validation.pipe';
import { NumberPipe } from './number.pipe';
import { ParseFloatPipe } from './parse-float.pipe';
import { PhysicalAddressPipe } from './physical-address.pipe';
import { PmsTypeEnumerationPipe } from './pms-type-enumeration.pipe';
import { PriorityCssPipe } from './priority-css.pipe';
import { ReplaceValuePipe } from './replace-value.pipe';
import { RoundValuePipe } from './round-value.pipe';
import { SectionIdPipe } from './section-id.pipe';
import { StartsWithPipe } from './starts-with.pipe';
import { TaskTableSubsectionResultCountPipe } from './task-table-subsection-result-count.pipe';
import { TextMaskPipe } from './text-mask.pipe';
import { ThresholdConfigurationPipe } from './threshold-configuration.pipe';
import { ThresholdPriorityCssPipe } from './threshold-priority-css.pipe';
import { ThresholdStepConfigurationPipe } from './threshold-step-configuration.pipe';
import { TimeDiffPipe } from './time-diff.pipe';
import { TimeFromDatePipe } from './time-from-date.pipe';
import { ToFixedValuePipe } from './to-fixed-value.pipe';
import { TruncateLengthPipe } from './truncate-lengths.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { TrustHtmlPipe } from './trust-html.pipe';
import { TurnoverAttachmentPipe } from './turnover-attachment.pipe';
import { UppercaseFirstLetterOfWordsPipe } from './uppercase-first-letter-of-words-pipe';
import { UtcDatePipe } from './utc-date.pipe';
import { ValueIfNonZeroPipe } from './valueIfNonZero.pipe';
import { ValueIfPositivePipe } from './valueIfPositive.pipe';
import { WorkflowPhaseDonePipe } from './workflow-phase-done.pipe';
import { WorkflowPhaseTypePipe } from './workflow-phase-type.pipe';
import { WorkflowStepDonePipe } from './workflow-step-done.pipe';
import { YesEmptyPipe } from './yesEmpty.pipe';

const PIPES = [
  TrustHtmlPipe,
  PluralizationPipe,
  ValueIfNonZeroPipe,
  ValueIfPositivePipe,
  YesEmptyPipe,
  TodayDatePipe,
  FormatToAvatarPipe,
  KpiHeaderPipe,
  KpiValuePipe,
  KpiTaskValuePipe,
  WorkflowPhaseTypePipe,
  KanbanHeaderWorkflowPhasePipe,
  WorkflowPhaseDonePipe,
  TextMaskPipe,
  TimeFromDatePipe,
  EnumerationValuePipe,
  IsIncludePipe,
  BidStatusTypePipe,
  TurnoverAttachmentPipe,
  DaysAgoPipe,
  CompareOperatorPipe,
  ToFixedValuePipe,
  EnumValueToDisplayTextPipe,
  ParseFloatPipe,
  GetPercentageValuePipe,
  EscapeHtmlPipe,
  FieldTypePipe,
  BooleanToStringPipe,
  UtcDatePipe,
  PhysicalAddressPipe,
  IsUserExistingPipe,
  IsSubmitRolesAndUnitsDisabledPipe,
  IsSaveGeneralRoleDisabledPipe,
  IsOddPipe,
  FileToObjectUrlPipe,
  DelinquencyGroupInvalidPipe,
  DelinquencyPristineGroupPipe,
  SectionIdPipe,
  KanbanHeaderWorkflowStepPipe,
  PriorityIconPipe,
  PriorityToCssClassPipe,
  PropertiesToRadioInputPipe,
  TurnoverPhaseToStatusPipe,
  WorkflowPhaseToProgressValuePipe,
  TaskTableSubsectionResultCountPipe,
  RoundValuePipe,
  ReplaceValuePipe,
  PriorityCssPipe,
  DaysInStepPipe,
  FileSizePipe,
  WorkflowStepDonePipe,
  LostRevenuePipe,
  ThresholdPriorityCssPipe,
  DayFromHoursPipe,
  FindByIdPipe,
  GetWorkflowStepPipe,
  StartsWithPipe,
  TimeDiffPipe,
  FirstPipe,
  GetFromGroupPipe,
  ElapsedDaysMidRangePipe,
  LabelDayInStepPipe,
  LabelDaySinceStepPipe,
  CustomCurrencyPipe,
  CustomCurrencyDotsCommasPipe,
  CustomCurrencyShortWithSuffixPipe,
  DaysPastPipe,
  HoursPastPipe,
  GetCurrentWorkflowStepPipe,
  MeasureTypePipe,
  NumberPipe,
  NumberValidationPipe,
  MentionCommentPipe,
  ThresholdConfigurationPipe,
  ThresholdStepConfigurationPipe,
  TruncateTextPipe,
  HumanizePipe,
  CompactPipe,
  PhoneNumberPipe,
  LabelAvailableDayInStepPipe,
  NotEmptyPropsAmountPipe,
  UppercaseFirstLetterOfWordsPipe,
  ElapsedDatePipe,
  EnumerationUserPipe,
  ValueLengthPipe,
  BoardCurrencyPipe,
  FileMimeTypePipe,
  UserDisplayNamePipe,
  FindByPropPipe,
  WorkflowStepNamePipe,
  TruncateLengthPipe,
  PmsTypeEnumerationPipe,
  ConditionalDatePipe,
  BoardTypePipe,
];

@NgModule({
  exports: [...PIPES],
  imports: [NgxMaskModule],
  declarations: [...PIPES],
  providers: [...PIPES, CurrencyPipe, MaskPipe],
})
export class PipesModule {}
