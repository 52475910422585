import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';

import { environment } from '../../environments/environment';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DeepLinkService extends PuSubscribable {
  constructor(private router: Router) {
    super();
    this.initializeDeepLinkHandler();
  }

  private initializeDeepLinkHandler(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        const url = new URL(window.location.href);
        const hasLockFullScreen = url.searchParams.has('lockFullScreen');

        if (hasLockFullScreen && this.handleDeepLink(event.url)) {
          const currentUrl = this.router.url;
          this.router.navigateByUrl(currentUrl, { skipLocationChange: true });
        }
      })
      .untilDestroyed(this);
  }

  private isDeepLinkRoute(path: string): boolean {
    const ticketMatch = /^\/tickets\/\d+$/.test(path);
    const inspectionMatch = /^\/inspections\/\d+$/.test(path);
    return ticketMatch || inspectionMatch;
  }

  private handleDeepLink(url: string): boolean {
    try {
      const [pathWithoutParams] = url.split('?');

      if (!this.isDeepLinkRoute(pathWithoutParams)) {
        return false;
      }

      const path = pathWithoutParams.startsWith('/') ? pathWithoutParams.substring(1) : pathWithoutParams;

      const baseScheme = environment.deepLinkScheme;
      if (!baseScheme) {
        return false;
      }
      const deepLink = `${baseScheme}${path}`;
      window.location.href = deepLink;
      return true;
    } catch {
      return false;
    }
  }
}
