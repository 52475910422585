import { AbstractControl, ValidatorFn } from '@angular/forms';

export const RequiredOneOfValidator = (fields: string[], isValid = false): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const result = fields.some(
      field =>
        control.value[field] !== null &&
        control.value[field] !== undefined &&
        typeof control.value[field] === 'string' &&
        control.value[field]?.trim() !== ''
    );
    return result || isValid ? null : { requiredOneOf: { fields } };
  };
};
