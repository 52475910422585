import { Injectable } from '@angular/core';
import { first } from 'lodash';
import { BehaviorSubject, Observable, finalize, of, switchMap } from 'rxjs';

import { RestapiService, RestapiServiceWithoutTimezone } from '@app/services/restapi.service';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';
import { TimezoneHelper } from '@main-application/management/pages/system-configuration/components/timezone.helper';
import { TurnoverStepTimer } from '@main-application/turnovers/interfaces/timers.interface';
import { TurnoverAttachmentType } from '@shared/enums/attachment-type';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import {
  PostTurnoverModel,
  RestArchivedTurnover,
  RestTurnoverDataModel,
  RestTurnoverTaskModel,
  RestWorkflowTemplate,
  UpdateTypedAttachmentToEntityModel,
} from '@shared/interfaces/turnover.interface';
import {
  SummaryStatsGroup,
  TurnoversSummaryStatsDetails,
} from '@ui-components/components/summary-expandable-table/summary-expandable-table.model';

import { CacheService } from './cache.service';

export enum TurnoverSummaryQueryType {
  AllTurns,
  ActiveTurns,
  CompletedTurns,
}

export enum TurnoverPriorityType {
  None,
  Low,
  Medium,
  High,
}

export enum TurnoverSummaryGroupType {
  None,
  Collection,
  Property,
  Portfolio,
  UnitType,
}

export interface TurnoverSummaryFilters {
  portfolioId?: number;
  assigneeId?: number;
  collectionIds?: string;
  propertyIds?: string;
  unitType?: number;
  startDate?: string;
  endDate?: string;
  queryType?: TurnoverSummaryQueryType;
  threshold?: TurnoverPriorityType;
  groupBy?: TurnoverSummaryGroupType;
  subGroupBy?: TurnoverSummaryGroupType;
}

class TurnoverPatchOperation<T> {
  op: 'add' | 'replace' | 'remove';
  path: KeyOfClassWithType<RestTurnoverDataModel, T> | string;
  value: T;
}

type KeyOfClassWithType<Class, AllowedType> = {
  [Property in keyof Class]: Class[Property] extends AllowedType ? Property : never;
}[keyof Class];

@Injectable({
  providedIn: 'root',
})
export class TurnoversService {
  kanbanListLoading = new BehaviorSubject<boolean>(false);
  kanbanListLoading$ = this.kanbanListLoading.asObservable();

  constructor(
    private restApiService: RestapiService,
    private cacheService: CacheService,
    private restapiWithoutTimezone: RestapiServiceWithoutTimezone
  ) {}

  create(postTurnoverModel: PostTurnoverModel): Observable<RestTurnoverDataModel> {
    return this.restApiService.create<RestTurnoverDataModel, PostTurnoverModel>(
      `Turnovers`,
      postTurnoverModel,
      null,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  public static fixTimezoneForTurnoverTaskModel(data: RestTurnoverTaskModel[], timezone: RestTimezone) {
    data.forEach(e =>
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient(e.turnoverData as RestTurnoverDataModel, timezone)
    );
    return data;
  }

  public static fixTimezoneForArchivedTurnoverTaskModel(data: RestArchivedTurnover[], timezone: RestTimezone) {
    data.forEach(e => TimezoneEntityHelper.fixTimezoneForArchivedTurnoverDataModelToClient(e, timezone));
    return data;
  }

  getTurnover(turnoverId: number): Observable<RestTurnoverDataModel> {
    return this.restApiService.getData<RestTurnoverDataModel>(
      `Turnovers/${turnoverId}`,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  update(turnoverData: RestTurnoverDataModel): Observable<RestTurnoverDataModel> {
    return this.restApiService.update<RestTurnoverDataModel, RestTurnoverDataModel>(
      `Turnovers/${turnoverData.id}`,
      turnoverData,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToServer,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  patch<T>(id: number, patchData: TurnoverPatchOperation<T>[]): Observable<RestTurnoverDataModel> {
    return this.restApiService.customPatchData<RestTurnoverDataModel, TurnoverPatchOperation<T>[]>(
      `Turnovers/${id}`,
      patchData,
      data => {
        data.forEach(e => {
          if (['dateMoveIn', 'dateMoveOut', 'pmsMoveOutDate', 'dateAvailable', 'dateAvailable'].includes(e.path)) {
            e.value = TimezoneHelper.removeTimeZone(e.value as Date) as T;
          }
        });
        return data;
      },
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  updateMoveOutDateSortOrder(turnoverId: number, boardId: number, sortOrderValue: number): Observable<void> {
    return this.restApiService.post(`Turnovers/${turnoverId}/UpdateMoveOutPosition`, {
      boardId,
      position: sortOrderValue,
    });
  }

  updateMoveInDateSortOrder(turnoverId: number, boardId: number, sortOrderValue: number): Observable<void> {
    return this.restApiService.post(`Turnovers/${turnoverId}/UpdateMoveInPosition`, {
      boardId,
      position: sortOrderValue,
    });
  }

  addUnitToBoard(turnoverId: number, boardId: number, isCompleted: boolean): Observable<void> {
    return this.restApiService.post(`Turnovers/${turnoverId}/addToBoard/${boardId}/${isCompleted}`, {
      id: turnoverId,
      boardLayoutId: boardId,
      isCompleted: isCompleted,
    });
  }

  deleteUnitFromBoard(turnoverId: number, boardId: number): Observable<void> {
    return this.restApiService.delete(`Turnovers/${turnoverId}/addToBoard/${boardId}`);
  }

  patchAdditionalData<T>(
    turnoverId: number,
    boardId: number,
    patchData: TurnoverPatchOperation<T>[]
  ): Observable<RestTurnoverDataModel> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');

    return this.restApiService.customPatchData<RestTurnoverDataModel, TurnoverPatchOperation<T>[]>(
      `Turnovers/additionalData/${turnoverId}/${boardId}`,
      patchData
    );
  }

  setAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<RestGenericTypedAttachment> {
    return this.restApiService.create<RestGenericTypedAttachment>(
      `Turnovers/${first(attachmentToEntity.turnoverIds)}/Attachments`,
      attachmentToEntity
    );
  }

  setAttachments(
    turnoverId: number,
    attachmentType: TurnoverAttachmentType,
    attachmentToEntity: UpdateTypedAttachmentToEntityModel[]
  ): Observable<RestGenericTypedAttachment> {
    return this.restApiService.create<RestGenericTypedAttachment>(
      `Turnovers/${turnoverId}/bulk/attachments?attachmentType=${attachmentType}`,
      {
        attachFileIds: attachmentToEntity.map(a => a.fileUploadId),
      }
    );
  }

  updateAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<boolean> {
    return this.restApiService.update<boolean>(
      `Turnovers/${first(attachmentToEntity.turnoverIds)}/Attachments`,
      attachmentToEntity
    );
  }

  getPropertyTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/property/${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  getCachedPortfolioTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    this.kanbanListLoading.next(true);
    return this.restApiService
      .getData<number>(`Turnovers/getlatestversionbyportfolioid?portfolioId=${portfolioId}`)
      .pipe(
        switchMap(version => {
          return this.cacheService.getCachedInfo(
            'getPortfolioTurnovers',
            portfolioId,
            (id: number) => this.getPortfolioTurnovers(id),
            version
          );
        }),
        finalize(() => this.kanbanListLoading.next(false))
      );
  }

  getArchivedPortfolioTurnovers(portfolioId: number): Observable<RestArchivedTurnover[]> {
    return this.restApiService.getData<RestArchivedTurnover[]>(
      `Turnovers/portfolio/${portfolioId}/archived`,
      TurnoversService.fixTimezoneForArchivedTurnoverTaskModel
    );
  }

  getPortfolioTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/portfolio/${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel,
      null,
      'v2'
    );
  }

  getAllTasks(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/alltasks?portfolioId=${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  deleteTurnover(turnoverId: number, disableAutoTurnCreation = false): Observable<boolean> {
    const route = `Turnovers/${turnoverId}${disableAutoTurnCreation ? '?disableAutoTurnCreation=true' : ''}`;
    return this.restApiService.delete(route);
  }

  deleteTurnoverAttachment(turnoverId: number, attachmentId: number): Observable<boolean> {
    return this.restApiService.delete(`Turnovers/${turnoverId}/Attachments/${attachmentId}`);
  }

  getTurnoversAttachments(turnoverId: number): Observable<RestGenericTypedAttachment[]> {
    return this.restApiService.getData<RestGenericTypedAttachment[]>(`Turnovers/${turnoverId}/Attachments`);
  }

  toggleAllSections(allPropertyIds: number[], active: boolean): Observable<number[]> {
    return of<number[]>(active ? allPropertyIds : []);
  }

  toggleSingleSection(activePropertyIds: number[], propertyId: number, active: boolean): Observable<number[]> {
    if (active) {
      return of<number[]>([...activePropertyIds, propertyId]);
    }
    return of<number[]>(activePropertyIds.filter(value => value !== propertyId));
  }

  getTurnoversTimers(turnoverId: number): Observable<TurnoverStepTimer[]> {
    return this.restapiWithoutTimezone.getData<TurnoverStepTimer[]>(`Turnovers/${turnoverId}/timers`);
  }

  updateTurnoversTimers(turnoverId: number, timers: TurnoverStepTimer[]): Observable<TurnoverStepTimer[]> {
    return this.restApiService.create<TurnoverStepTimer[]>(`Turnovers/${turnoverId}/updateTimersData`, timers);
  }

  restoreTurnover(turnoverId: number) {
    return this.restApiService.post(`Turnovers/restore/${turnoverId}`);
  }

  getWorkflowTemplates(): Observable<RestWorkflowTemplate[]> {
    return this.restApiService.getData(`Turnovers/GetWorkflowTemplates`);
  }

  recalculatePastTimerKpis(days: number) {
    return this.restApiService.post(`AdminPanel/recalculatePastTimerKpis/${days}`, days);
  }

  getTurnoverSummaryStats(filter: TurnoverSummaryFilters): Observable<SummaryStatsGroup[]> {
    let url = `turnovers/summaryStats/${filter.groupBy ?? 0}/${filter.subGroupBy ?? 0}?`;

    if (filter.portfolioId !== null && filter.portfolioId !== undefined) url += `&portfolioId=${filter.portfolioId}`;
    if (filter.collectionIds) url += `&collectionIds=${filter.collectionIds}`;
    if (filter.propertyIds) url += `&propertyIds=${filter.propertyIds}`;
    if (filter.unitType) url += `&unitType=${filter.unitType}`;
    if (filter.startDate) url += `&startDate=${filter.startDate}`;
    if (filter.endDate) url += `&endDate=${filter.endDate}`;
    if (filter.queryType !== null && filter.queryType !== undefined) url += `&queryType=${filter.queryType}`;
    if (filter.threshold !== null && filter.threshold !== undefined) url += `&threshold=${filter.threshold}`;
    if (filter.assigneeId !== null && filter.assigneeId !== undefined) url += `&assigneeId=${filter.assigneeId}`;

    return this.restApiService.getData<SummaryStatsGroup[]>(url);
  }

  getTurnoverSummaryDetails(filter: TurnoverSummaryFilters): Observable<TurnoversSummaryStatsDetails> {
    let url = `turnovers/summaryStatsDetails?`;

    if (filter.portfolioId !== null && filter.portfolioId !== undefined) url += `&portfolioId=${filter.portfolioId}`;
    if (filter.collectionIds) url += `&collectionIds=${filter.collectionIds}`;
    if (filter.propertyIds) url += `&propertyIds=${filter.propertyIds}`;
    if (filter.unitType) url += `&unitType=${filter.unitType}`;
    if (filter.startDate) url += `&startDate=${filter.startDate}`;
    if (filter.endDate) url += `&endDate=${filter.endDate}`;
    if (filter.queryType !== null && filter.queryType !== undefined) url += `&queryType=${filter.queryType}`;
    if (filter.threshold !== null && filter.threshold !== undefined) url += `&threshold=${filter.threshold}`;
    if (filter.assigneeId !== null && filter.assigneeId !== undefined) url += `&assigneeId=${filter.assigneeId}`;

    return this.restApiService.getData<TurnoversSummaryStatsDetails>(url);
  }
}
