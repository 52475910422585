import { InspectionsSummarySelectFilterConfig } from '@main-application/inspections/components/inspections-list/components/inspections-summary/inspections-summary.model';
import { InspectionsListConstants } from '@main-application/inspections/components/inspections-list/constants';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartDataItem } from '@shared/interfaces/chart-data-item';

export const FILTER_PERIOD_RECORD = {
  sevenDays: 'past 7 days',
  thirtyDays: 'past 30 days',
  threeMonths: 'past 3 months',
  sixMonths: 'past 6 months',
  year: 'past year',
  allTime: 'all time',
  thisWeek: 'this week',
  thisMonth: 'this month',
  nextTwoWeeks: 'next two weeks',
  thisQuarter: 'this quarter',
  thisYear: 'this year',
  custom: 'custom',
};

export const SELECT_FILTER_PERIOD_CONFIG: InspectionsSummarySelectFilterConfig[] = [
  {
    id: InspectionsListConstants.TimeFrame.Week,
    label: FILTER_PERIOD_RECORD.sevenDays,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month,
    label: FILTER_PERIOD_RECORD.thirtyDays,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month_3,
    label: FILTER_PERIOD_RECORD.threeMonths,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month_6,
    label: FILTER_PERIOD_RECORD.sixMonths,
  },
  {
    id: InspectionsListConstants.TimeFrame.Year,
    label: FILTER_PERIOD_RECORD.year,
  },
  {
    id: InspectionsListConstants.TimeFrame.AllTime,
    label: FILTER_PERIOD_RECORD.allTime,
  },
  {
    id: InspectionsListConstants.TimeFrame.Custom,
    label: FILTER_PERIOD_RECORD.custom,
  },
  {
    id: InspectionsListConstants.TimeFrame.ThisWeek,
    label: FILTER_PERIOD_RECORD.thisWeek,
  },
  {
    id: InspectionsListConstants.TimeFrame.NextTwoWeeks,
    label: FILTER_PERIOD_RECORD.nextTwoWeeks,
  },
  {
    id: InspectionsListConstants.TimeFrame.ThisMonth,
    label: FILTER_PERIOD_RECORD.thisMonth,
  },
  {
    id: InspectionsListConstants.TimeFrame.ThisQuarter,
    label: FILTER_PERIOD_RECORD.thisQuarter,
  },
  {
    id: InspectionsListConstants.TimeFrame.ThisYear,
    label: FILTER_PERIOD_RECORD.thisYear,
  },
];

export enum INSPECTION_SUMMARY_SHOW_BY__ENUM {
  ASSIGNEE = 1,
  TYPE,
}
export const SELECT_FILTER_SHOW_BY_CONFIG: InspectionsSummarySelectFilterConfig[] = [
  {
    id: INSPECTION_SUMMARY_SHOW_BY__ENUM.ASSIGNEE,
    label: 'Assignee',
  },
  {
    id: INSPECTION_SUMMARY_SHOW_BY__ENUM.TYPE,
    label: 'Type',
  },
];

export enum INSPECTION_SUMMARY_GROUP_BY__ENUM {
  NONE = 1,
  PROPERTY = 2,
}
export const SELECT_FILTER_GROUP_BY_CONFIG: InspectionsSummarySelectFilterConfig[] = [
  {
    id: INSPECTION_SUMMARY_GROUP_BY__ENUM.NONE,
    label: 'None',
  },
  {
    id: INSPECTION_SUMMARY_GROUP_BY__ENUM.PROPERTY,
    label: 'Property',
  },
];

export enum INSPECTION_SUMMARY_ACTION__ENUM {
  DRILL_DOWN = 1,
  OPEN_IN_NEW_TAB = 2,
}

export const SELECT_FILTER_ACTION_CONFIG: InspectionsSummarySelectFilterConfig[] = [
  {
    id: INSPECTION_SUMMARY_ACTION__ENUM.DRILL_DOWN,
    label: 'Drill Down',
  },
  {
    id: INSPECTION_SUMMARY_ACTION__ENUM.OPEN_IN_NEW_TAB,
    label: 'Open in a New Tab',
  },
];

export const INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD = {
  0: 'completed',
  1: 'completed_late',
  2: 'expired',
  3: 'in_progress',
  4: 'not_started',
  5: 'late',
  6: 'review',
}; // Id by ths id record below

export const INSPECTION_SUMMARY_CHART: ChartDataItem[] = [
  {
    label: 'Completed',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Completed Late',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Expired',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'In Progress',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Not Started',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Late',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Review',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
];

export const INSPECTION_SUMMARY_EXPANDED_TABLE_DATA_BASE = {
  expandedTitle: '',
  total: {
    notStarted: 0,
    inProgress: 0,
    late: 0,
    completed: 0,
    completedLate: 0,
    total: 0,
  },
  data: [],
};

export const INSPECTION_SUMMARY_ERROR = {
  errorWrong: 'Something went wrong. Give it another try!',
  errorNoData: 'No data to display.',
};

export const Unassigned = 'Unassigned';
export const System = 'System';

export type ExpandableExpandedRecord = {
  propertyId: number;
  opened: boolean;
};
