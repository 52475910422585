export function getStorageItem<T = string>(key: string, parseUndefined = false): T | undefined {
  const storageValue = localStorage.getItem(key);
  if (storageValue) {
    try {
      if (parseUndefined) {
        let parsedValue = JSON.parse(storageValue);
        parsedValue = Object.fromEntries(
          Object.entries(parsedValue).map(([key, value]) => [key, value === '_undefined_' ? undefined : value])
        );
        return parsedValue as T;
      } else {
        const parsedValue = JSON.parse(storageValue);
        return parsedValue as T;
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export function setStorageItem<T>(key: string, value: T, includeUndefined = false) {
  try {
    if (includeUndefined) {
      const storageValue = JSON.stringify(value, (key, val) => (val === undefined ? '_undefined_' : val));
      localStorage.setItem(key, storageValue);
      return;
    } else {
      const storageValue = JSON.stringify(value);
      localStorage.setItem(key, storageValue);
    }
  } catch (err) {
    console.error(err);
  }
}

export function parseJSON<T>(json: string) {
  return json != null ? (JSON.parse(json) as T) : undefined;
}
