import { createReducer, on } from '@ngrx/store';

import { TurnoverStepTimer } from '@main-application/turnovers/interfaces/timers.interface';
import { ValidationParams } from '@main-application/turnovers/interfaces/validation-params.interface';
import { UpdateStatus } from '@shared/enums/update-status';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestApplianceModel } from '@shared/interfaces/appliance.interface';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { RestBidModel, RestTypedBidAttachment } from '@shared/interfaces/bid.interface';
import { RestCommentModel, RestWorkflowStepCommentModel } from '@shared/interfaces/comments.interface';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { KanbanListItem } from '@shared/interfaces/kanban-list-item.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestWorkflowStepAssignmentModel } from '@shared/interfaces/rest-workflow-step-assignment-model.interface';
import {
  RestTicketModel,
  RestTurnoverDataModel,
  RestTypedTicketAttachment,
  RestTypedTurnoverAttachment,
  RestWorkflowInstanceModel,
  TurnoverStepInfo,
  UpdateTypedAttachmentToEntityModel,
} from '@shared/interfaces/turnover.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import {
  SummaryStatsGroup,
  TurnoversSummaryStatsDetails,
} from '@ui-components/components/summary-expandable-table/summary-expandable-table.model';

import {
  addNewBid,
  addNewBidFail,
  addNewBidSuccess,
  addNewTurnoverTicket,
  addNewTurnoverTicketFailed,
  addNewTurnoverTicketSuccess,
  addWorkflowStepComment,
  addWorkflowStepCommentFailed,
  addWorkflowStepCommentReactionSuccess,
  addWorkflowStepCommentSuccess,
  cleanTurnoverStore,
  clearAddedAttachment,
  clearAddedBidAttachment,
  clearAddedTicketAttachment,
  clearBid,
  clearNewBidList,
  clearTicketPreview,
  clearTurnoverAttachments,
  clearTurnoverData,
  clearTurnoverTimers,
  clearUpdatedBid,
  clearWorkflowStepCommentList,
  createNewTurnover,
  createNewTurnoverFailed,
  createNewTurnoverSuccess,
  deleteBidAttachment,
  deleteBidAttachmentFailed,
  deleteBidAttachmentSuccess,
  deleteFileFromRepositoryFailed,
  deleteFileFromRepositorySuccess,
  deleteTurnover,
  deleteTurnoverAttachment,
  deleteTurnoverAttachmentFailed,
  deleteTurnoverAttachmentSuccess,
  deleteTurnoverFailed,
  deleteTurnoverSuccess,
  deleteTurnoverTicket,
  deleteTurnoverTicketFailed,
  deleteTurnoverTicketSuccess,
  deleteWorkflowStepComment,
  deleteWorkflowStepCommentFailed,
  deleteWorkflowStepCommentSuccess,
  getAllBidList,
  getAllBidListFail,
  getAllBidListSuccess,
  getAppliance,
  getApplianceFailed,
  getApplianceSuccess,
  getBid,
  getBidAttachmentList,
  getBidAttachmentListFailed,
  getBidAttachmentListSuccess,
  getBidCompanyList,
  getBidCompanyListFail,
  getBidCompanyListSuccess,
  getBidFail,
  getBidSuccess,
  getBidTurnoverList,
  getBidTurnoverListFail,
  getBidTurnoverListSuccess,
  getCompanyList,
  getCompanyListFail,
  getCompanyListSuccess,
  getDefaultTurnoversAssignees,
  getDefaultTurnoversAssigneesFailed,
  getDefaultTurnoversAssigneesSuccess,
  getSelectedTicket,
  getSelectedTicketFailed,
  getSelectedTicketSuccess,
  getTicket,
  getTicketAttachments,
  getTicketAttachmentsFailed,
  getTicketAttachmentsSuccess,
  getTicketFailed,
  getTicketSuccess,
  getTurnoverAttachments,
  getTurnoverAttachmentsFailed,
  getTurnoverAttachmentsSuccess,
  getTurnoverData,
  getTurnoverDataFailed,
  getTurnoverDataSuccess,
  getTurnoverTickets,
  getTurnoverTicketsFailed,
  getTurnoverTicketsSuccess,
  getWorkflowStepCommentList,
  getWorkflowStepCommentListFailed,
  getWorkflowStepCommentListSuccess,
  inspectTurnover,
  loadTurnoverSummaryDetails,
  loadTurnoverSummaryDetailsFailed,
  loadTurnoverSummaryDetailsSuccess,
  loadTurnoverSummaryStats,
  loadTurnoverSummaryStatsFailed,
  loadTurnoverSummaryStatsSuccess,
  newBidInProgress,
  rejectBid,
  rejectBidFail,
  rejectBidSuccess,
  reloadAppliancesNeeded,
  reloadAppliancesNeededEnd,
  removeWorkflowStepCommentReactionSuccess,
  resetCreateNewTurnover,
  resetKanbanCounters,
  resetNewTurnoverTicket,
  resetWorkflowStepStatus,
  setActiveSections,
  setAllPropertyIds,
  setAllUserList,
  setAllUserRadioList,
  setApplianceTypeList,
  setAttachmentToTurnover,
  setAttachmentToTurnoverFailed,
  setAttachmentToTurnoverSuccess,
  setBidAttachment,
  setBidAttachmentFail,
  setBidAttachmentSuccess,
  setColorTypeList,
  setCompanyEmailRadioList,
  setCompanyNameRadioList,
  setCompanyRadioList,
  setCurrentWorkflowStep,
  setCurrentWorkflowStepModel,
  setDisplayedPropertyList,
  setInactiveFilteredSections,
  setSectionLostRevenue,
  setStepAssignee,
  setStepAssigneeFailed,
  setStepAssigneeSuccess,
  setStepInfo,
  setTicketAttachment,
  setTicketAttachmentFail,
  setTicketAttachmentSuccess,
  setUserList,
  setUserListModel,
  setWorkflowStepSuccess,
  turnoverTimersLoaded,
  updateBid,
  updateBidComment,
  updateBidCommentFail,
  updateBidCommentSuccess,
  updateBidFail,
  updateBidSuccess,
  updateTicketPreview,
  updateTicketPreviewFailed,
  updateTicketPreviewSuccess,
  updateTurnoverAttachment,
  updateTurnoverAttachmentFailed,
  updateTurnoverAttachmentSuccess,
  updateTurnoverTicket,
  updateTurnoverTicketFailed,
  updateTurnoverTicketSuccess,
  updateValidationParams,
  updateWorkflowStepComment,
  updateWorkflowStepCommentFailed,
  updateWorkflowStepCommentSuccess,
  workflowAssignmentsByPropertyAndStepIdSucceded,
} from '../actions/turnovers.actions';

export const turnoversFeatureKey = 'turnovers';

export interface State {
  turnoverData: RestTurnoverDataModel;
  updatedTurnoverData: RestTurnoverDataModel;
  emptyTurnoverData: boolean;
  createTurnoverLoading: boolean;
  createdTurnover: RestTurnoverDataModel;
  turnoverDataLoading: boolean;
  commentItem: RestCommentModel;
  setWorkflowStepStatus: UpdateStatus;
  nextWorkflowStep: WorkflowStepEnumType;
  setWorkflowStepLoading: boolean;
  currentWorkflowStep: WorkflowStepEnumType;
  currentWorkflowStepModel: RestWorkflowInstanceModel;
  applianceTypeList: IRadioButtonOption<number>[];
  colorTypeList: IRadioButtonOption<number>[];
  userList: IRadioButtonOption<number>[];
  userListModel: RestUserModel[];
  companyRadioList: IRadioButtonOption<number>[];
  companyNameRadioList: IRadioButtonOption<number>[];
  companyEmailRadioList: IRadioButtonOption<number>[];
  attachmentToEntity: UpdateTypedAttachmentToEntityModel;
  addBidAttachmentInProgress: boolean;
  addBidAttachmentStatus: UpdateStatus;
  addedAttachment: RestGenericTypedAttachment;
  addedBidAttachment: RestTypedBidAttachment;
  addedTicketAttachment: RestTypedTicketAttachment;
  turnoverTicketList: RestTicketModel[];
  turnoverTicketListLoading: boolean;
  newTurnoverTicket: RestTicketModel;
  newTurnoverTicketLoading: boolean;
  editedTicket: RestTicketModel;
  selectedTicket: RestTicketModel;
  selectedTicketLoading: boolean;
  addNewApplianceNeededLoading: boolean;
  isNewApplianceNeededAdd: boolean;
  markApplianceAsDoneLoading: boolean;
  isMarkApplianceAsDone: boolean;
  companyList: RestCompanyModel[];
  companyListLoading: boolean;
  newBidList: RestBidModel[];
  newBidListInProgress: boolean;
  bidTurnoverList: RestBidModel[];
  bidTurnoverListLoading: boolean;
  bidCompanyList: RestBidModel[];
  bidCompanyListLoading: boolean;
  bid: RestBidModel;
  editedBid: RestBidModel;
  bidLoading: boolean;
  updatedBid: RestBidModel;
  updatedBidLoading: boolean;
  updatedBidCommentLoading: boolean;
  appliance: RestApplianceModel;
  applianceLoading: boolean;
  workflowStepCommentList: RestWorkflowStepCommentModel[];
  workflowStepCommentListLoading: boolean;
  workflowStepCommentUpdateLoading: boolean;
  workflowStepAssigneeId: number;
  workflowStepAssignmentLoading: boolean;
  reloadAppliancesNeeded: boolean;
  deleteTurnoverInProgress: boolean;
  turnoverDeleteStatus: UpdateStatus;
  deleteTurnoverAttachmentInProgress: boolean;
  turnoverDeleteAttachmentStatus: UpdateStatus;
  addTurnoverAttachmentInProgress: boolean;
  addTurnoverAttachmentStatus: UpdateStatus;
  stepInfo: TurnoverStepInfo;
  allAttachments: RestTypedTurnoverAttachment[];
  allAttachmentsLoading: boolean;
  deleteBidAttachmentInProgress: boolean;
  deleteBidAttachmentStatus: UpdateStatus;
  bidAttachments: RestTypedBidAttachment[];
  bidAttachmentsLoading: boolean;
  updateTicketInProgress: boolean;
  updateTurnoverAttachmentInProgress: boolean;
  updateTurnoverAttachmentStatus: UpdateStatus;

  allBidList: RestBidModel[];
  allBidListLoading: boolean;

  ticket: RestTicketModel;
  ticketLoading: boolean;

  ticketAttachments: RestTypedTicketAttachment[];
  ticketAttachmentsLoading: boolean;

  ticketUpdateInProgress: boolean;
  ticketUpdateInProgressStatus: UpdateStatus;

  inspectTurnoverId: number;

  validationParams: ValidationParams;

  allPropertyIds: number[];
  activePropertyIds: number[];
  inactiveFilteredPropertyIds: number[];

  updateStepAssigneeInProgress: boolean;
  updateStepAssigneeStatus: UpdateStatus;

  defaultTurnoversAssignees: RestWorkflowStepAssignmentModel[];
  defaultTurnoversAssigneesLoading: boolean;

  allUserRadioList: IRadioButtonOption<number>[];
  allUserList: RestUserModel[];
  displayedPropertyList: KanbanListItem[];
  sectionsLostRevenue: { [key: number]: number };
  rejectBidLoading: boolean;

  timers: TurnoverStepTimer[];

  turnoversSummaryStats: SummaryStatsGroup[] | null;
  turnoversSummaryDetails: TurnoversSummaryStatsDetails | null;
  turnoversSummaryLoading: boolean;
  turnoversSummaryError: any;
}

export const initialState: State = {
  turnoverData: null,
  updatedTurnoverData: null,
  emptyTurnoverData: false,
  createTurnoverLoading: false,
  createdTurnover: null,
  turnoverDataLoading: false,
  commentItem: null,
  setWorkflowStepStatus: null,
  nextWorkflowStep: null,
  setWorkflowStepLoading: false,
  currentWorkflowStep: null,
  currentWorkflowStepModel: null,
  applianceTypeList: [],
  colorTypeList: [],
  userList: [],
  userListModel: [],
  companyRadioList: [],
  companyNameRadioList: [],
  companyEmailRadioList: [],
  attachmentToEntity: null,
  addBidAttachmentInProgress: false,
  addBidAttachmentStatus: null,
  addedAttachment: null,
  addedBidAttachment: null,
  addedTicketAttachment: null,
  turnoverTicketList: [],
  turnoverTicketListLoading: false,
  newTurnoverTicket: null,
  newTurnoverTicketLoading: false,
  editedTicket: null,
  selectedTicket: null,
  selectedTicketLoading: false,
  addNewApplianceNeededLoading: false,
  isNewApplianceNeededAdd: false,
  markApplianceAsDoneLoading: false,
  isMarkApplianceAsDone: false,
  companyList: [],
  companyListLoading: false,
  newBidList: [],
  newBidListInProgress: false,
  bidTurnoverList: [],
  bidTurnoverListLoading: false,
  bidCompanyList: [],
  bidCompanyListLoading: false,
  bid: null,
  editedBid: null,
  bidLoading: false,
  updatedBid: null,
  updatedBidLoading: false,
  updatedBidCommentLoading: false,
  appliance: null,
  applianceLoading: false,
  workflowStepCommentList: [],
  workflowStepCommentListLoading: false,
  workflowStepCommentUpdateLoading: false,
  workflowStepAssigneeId: null,
  workflowStepAssignmentLoading: false,
  reloadAppliancesNeeded: false,
  deleteTurnoverInProgress: false,
  turnoverDeleteStatus: null,
  deleteTurnoverAttachmentInProgress: false,
  turnoverDeleteAttachmentStatus: null,
  addTurnoverAttachmentInProgress: false,
  addTurnoverAttachmentStatus: null,
  stepInfo: null,
  allAttachments: [],
  allAttachmentsLoading: false,
  deleteBidAttachmentInProgress: false,
  deleteBidAttachmentStatus: null,
  bidAttachments: [],
  bidAttachmentsLoading: false,
  updateTicketInProgress: false,
  updateTurnoverAttachmentInProgress: false,
  updateTurnoverAttachmentStatus: null,

  allBidList: [],
  allBidListLoading: false,

  ticket: null,
  ticketLoading: false,

  ticketAttachments: [],
  ticketAttachmentsLoading: false,

  ticketUpdateInProgress: false,
  ticketUpdateInProgressStatus: null,

  inspectTurnoverId: null,

  validationParams: {},

  allPropertyIds: [],
  activePropertyIds: [],
  inactiveFilteredPropertyIds: [],

  updateStepAssigneeInProgress: false,
  updateStepAssigneeStatus: null,

  defaultTurnoversAssignees: [],
  defaultTurnoversAssigneesLoading: false,

  allUserRadioList: [],
  allUserList: [],

  displayedPropertyList: [],
  sectionsLostRevenue: {},
  rejectBidLoading: false,

  timers: null,

  turnoversSummaryStats: null,
  turnoversSummaryDetails: null,
  turnoversSummaryLoading: false,
  turnoversSummaryError: null,
};

export const reducer = createReducer(
  initialState,
  /**
   * Create new Turnover
   */
  on(createNewTurnover, state => ({
    ...state,
    createTurnoverLoading: true,
  })),
  on(createNewTurnoverSuccess, (state, action) => ({
    ...state,
    createTurnoverLoading: false,
    createdTurnover: action.turnoverData,
  })),
  on(resetCreateNewTurnover, createNewTurnoverFailed, state => ({
    ...state,
    createTurnoverLoading: false,
    createdTurnover: null,
  })),

  /**
   * Get turnover data
   */
  on(clearTurnoverData, state => ({
    ...state,
    turnoverDataLoading: false,
    emptyTurnoverData: false,
    turnoverData: null,
  })),
  on(getTurnoverData, state => ({
    ...state,
    turnoverDataLoading: true,
    emptyTurnoverData: false,
  })),
  on(getTurnoverDataSuccess, (state, action) => ({
    ...state,
    turnoverData: action.turnoverData,
    turnoverDataLoading: false,
    emptyTurnoverData: false,
  })),
  on(getTurnoverDataFailed, state => ({
    ...state,
    turnoverData: null,
    turnoverDataLoading: false,
    emptyTurnoverData: true,
  })),
  /**
   * Set workflow step
   */
  on(setWorkflowStepSuccess, (state, action) => ({
    ...state,
    setWorkflowStepStatus: UpdateStatus.OK,
    nextWorkflowStep: action.nextWorkflowStep,
    setWorkflowStepLoading: false,
  })),
  on(resetWorkflowStepStatus, state => ({
    ...state,
    setWorkflowStepStatus: null,
    nextWorkflowStep: null,
  })),

  /**
   * set current workflow step
   */
  on(setCurrentWorkflowStep, (state, action) => ({
    ...state,
    currentWorkflowStep: action.currentWorkflowStep,
  })),

  on(setCurrentWorkflowStepModel, (state, action) => ({
    ...state,
    currentWorkflowStepModel: action.currentWorkflowStepModel,
  })),

  /**
   * set attachment to turnover
   */
  on(setAttachmentToTurnover, (state, action) => ({
    ...state,
    attachmentToEntity: action.attachmentToEntity,
    addedAttachment: null,
    addTurnoverAttachmentInProgress: true,
    addTurnoverAttachmentStatus: null,
  })),
  on(setAttachmentToTurnoverSuccess, (state, action) => ({
    ...state,
    addedAttachment: action.addedAttachment,
    addTurnoverAttachmentInProgress: false,
    addTurnoverAttachmentStatus: UpdateStatus.OK,
  })),
  on(setAttachmentToTurnoverFailed, state => ({
    ...state,
    addedAttachment: null,
    addTurnoverAttachmentInProgress: false,
    addTurnoverAttachmentStatus: UpdateStatus.ERROR,
  })),
  on(clearAddedAttachment, state => ({
    ...state,
    addedAttachment: null,
  })),

  /**
   * update turnover attachment
   */
  on(updateTurnoverAttachment, state => ({
    ...state,
    updateTurnoverAttachmentInProgress: true,
    updateTurnoverAttachmentStatus: UpdateStatus.UPDATE,
  })),
  on(updateTurnoverAttachmentSuccess, state => ({
    ...state,
    updateTurnoverAttachmentInProgress: false,
    updateTurnoverAttachmentStatus: UpdateStatus.OK,
  })),
  on(updateTurnoverAttachmentFailed, state => ({
    ...state,
    updateTurnoverAttachmentInProgress: false,
    updateTurnoverAttachmentStatus: UpdateStatus.ERROR,
  })),

  /**
   * get turnover tickets
   */
  on(getTurnoverTickets, state => ({
    ...state,
    turnoverTicketListLoading: true,
  })),
  on(getTurnoverTicketsSuccess, (state, action) => ({
    ...state,
    turnoverTicketList: action.turnoverTicketList,
    turnoverTicketListLoading: false,
  })),
  on(getTurnoverTicketsFailed, state => ({
    ...state,
    turnoverTicketList: [],
    turnoverTicketListLoading: false,
  })),

  /**
   * add new turnover ticket
   */
  on(addNewTurnoverTicket, state => ({
    ...state,
    newTurnoverTicket: null,
    newTurnoverTicketLoading: true,
  })),
  on(addNewTurnoverTicketSuccess, (state, action) => ({
    ...state,
    newTurnoverTicket: action.newTurnoverTicket,
    newTurnoverTicketLoading: false,
  })),
  on(addNewTurnoverTicketFailed, state => ({
    ...state,
    newTurnoverTicket: null,
    newTurnoverTicketLoading: false,
  })),
  on(resetNewTurnoverTicket, state => ({
    ...state,
    newTurnoverTicket: null,
  })),

  /**
   * update turnover tickets
   */
  on(updateTurnoverTicket, state => ({
    ...state,
    editedTicket: null,
    updateTicketInProgress: true,
  })),
  on(updateTurnoverTicketSuccess, (state, action) => ({
    ...state,
    editedTicket: action.editedTicket,
    updateTicketInProgress: false,
  })),
  on(updateTurnoverTicketFailed, state => ({
    ...state,
    editedTicket: null,
    updateTicketInProgress: false,
  })),

  /**
   * update ticket preview
   */
  on(updateTicketPreview, state => ({
    ...state,
    editedTicket: null,
    ticketUpdateInProgress: true,
    ticketUpdateInProgressStatus: UpdateStatus.UPDATE,
  })),
  on(updateTicketPreviewSuccess, (state, action) => ({
    ...state,
    editedTicket: action.editedTicket,
    ticketUpdateInProgress: false,
    ticketUpdateInProgressStatus: UpdateStatus.OK,
  })),
  on(updateTicketPreviewFailed, state => ({
    ...state,
    editedTicket: null,
    ticketUpdateInProgress: false,
    ticketUpdateInProgressStatus: UpdateStatus.ERROR,
  })),

  /**
   * update turnover tickets
   */
  on(getSelectedTicket, state => ({
    ...state,
    selectedTicket: null,
    selectedTicketLoading: true,
  })),
  on(getSelectedTicketSuccess, (state, action) => ({
    ...state,
    selectedTicket: action.ticket,
    selectedTicketLoading: false,
  })),
  on(getSelectedTicketFailed, state => ({
    ...state,
    selectedTicket: null,
    selectedTicketLoading: false,
  })),

  /**
   * delete turnover tickets
   */
  on(deleteTurnoverTicket, state => ({
    ...state,
    turnoverTicketListLoading: true,
  })),
  on(deleteTurnoverTicketSuccess, state => ({
    ...state,
    turnoverTicketListLoading: false,
  })),
  on(deleteTurnoverTicketFailed, state => ({
    ...state,
    turnoverTicketListLoading: false,
  })),

  /**
   * delete bid attachment
   */
  on(deleteBidAttachment, state => ({
    ...state,
    deleteBidAttachmentInProgress: true,
    deleteBidAttachmentStatus: null,
  })),
  on(deleteBidAttachmentSuccess, state => ({
    ...state,
    deleteBidAttachmentInProgress: false,
    deleteBidAttachmentStatus: UpdateStatus.DELETED,
  })),
  on(deleteBidAttachmentFailed, state => ({
    ...state,
    deleteBidAttachmentInProgress: false,
    deleteBidAttachmentStatus: UpdateStatus.ERROR,
  })),

  /**
   * bid attachment list
   */
  on(getBidAttachmentList, state => ({
    ...state,
    bidAttachmentsLoading: true,
  })),
  on(getBidAttachmentListSuccess, (state, action) => ({
    ...state,
    bidAttachments: action.allAttachments,
    bidAttachmentsLoading: false,
  })),
  on(getBidAttachmentListFailed, state => ({
    ...state,
    bidAttachments: [],
    bidAttachmentsLoading: false,
  })),

  /**
   * set ticket attachment
   */
  on(setTicketAttachment, (state, action) => ({
    ...state,
    attachmentToEntity: action.attachmentToEntity,
  })),
  on(setTicketAttachmentSuccess, (state, action) => ({
    ...state,
    addedTicketAttachment: action.addedTicketAttachment,
  })),
  on(setTicketAttachmentFail, state => ({
    ...state,
    addedTicketAttachment: null,
  })),
  on(clearAddedTicketAttachment, state => ({
    ...state,
    addedTicketAttachment: null,
  })),

  /**
   * get company list
   */
  on(getCompanyList, state => ({
    ...state,
    companyListLoading: true,
  })),
  on(getCompanyListSuccess, (state, action) => ({
    ...state,
    companyListLoading: false,
    companyList: action.companyList,
  })),
  on(getCompanyListFail, state => ({
    ...state,
    companyListLoading: false,
    companyList: [],
  })),

  /**
   * get Bid Turnover List
   */
  on(getBidTurnoverList, state => ({
    ...state,
    bidTurnoverListLoading: true,
  })),
  on(getBidTurnoverListSuccess, (state, action) => ({
    ...state,
    bidTurnoverListLoading: false,
    bidTurnoverList: action.bidList,
  })),
  on(getBidTurnoverListFail, state => ({
    ...state,
    bidTurnoverListLoading: false,
    bidTurnoverList: [],
  })),

  /**
   * get bid company list
   */
  on(getBidCompanyList, state => ({
    ...state,
    bidCompanyListLoading: true,
  })),
  on(getBidCompanyListSuccess, (state, action) => ({
    ...state,
    bidCompanyListLoading: false,
    bidCompanyList: action.bidList,
  })),
  on(getBidCompanyListFail, state => ({
    ...state,
    bidCompanyListLoading: false,
    bidCompanyList: [],
  })),

  /**
   * bid super admin list
   */
  on(getAllBidList, state => ({
    ...state,
    allBidListLoading: true,
  })),
  on(getAllBidListSuccess, (state, action) => ({
    ...state,
    allBidListLoading: false,
    allBidList: action.bidList,
  })),
  on(getAllBidListFail, state => ({
    ...state,
    allBidListLoading: false,
    allBidList: [],
  })),

  /**
   * get bid
   */
  on(clearBid, state => ({
    ...state,
    bidLoading: false,
    bid: null,
  })),
  on(getBid, state => ({
    ...state,
    bidLoading: true,
  })),
  on(getBidSuccess, (state, action) => ({
    ...state,
    bidLoading: false,
    bid: action.bid,
  })),
  on(getBidFail, state => ({
    ...state,
    bidLoading: false,
    bid: null,
  })),

  /**
   * add new bid
   */
  on(addNewBid, state => ({
    ...state,
    newBidList: [],
    newBidListInProgress: true,
  })),
  on(addNewBidSuccess, (state, action) => ({
    ...state,
    newBidList: action.newBidList,
  })),
  on(addNewBidFail, state => ({
    ...state,
    newBidList: [],
    newBidListInProgress: false,
  })),
  on(clearNewBidList, state => ({
    ...state,
    newBidList: [],
  })),
  on(newBidInProgress, (state, action) => ({
    ...state,
    newBidListInProgress: action.newBidListInProgress,
  })),

  /**
   * update bid
   */
  on(updateBid, state => ({
    ...state,
    updatedBidLoading: true,
    updatedBid: null,
  })),
  on(updateBidSuccess, (state, action) => ({
    ...state,
    updatedBidLoading: false,
    bid: action.updatedBid,
    updatedBid: action.updatedBid,
  })),
  on(updateBidFail, state => ({
    ...state,
    updatedBidLoading: false,
    updatedBid: null,
  })),
  on(rejectBid, state => ({
    ...state,
    rejectBidLoading: true,
  })),
  on(rejectBidSuccess, rejectBidFail, state => ({
    ...state,
    rejectBidLoading: false,
  })),
  on(clearUpdatedBid, state => ({
    ...state,
    updatedBid: null,
  })),

  /**
   * update bid comment
   */
  on(updateBidComment, state => ({
    ...state,
    updatedBidCommentLoading: true,
  })),
  on(updateBidCommentSuccess, (state, action) => ({
    ...state,
    updatedBidCommentLoading: false,
    bid: action.updatedBid,
  })),
  on(updateBidCommentFail, state => ({
    ...state,
    updatedBidCommentLoading: false,
  })),

  /**
   * set bid attachment
   */
  on(setBidAttachment, (state, action) => ({
    ...state,
    attachmentToEntity: action.attachmentToEntity,
    addBidAttachmentInProgress: true,
    addBidAttachmentStatus: null,
  })),
  on(setBidAttachmentSuccess, (state, action) => ({
    ...state,
    addedBidAttachment: action.addedBidAttachment,
    addBidAttachmentInProgress: false,
    addBidAttachmentStatus: UpdateStatus.OK,
  })),
  on(setBidAttachmentFail, state => ({
    ...state,
    addedBidAttachment: null,
    addBidAttachmentInProgress: false,
    addBidAttachmentStatus: UpdateStatus.ERROR,
  })),
  on(clearAddedBidAttachment, state => ({
    ...state,
    addedBidAttachment: null,
    bidAttachments: [],
  })),

  /**
   * get appliance
   */
  on(getAppliance, state => ({
    ...state,
    applianceLoading: true,
    emptyTurnoverData: false,
  })),
  on(getApplianceSuccess, (state, action) => ({
    ...state,
    appliance: action.appliance,
    applianceLoading: false,
    emptyTurnoverData: false,
  })),
  on(getApplianceFailed, state => ({
    ...state,
    appliance: null,
    applianceLoading: false,
    emptyTurnoverData: true,
  })),

  /**
   * get workflow step comment list
   */
  on(getWorkflowStepCommentList, state => ({
    ...state,
    workflowStepCommentListLoading: true,
  })),
  on(clearWorkflowStepCommentList, state => ({
    ...state,
    workflowStepCommentList: null,
    workflowStepCommentListLoading: false,
  })),
  on(getWorkflowStepCommentListSuccess, (state, action) => ({
    ...state,
    workflowStepCommentList: action.workflowStepCommentList,
    workflowStepCommentListLoading: false,
  })),
  on(getWorkflowStepCommentListFailed, state => ({
    ...state,
    workflowStepCommentList: [],
    workflowStepCommentListLoading: false,
  })),

  /**
   * add workflow step comment
   */
  on(addWorkflowStepComment, state => ({
    ...state,
    workflowStepCommentUpdateLoading: true,
  })),
  on(addWorkflowStepCommentSuccess, (state, action) => ({
    ...state,
    workflowStepCommentList: [...state.workflowStepCommentList, action.commentItem],
    workflowStepCommentUpdateLoading: false,
  })),
  on(addWorkflowStepCommentFailed, state => ({
    ...state,
    workflowStepCommentUpdateLoading: false,
  })),

  on(addWorkflowStepCommentReactionSuccess, (state, action) => ({
    ...state,
    workflowStepCommentList: [
      ...state.workflowStepCommentList.filter(x => x.id !== action.commentItem.id),
      action.commentItem,
    ],
  })),
  on(removeWorkflowStepCommentReactionSuccess, (state, action) => ({
    ...state,
    workflowStepCommentList: [
      ...state.workflowStepCommentList.filter(x => x.id !== action.commentItem.id),
      action.commentItem,
    ],
  })),

  /**
   * update workflow step comment
   */
  on(updateWorkflowStepComment, state => ({
    ...state,
    workflowStepCommentUpdateLoading: true,
  })),
  on(updateWorkflowStepCommentSuccess, state => ({
    ...state,
    workflowStepCommentUpdateLoading: false,
  })),
  on(updateWorkflowStepCommentFailed, state => ({
    ...state,
    workflowStepCommentUpdateLoading: false,
  })),

  /**
   * delete turnover
   */
  on(deleteTurnover, state => ({
    ...state,
    deleteTurnoverInProgress: true,
    turnoverDeleteStatus: null,
  })),
  on(deleteTurnoverSuccess, state => ({
    ...state,
    deleteTurnoverInProgress: false,
    turnoverDeleteStatus: UpdateStatus.DELETED,
  })),
  on(deleteTurnoverFailed, state => ({
    ...state,
    deleteTurnoverInProgress: false,
    turnoverDeleteStatus: UpdateStatus.ERROR,
  })),

  /**
   * delete turnover attachment
   */
  on(deleteTurnoverAttachment, state => ({
    ...state,
    deleteTurnoverAttachmentInProgress: true,
    turnoverDeleteAttachmentStatus: null,
  })),
  on(deleteTurnoverAttachmentSuccess, state => ({
    ...state,
    deleteTurnoverAttachmentInProgress: false,
    turnoverDeleteAttachmentStatus: UpdateStatus.DELETED,
  })),
  on(deleteTurnoverAttachmentFailed, state => ({
    ...state,
    deleteTurnoverAttachmentInProgress: false,
    turnoverDeleteAttachmentStatus: UpdateStatus.ERROR,
  })),

  /**
   * delete file from repository
   */
  on(deleteFileFromRepositorySuccess, state => ({
    ...state,
    deleteTurnoverAttachmentInProgress: false,
  })),
  on(deleteFileFromRepositoryFailed, state => ({
    ...state,
    deleteTurnoverAttachmentInProgress: false,
  })),

  /**
   * delete workflow step comment
   */
  on(deleteWorkflowStepComment, state => ({
    ...state,
    workflowStepCommentUpdateLoading: true,
  })),
  on(deleteWorkflowStepCommentSuccess, state => ({
    ...state,
    workflowStepCommentUpdateLoading: false,
  })),
  on(deleteWorkflowStepCommentFailed, state => ({
    ...state,
    workflowStepCommentUpdateLoading: false,
  })),

  /**
   * get turnover attachments
   */
  on(getTurnoverAttachments, state => ({
    ...state,
    allAttachmentsLoading: true,
  })),
  on(getTurnoverAttachmentsSuccess, (state, action) => ({
    ...state,
    allAttachments: action.allAttachments,
    allAttachmentsLoading: false,
  })),
  on(getTurnoverAttachmentsFailed, clearTurnoverAttachments, state => ({
    ...state,
    allAttachments: [],
    allAttachmentsLoading: false,
  })),
  /**
   * get ticket
   */
  on(getTicket, state => ({
    ...state,
    ticketLoading: true,
  })),
  on(getTicketSuccess, (state, action) => ({
    ...state,
    ticket: action.restTicketModel,
    ticketLoading: false,
  })),
  on(getTicketFailed, state => ({
    ...state,
    ticket: null,
    ticketLoading: false,
  })),
  on(getTicketFailed, state => ({
    ...state,
    ticket: null,
    ticketLoading: false,
  })),

  /**
   * get ticket attachments
   */
  on(getTicketAttachments, state => ({
    ...state,
    ticketAttachmentsLoading: true,
  })),
  on(getTicketAttachmentsSuccess, (state, action) => ({
    ...state,
    ticketAttachments: action.ticketAttachments,
    ticketAttachmentsLoading: false,
  })),
  on(getTicketAttachmentsFailed, state => ({
    ...state,
    ticketAttachments: [],
    ticketAttachmentsLoading: false,
  })),

  /**
   * clear ticket preview
   */
  on(clearTicketPreview, state => ({
    ...state,
    ticketAttachments: [],
    ticket: null,
    newTurnoverTicket: null,
  })),

  /**
   * RADIO BUTTON DROPDOWN LISTS
   */
  on(setCompanyRadioList, (state, action) => ({
    ...state,
    companyRadioList: action.companyRadioList,
  })),
  on(setCompanyNameRadioList, (state, action) => ({
    ...state,
    companyNameRadioList: action.companyNameRadioList,
  })),
  on(setCompanyEmailRadioList, (state, action) => ({
    ...state,
    companyEmailRadioList: action.companyEmailRadioList,
  })),
  on(setApplianceTypeList, (state, action) => ({
    ...state,
    applianceTypeList: action.applianceTypeList,
  })),

  on(setColorTypeList, (state, action) => ({
    ...state,
    colorTypeList: action.colorTypeList,
  })),

  on(setUserList, (state, action) => ({
    ...state,
    userList: action.userList,
  })),

  on(setUserListModel, (state, action) => ({
    ...state,
    userListModel: action.userListModel,
  })),

  on(setAllUserRadioList, (state, action) => ({
    ...state,
    allUserRadioList: action.allUserRadioList,
  })),
  on(setAllUserList, (state, action) => ({
    ...state,
    allUserList: action.allUserList,
  })),

  on(cleanTurnoverStore, state => ({
    ...state,
    ...initialState,
  })),
  on(workflowAssignmentsByPropertyAndStepIdSucceded, (state, action) => ({
    ...state,
    workflowStepAssigneeId: action.workflowStepAssigneeId,
    workflowStepAssignmentLoading: false,
  })),
  on(reloadAppliancesNeeded, state => ({
    ...state,
    reloadAppliancesNeeded: true,
  })),
  on(reloadAppliancesNeededEnd, state => ({
    ...state,
    reloadAppliancesNeeded: false,
  })),
  on(setStepInfo, (state, action) => ({
    ...state,
    stepInfo: action.stepInfo,
  })),
  on(inspectTurnover, (state, action) => ({
    ...state,
    inspectTurnoverId: action.inspectTurnoverId,
  })),

  on(updateValidationParams, (state, action) => ({
    ...state,
    validationParams: { ...state.validationParams, ...action.validationParams },
  })),
  on(resetKanbanCounters, state => ({
    ...state,
    allPropertyIds: [],
  })),
  on(setAllPropertyIds, (state, action) => ({
    ...state,
    allPropertyIds: action.allPropertyIds,
  })),
  on(setActiveSections, (state, action) => ({
    ...state,
    activePropertyIds: action.activePropertyIds,
  })),

  on(setInactiveFilteredSections, (state, action) => ({
    ...state,
    inactiveFilteredPropertyIds: action.inactiveFilteredPropertyIds,
  })),

  on(setStepAssignee, state => ({
    ...state,
    updateStepAssigneeInProgress: true,
    updateStepAssigneeStatus: UpdateStatus.UPDATE,
  })),
  on(setStepAssigneeSuccess, state => ({
    ...state,
    updateStepAssigneeInProgress: false,
    updateStepAssigneeStatus: UpdateStatus.OK,
  })),
  on(setStepAssigneeFailed, state => ({
    ...state,
    updateStepAssigneeInProgress: false,
    updateStepAssigneeStatus: UpdateStatus.ERROR,
  })),

  on(getDefaultTurnoversAssignees, state => ({
    ...state,
    defaultTurnoversAssigneesLoading: true,
  })),
  on(getDefaultTurnoversAssigneesSuccess, (state, action) => ({
    ...state,
    defaultTurnoversAssignees: action.defaultTurnoversAssignees,
    defaultTurnoversAssigneesLoading: false,
  })),
  on(getDefaultTurnoversAssigneesFailed, state => ({
    ...state,
    defaultTurnoversAssignees: [],
    defaultTurnoversAssigneesLoading: false,
  })),
  on(setDisplayedPropertyList, (state, action) => ({
    ...state,
    displayedPropertyList: action.displayedPropertyList,
  })),
  on(setSectionLostRevenue, (state, action) => ({
    ...state,
    sectionsLostRevenue: {
      ...state.sectionsLostRevenue,
      [action.propertyId]: action.lostRevenue,
    },
  })),
  on(clearTurnoverTimers, state => ({
    ...state,
    timers: [],
  })),
  on(turnoverTimersLoaded, (state, action) => ({
    ...state,
    timers: action.timers,
  })),
  on(loadTurnoverSummaryStats, state => ({ ...state, turnoversSummaryLoading: true, turnoversSummaryError: null })),
  on(loadTurnoverSummaryStatsSuccess, (state, { stats }) => ({
    ...state,
    turnoversSummaryStats: stats,
    turnoversSummaryLoading: false,
  })),
  on(loadTurnoverSummaryStatsFailed, (state, { error }) => ({
    ...state,
    turnoversSummaryLoading: false,
    turnoversSummaryError: error,
  })),
  on(loadTurnoverSummaryDetails, state => ({ ...state, turnoversSummaryLoading: true, turnoversSummaryError: null })),
  on(loadTurnoverSummaryDetailsSuccess, (state, { details }) => ({
    ...state,
    turnoversSummaryDetails: details,
    turnoversSummaryLoading: false,
  })),
  on(loadTurnoverSummaryDetailsFailed, (state, { error }) => ({
    ...state,
    turnoversSummaryLoading: false,
    turnoversSummaryError: error,
  }))
);
