<div class="bottom-sheet-container">
  <div class="bottom-sheet-header">
    <h5 [style.opacity]="searchInputCollapsed ? '1' : '0'" [style.transition]="'opacity 0.3s'">
      {{ data.label | uppercase }}
    </h5>

    <div class="display-flex align-items-center grid-gap-10">
      <app-collapsible-search-input
        *ngIf="data.allowSearch"
        [(ngModel)]="searchTerm"
        [iconColor]="searchIconColor"
        (ngModelChange)="onSearch($event)"
        [attrPlaceholder]="data.label"
        [inputCss]="'text-color dark fs s14'"
        (collapsedChange)="searchInputCollapsed = $event"
        class="button-item collapsed-search-input"></app-collapsible-search-input>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="data.multiSelect && hasMultipleItems()" class="select-all-controls">
    <button mat-button color="primary" (click)="toggleSelectAll()">
      <mat-icon>{{ isAllSelected() ? 'clear_all' : 'done_all' }}</mat-icon>
      {{ isAllSelected() ? 'Clear All' : 'Select All' }}
    </button>
  </div>

  <div *ngIf="data.allowAddNew" class="add-new-button">
    <button mat-button color="primary" (click)="onAddNew()">
      {{ data.addNewLabel }}
    </button>
  </div>

  <div class="bottom-sheet-content">
    <ng-container *ngIf="!searchTerm">
      <ng-container *ngIf="data.sections?.length; else singleList">
        <ng-container *ngFor="let section of data.sections; let i = index">
          <mat-divider *ngIf="i > 0"></mat-divider>
          <mat-selection-list [multiple]="data.multiSelect" (selectionChange)="onSelectionChange($event)">
            <mat-list-option
              checkboxPosition="before"
              *ngFor="let item of section"
              [value]="item.value"
              [selected]="isSelected(item.value)"
              [class.selected]="isSelected(item.value)">
              {{ item.label }}
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="searchTerm">
      <mat-selection-list [multiple]="data.multiSelect" (selectionChange)="onSelectionChange($event)">
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let item of filteredItems"
          [value]="item.value"
          [selected]="isSelected(item.value)"
          [class.selected]="isSelected(item.value)">
          {{ item.label }}
        </mat-list-option>
      </mat-selection-list>
    </ng-container>

    <ng-template #singleList>
      <mat-selection-list [multiple]="data.multiSelect" (selectionChange)="onSelectionChange($event)">
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let item of data.items"
          [value]="item.value"
          [selected]="isSelected(item.value)"
          [class.selected]="isSelected(item.value)">
          {{ item.label }}
        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </div>
</div>
