import { UserType } from '@shared/enums/user-type';

export enum UserPreviewFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  RoleId = 'roleId',
  UserGroupType = 'userGroupType',
  PropertyId = 'propertyId',
  UnitId = 'unitId',
  CompanyId = 'companyId',
  PhoneNumber = 'phoneNumber',
  PhoneNumberAddedAsIdentity = 'phoneNumberAddedAsIdentity',
  NativeLanguage = 'nativeLanguage',
  FederatedIssuerAssigneeId = 'federatedIssuerAssigneeId',
}

export interface UserPreview {
  [UserPreviewFields.FirstName]: string;
  [UserPreviewFields.LastName]: string;
  [UserPreviewFields.Email]: string;
  [UserPreviewFields.RoleId]: number;
  [UserPreviewFields.UserGroupType]: UserType;
  [UserPreviewFields.PropertyId]: number;
  [UserPreviewFields.UnitId]: number;
  [UserPreviewFields.CompanyId]: number;
  [UserPreviewFields.PhoneNumber]: string;
  [UserPreviewFields.PhoneNumberAddedAsIdentity]: boolean;
  [UserPreviewFields.NativeLanguage]: number;
  [UserPreviewFields.FederatedIssuerAssigneeId]: string;
}
