import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';

import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { InputModule } from '@ui-components/controls/input/input.module';

import { RadioButtonBottomSheetModule } from './radio-button-bottom-sheet/radio-button-bottom-sheet.module';
import { RadioButtonDropdownComponent } from './radio-button-dropdown.component';
import { IconComponent } from '../../components/icon/icon.component';
import { RadioButtonModule } from '../radio-button/radio-button.module';

@NgModule({
  declarations: [RadioButtonDropdownComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    RadioButtonModule,
    ReactiveFormsModule,
    IconComponent,
    MatButtonModule,
    InputModule,
    NgSelectModule,
    PipesModule,
    InputErrorModule,
    TooltipDirectiveModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    StopPropagationDirective,
    ClickOutsideDirective,
    MatButtonToggleModule,
    MatIconModule,
    RadioButtonBottomSheetModule,
  ],
  exports: [RadioButtonDropdownComponent],
})
export class RadioButtonDropdownModule {}
