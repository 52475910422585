import { createReducer, on } from '@ngrx/store';

import getFilterKey from '@main-application/administration/functions/get-filter-key';
import { UpdateStatus } from '@shared/enums/update-status';
import { getUserOrVendorList } from '@shared/functions/get-user-list.function';
import { GeneralRole } from '@shared/interfaces/general-role';
import { GeneralRoleToPermissionLevel } from '@shared/interfaces/general-role-to-permission-level';
import { PermissionLevel } from '@shared/interfaces/permission-level';
import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserToGeneralRoleMapping } from '@shared/interfaces/user-to-general-role-mapping';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import { RestUserModel, UserFilter } from '@shared/interfaces/user.interface';

import * as AdministrationActions from '../actions/administration.actions';
import { savingGeneralRoleFailed, setUserListFilter } from '../actions/administration.actions';

export const administrationFeatureKey = 'administration';

export interface IAdministrationStateTree {
  readonly [administrationFeatureKey]: State;
}

export interface State {
  portfolioActiveUsers: RestUserModel[];
  portfolioInactiveUsers: RestUserModel[];
  portfolioUsersLoading: boolean;

  activeUsers: RestUserModel[];
  activeUserOrVendorOptionsList: IRadioButtonOption<number>[];
  inactiveUsers: RestUserModel[];
  cachedUsers: { [filterKey: string]: RestUserModel[] };

  usersLoading: boolean;
  activePropertyUsers: RestUserModel[];
  propertyUsersLoading: boolean;
  permissionLevels: PermissionLevel[];
  generalRolesToPermissionMapping: GeneralRoleToPermissionLevel[];
  propertiesRoles: any;
  userToGeneralRole: UserToGeneralRoleMapping[];
  selectedUsers: UserWithGeneralRole[];
  generalRoles: GeneralRole[];
  usersWithGeneralRoles: UserWithGeneralRole[];
  usersWithGeneralRolesLoading: boolean;
  propertiesStructure: PropertyBasicInfo[];

  addNewUserInProgress: boolean;
  addedUser: RestUserModel;
  deleteGeneralRoleInProgress: boolean;
  selectedGeneralRole: GeneralRole;
  saveGeneralRoleInProgress: boolean;
  userToPropertyMapping: UserToPropertyMapping[];
  generalRoleRadioList: IRadioButtonOption<number>[];

  userUpdateInProgress: boolean;
  userUpdateStatus: UpdateStatus;

  userInviteInProgress: boolean;
  userInviteStatus: UpdateStatus;
  userInvited: RestUserModel | null;

  saveUserToGeneralRoleInProgress: boolean;
  saveUserToGeneralRoleStatus: UpdateStatus;

  assignUserIntoPropertyInProgress: boolean;
  assignUserIntoPropertyStatus: UpdateStatus;

  removingPropertyAssignedUserInProgress: boolean;
  removingPropertyAssignedUserStatus: UpdateStatus;
  removeUserInProgress: boolean;
  userListFilter: Partial<UserFilter>;
}

export const initialState: State = {
  portfolioActiveUsers: [],
  portfolioInactiveUsers: [],
  portfolioUsersLoading: false,

  addNewUserInProgress: false,
  addedUser: null,
  deleteGeneralRoleInProgress: false,
  generalRoles: [],
  generalRolesToPermissionMapping: [],
  permissionLevels: [],
  propertiesRoles: null,
  propertiesStructure: [],
  activePropertyUsers: [],
  propertyUsersLoading: false,
  saveGeneralRoleInProgress: false,

  selectedGeneralRole: null,
  selectedUsers: [],
  userToGeneralRole: [],
  userToPropertyMapping: [],
  activeUsers: [],
  activeUserOrVendorOptionsList: [],
  inactiveUsers: [],
  cachedUsers: {},
  usersLoading: false,
  usersWithGeneralRoles: [],
  usersWithGeneralRolesLoading: false,

  generalRoleRadioList: [],

  userUpdateInProgress: false,
  userUpdateStatus: null,

  userInviteInProgress: false,
  userInviteStatus: null,
  userInvited: null,

  saveUserToGeneralRoleInProgress: false,
  saveUserToGeneralRoleStatus: null,

  assignUserIntoPropertyInProgress: false,
  assignUserIntoPropertyStatus: null,

  removingPropertyAssignedUserInProgress: false,
  removingPropertyAssignedUserStatus: null,
  removeUserInProgress: false,
  userListFilter: {},
};

export const reducer = createReducer(
  initialState,

  on(AdministrationActions.loadAllUsers, (state, action) => ({
    ...state,
    usersLoading: true,
    usersWithGeneralRolesLoading: true,
  })),
  on(AdministrationActions.loadAllUsersSucceeded, (state, action) => {
    const users = [...action.activeUsers, ...action.inactiveUsers];

    return {
      ...state,
      activeUsers: action.activeUsers,
      activeUserOrVendorOptionsList: getUserOrVendorList(action.activeUsers),
      inactiveUsers: action.inactiveUsers,
      usersLoading: false,
      cachedUsers: {
        ...state.cachedUsers,
        [getFilterKey(action.filter)]: users,
      },
    };
  }),
  on(AdministrationActions.loadPortfolioUsers, (state, action) => ({
    ...state,
    portfolioUsersLoading: true,
  })),
  on(AdministrationActions.loadPortfolioUsersSucceeded, (state, action) => ({
    ...state,
    portfolioActiveUsers: action.activeUsers,
    portfolioInactiveUsers: action.inactiveUsers,
    portfolioUsersLoading: false,
  })),
  on(AdministrationActions.loadAllUsersFailed, (state, action) => ({ ...state, users: [], usersLoading: false })),
  on(AdministrationActions.clearAllUsers, (state, action) => ({ ...state, users: null })),

  on(AdministrationActions.loadPropertyUsers, (state, action) => ({
    ...state,
    activePropertyUsers: [],
    propertyUsersLoading: true,
  })),
  on(AdministrationActions.loadPropertyUsersSucceeded, (state, action) => ({
    ...state,
    activePropertyUsers: action.activePropertyUsers,
    propertyUsersLoading: false,
  })),
  on(AdministrationActions.loadPropertyUsersFailed, (state, action) => ({
    ...state,
    activePropertyUsers: [],
    propertyUsersLoading: false,
  })),

  on(AdministrationActions.loadAllPermissionLevelsSucceeded, (state, action) => ({
    ...state,
    permissionLevels: action.result,
  })),
  on(AdministrationActions.loadAllGeneralRolesToPermissionMappingSucceeded, (state, action) => ({
    ...state,
    generalRolesToPermissionMapping: action.result,
  })),
  on(AdministrationActions.loadAllPropertiesRolesSucceeded, (state, action) => ({
    ...state,
    propertiesRoles: action.result,
  })),
  on(AdministrationActions.clearAllPropertiesRoles, (state, action) => ({ ...state, propertiesRoles: undefined })),
  on(AdministrationActions.loadUserToRoleMappingSucceeded, (state, action) => ({
    ...state,
    userToGeneralRole: action.userToGeneralRole,
  })),
  on(AdministrationActions.setSelectedUsers, (state, action) => ({ ...state, selectedUsers: action.users })),
  on(AdministrationActions.loadAllGeneralRolesSucceeded, (state, action) => ({
    ...state,
    generalRoles: action.generalRoles,
  })),
  on(AdministrationActions.clearAllGeneralRoles, (state, action) => ({ ...state, generalRoles: undefined })),
  on(AdministrationActions.setAllUsersCombinedWithGeneralRoles, (state, action) => ({
    ...state,
    usersWithGeneralRoles: action.usersWithGeneralRoles,
    usersWithGeneralRolesLoading: false,
  })),
  on(AdministrationActions.clearPropertiesStructure, (state, action) => ({ ...state, propertiesStructure: undefined })),
  on(AdministrationActions.loadPropertiesStructureSucceeded, (state, action) => ({
    ...state,
    propertiesStructure: action.propertiesStructure,
  })),
  on(AdministrationActions.saveUsersRolesAndUnits, (state, action) => ({
    ...state,
    saveUserToGeneralRoleInProgress: true,
    saveUserToPropertyInProgress: true,
  })),
  on(setUserListFilter, (state, action) => ({
    ...state,
    userListFilter: action,
  })),

  /**
   * assign user into property
   */
  on(AdministrationActions.assignUserToProperty, (state, action) => ({
    ...state,
    assignUserIntoPropertyInProgress: true,
    assignUserIntoPropertyStatus: UpdateStatus.IN_PROGRESS,
  })),
  on(AdministrationActions.assignUserToPropertySuccess, (state, action) => ({
    ...state,
    assignUserIntoPropertyInProgress: false,
    assignUserIntoPropertyStatus: UpdateStatus.UPDATED,
  })),
  on(AdministrationActions.assignUserToPropertyError, (state, action) => ({
    ...state,
    assignUserIntoPropertyInProgress: false,
    assignUserIntoPropertyStatus: UpdateStatus.ERROR,
  })),

  /**
   * removing property assigned user
   */
  on(AdministrationActions.removingPropertyAssignedUser, (state, action) => ({
    ...state,
    removingPropertyAssignedUserInProgress: true,
    removingPropertyAssignedUserStatus: UpdateStatus.IN_PROGRESS,
  })),
  on(
    AdministrationActions.removingPropertyAssignedUserSuccess,
    AdministrationActions.removingPropertyAssignedUserError,
    (state, action) => ({
      ...state,
      removingPropertyAssignedUserInProgress: false,
      removingPropertyAssignedUserStatus: UpdateStatus.DELETED,
    })
  ),
  on(
    AdministrationActions.removingPropertyAssignedUserSuccess,
    AdministrationActions.removingPropertyAssignedUserError,
    (state, action) => ({
      ...state,
      removingPropertyAssignedUserInProgress: false,
      removingPropertyAssignedUserStatus: UpdateStatus.ERROR,
    })
  ),

  /**
   * save User To General Role
   */
  on(AdministrationActions.saveUserToGeneralRole, (state, action) => ({
    ...state,
    saveUserToGeneralRoleInProgress: true,
    saveUserToGeneralRoleStatus: UpdateStatus.IN_PROGRESS,
  })),

  on(AdministrationActions.saveUserToGeneralRoleSuccess, (state, action) => ({
    ...state,
    saveUserToGeneralRoleInProgress: false,
    saveUserToGeneralRoleStatus: UpdateStatus.UPDATED,
  })),

  on(AdministrationActions.saveUserToGeneralRoleError, (state, action) => ({
    ...state,
    saveUserToGeneralRoleInProgress: false,
    saveUserToGeneralRoleStatus: UpdateStatus.ERROR,
  })),

  on(AdministrationActions.clearUserToRoleMapping, (state, action) => ({
    ...state,
    saveUserToGeneralRoleInProgress: undefined,
  })),

  on(
    AdministrationActions.assignUserToPropertySuccess,
    AdministrationActions.assignUserToPropertyError,
    (state, action) => ({ ...state, saveUserToPropertyInProgress: false })
  ),
  on(AdministrationActions.startAddNewUser, (state, action) => ({
    ...state,
    addNewUserInProgress: true,
    addedUser: null,
  })),

  on(AdministrationActions.addingNewUserSucceeded, (state, action) => ({
    ...state,
    addNewUserInProgress: false,
    addedUser: action.user,
  })),

  on(AdministrationActions.addingNewUserFailed, (state, action) => ({
    ...state,
    addNewUserInProgress: false,
    addedUser: null,
  })),
  on(AdministrationActions.clearAddedUser, (state, action) => ({
    ...state,
    addedUser: null,
  })),

  on(AdministrationActions.startDeleteGeneralRole, (state, action) => ({
    ...state,
    deleteGeneralRoleInProgress: true,
  })),
  on(
    AdministrationActions.deleteGeneralRoleSucceeded,
    AdministrationActions.deleteGeneralRoleFailed,
    (state, action) => ({ ...state, deleteGeneralRoleInProgress: false })
  ),
  on(AdministrationActions.selectGeneralRoleToEdit, (state, action) => ({
    ...state,
    selectedGeneralRole: action.generalRole,
  })),
  on(AdministrationActions.clearSelectedGeneralRole, (state, action) => ({ ...state, selectedGeneralRole: undefined })),
  on(AdministrationActions.startSavingGeneralRole, (state, action) => ({ ...state, saveGeneralRoleInProgress: true })),
  on(
    AdministrationActions.savingGeneralRoleSucceeded,
    AdministrationActions.savingGeneralRoleFailed,
    (state, action) => ({ ...state, saveGeneralRoleInProgress: false })
  ),
  on(AdministrationActions.loadAllUserToProperyMappingsSucceeded, (state, action) => ({
    ...state,
    userToPropertyMapping: action.userToPropertyMapping,
  })),
  on(AdministrationActions.clearAllUserToPropertyMappings, (state, action) => ({
    ...state,
    userToPropertyMapping: undefined,
  })),
  on(AdministrationActions.setGeneralRoleRadioList, (state, action) => ({
    ...state,
    generalRoleRadioList: action.generalRoleRadioList,
  })),

  /**
   * invite User
   */
  on(AdministrationActions.inviteNewUser, AdministrationActions.inviteNewResidentUser, (state, action) => {
    return {
      ...state,
      userInviteInProgress: true,
      userInviteStatus: UpdateStatus.IN_PROGRESS,
      userInvited: null,
    };
  }),
  on(
    AdministrationActions.inviteNewUserSuccess,
    AdministrationActions.inviteNewResidentUserSuccess,
    (state, action) => ({
      ...state,
      userInviteInProgress: false,
      userInviteStatus: UpdateStatus.OK,
      userInvited: action.user,
    })
  ),
  on(AdministrationActions.inviteNewUserError, AdministrationActions.inviteNewResidentUserError, (state, action) => ({
    ...state,
    userInviteInProgress: false,
    userInviteStatus: UpdateStatus.ERROR,
    userInvited: null,
  })),

  /**
   * update User
   */
  on(AdministrationActions.updateUserData, AdministrationActions.updateResidentUserData, (state, action) => ({
    ...state,
    userUpdateInProgress: true,
    userUpdateStatus: UpdateStatus.IN_PROGRESS,
  })),
  on(
    AdministrationActions.updateUserDataSuccess,
    AdministrationActions.updateResidentUserDataSuccess,
    (state, action) => ({
      ...state,
      userUpdateInProgress: false,
      userUpdateStatus: UpdateStatus.UPDATED,
    })
  ),
  on(
    AdministrationActions.updateUserDataError,
    AdministrationActions.updateResidentUserDataError,
    AdministrationActions.savingGeneralRoleFailed,
    (state, action) => ({
      ...state,
      userUpdateInProgress: false,
      userUpdateStatus: UpdateStatus.ERROR,
    })
  ),
  on(AdministrationActions.removeUser, (state, action) => ({
    ...state,
    removeUserInProgress: true,
  })),
  on(AdministrationActions.removeUserFailed, AdministrationActions.removeUserSucceeded, (state, action) => ({
    ...state,
    removeUserInProgress: false,
  })),

  /**
   * clear User Preview Data
   **/
  on(AdministrationActions.clearUserPreviewData, (state, action) => ({
    ...state,
    userUpdateInProgress: false,
    userUpdateStatus: null,
    userInviteInProgress: false,
    userInviteStatus: null,
    userInvited: null,
    saveUserToGeneralRoleInProgress: false,
    saveUserToGeneralRoleStatus: null,
    assignUserIntoPropertyInProgress: false,
    assignUserIntoPropertyStatus: null,
    removingPropertyAssignedUserInProgress: false,
    removingPropertyAssignedUserStatus: null,
  }))
);
