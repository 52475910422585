<ng-container *ngIf="!isParentless && expandWorker.isExpanded">
  <div class="arrow"></div>
  <div class="arrow-half-circle"></div>
</ng-container>

<div class="header">
  <div class="header-main">
    <mat-icon [inline]="true" cdkDragHandle class="drag-icon">drag_indicator</mat-icon>
    <ng-container *ngIf="!this.isParentless">
      <div
        #areaName
        [attr.contenteditable]="editMode"
        class="header-control"
        (click)="enterEditMode()"
        (blur)="leaveEditMode(areaName.textContent)"
        (keydown)="nameChanged($event)"
        [attr.placeholder]="TemplateEditorDefaultValue">
        <ng-container *ngIf="area.title">
          <div [appFadeTextOnWidth]="358" [titleLength]="area.title?.length" [matTooltip]="area.title">
            {{ area.title }}
          </div>
        </ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="actionButtons"></ng-container>
      <ng-container [ngTemplateOutlet]="deleteButtons"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="!this.isParentless">
    <button
      aria-label="parts-group-button"
      type="button"
      class="icon-button"
      matTooltip="Replacement items list"
      matTooltipShowDelay="500"
      mat-icon-button
      (click)="onSaveArea()"
      (menuOpened)="openSparePartsPopup = true"
      (menuClosed)="openSparePartsPopup = false"
      [matMenuTriggerFor]="sparePartsMenu"
      [ngClass]="{ disabled: !this.initFinished, active: area.areaSurvey?.sparePartsGroups?.length > 0 }"
      [disabled]="!this.initFinished">
      <app-icon [svg]="EIcon.INVENTORY"></app-icon>
    </button>

    <button
      aria-label="instructions-button"
      type="button"
      class="icon-button"
      [matTooltip]="'Add instructions'"
      matTooltipShowDelay="500"
      mat-icon-button
      [ngClass]="{ active: showInstructions, disabled: !this.initFinished }"
      (click)="onSaveArea(); toggleInstructions()"
      [disabled]="!this.initFinished">
      <app-icon [svg]="EIcon.INFO"></app-icon>
    </button>
    <div
      *ngIf="template.scoringModel === 1"
      class="display-flex justify-content-end"
      [formGroup]="scoringParametersForm">
      <ng-container *ngIf="!isDropdownOpen">
        <div
          class="scoring-parameters-dropdown scoring-parameters-dropdown-placeholder full-capsule display-flex align-items-center"
          (mouseover)="isDropdownOpen = true">
          <div class="scoring-parameters-dropdown-text">Weight:</div>
          <div class="scoring-parameters-dropdown-text">
            {{
              scoringParametersForm.get('scoreWeight')?.value === 100
                ? 'Auto'
                : scoringParametersForm.get('scoreWeight')?.value + '%'
            }}
          </div>
          <mat-icon class="scoring-parameters-dropdown-arrow">arrow_drop_down</mat-icon>
        </div>
      </ng-container>
      <app-radio-button-dropdown
        *ngIf="isDropdownOpen"
        class="scoring-parameters-dropdown full-capsule"
        label="Weight:"
        [isLabelInside]="true"
        formControlName="scoreWeight"
        [items]="scoreWeightItems"
        [attrAppendTo]="'body'"
        [allowClear]="false"
        [allowSearch]="false"
        [autoSortList]="false"
        [isSmallFont]="true"
        [isSmallPadding]="true"
        [attrPlaceholder]="'weight'"
        [unsupportedValueLabel]="'Custom'"
        [dividerPosition]="0"
        [containerCss]="'display-flex flex-column'"
        [containerWidthCss]="'full-capsule'"
        [valueCss]="'small-font'"
        (blur)="isDropdownOpen = false"></app-radio-button-dropdown>
    </div>
  </ng-container>
  <div class="area-actions">
    <button
      aria-label="toggle-button"
      type="button"
      *ngIf="!isParentless"
      class="icon-button"
      mat-icon-button
      (click)="onSaveArea(); expandWorker.toggleExpand()"
      (dblclick)="onSaveArea(); expandWorker.toggleExpandAll()"
      [matTooltip]="'Hint: double click to ' + (expandWorker.isExpanded ? 'collapse' : 'expand') + ' all'"
      matTooltipShowDelay="500">
      <app-icon
        [iconHeight]="16"
        [iconWidth]="8"
        [iconColor]="EColorPalette.cGray5"
        [svg]="expandWorker.isExpanded ? EIcon.ANGLE_UP : EIcon.ANGLE_DOWN"></app-icon>
    </button>
  </div>
</div>
<div
  class="items-list-wrapper"
  [ngClass]="{ expandable: !isParentless }"
  *ngIf="expandWorker.isContentVisible"
  [class.display-none]="!expandWorker.isExpanded">
  <div class="items-list">
    <app-wysiwyg-editor
      *ngIf="showInstructions && !isParentless"
      [readonly]="!instructionsEditMode"
      (onFocus)="enterInstructionsEditMode()"
      (onBlur)="leaveInstructionsEditMode(instructions)"
      [(ngModel)]="instructions"
      [placeholder]="TemplateEditorInstructionsDefaultValue"></app-wysiwyg-editor>
    <div
      class="template-editor-inspection-area-dnd-content"
      cdkDropList
      [cdkDropListData]="area"
      (cdkDropListDropped)="dropDnd($event)"
      [cdkDropListAutoScrollStep]="scrollSpeed">
      <ng-container *ngFor="let item of pages; trackBy: trackBy">
        <div
          cdkDragLockAxis="y"
          cdkDrag
          [id]="'template-area-item-' + item.uuid"
          [cdkDragData]="item"
          (cdkDragStarted)="startDnd($event)"
          appDragCursor
          class="template-editor-inspection-area-dnd-content__item dnd-with-expandable"
          [cdkDragDisabled]="!item.pageType">
          <app-template-editor-item
            [isEditable]="isEditable"
            [grandParent]="template"
            [parent]="area"
            [item]="item"
            [emitItemSave]="emitItemSave"
            [isParentless]="isParentless"
            (itemChange)="itemChange($event)"
            (enterPressed)="enterPressedHandler($event)"
            (ratingGroupAdd)="addRatingGroup($event)"
            (clone)="cloneItem($event)"
            (questionAdd)="addQuestion($event)"
            (remove)="removeItem($event)"
            (focusedItemId)="setFocusedItem($event)">
            <ng-container actionButtons [ngTemplateOutlet]="actionButtons"></ng-container>
            <ng-container deleteButtons [ngTemplateOutlet]="deleteButtons"></ng-container>
            <ng-container dragHandle>
              <mat-icon *ngIf="item.pageType" [inline]="true" cdkDragHandle class="drag-icon">drag_indicator</mat-icon>
            </ng-container>
          </app-template-editor-item>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!isParentless">
      <button
        type="button"
        class="plus-button"
        [matTooltip]="'Add new item to ' + area.title"
        matTooltipShowDelay="500"
        matTooltipPosition="right"
        mat-button
        (click)="add()">
        <span class="display-flex align-items-center justify-content-center add-button">
          <app-icon
            [iconWidth]="20"
            [iconHeight]="20"
            [iconColor]="EColorPalette.cGray7"
            class="add-button__icon"
            [svg]="EIcon.PLUS"></app-icon>
          <span class="add-button__text">{{ area.title }} item</span>
        </span>
      </button>
    </div>
  </div>
</div>

<ng-template #actionButtons>
  <button
    aria-label="repeat-button"
    type="button"
    class="icon-button"
    (click)="onSaveArea()"
    [style.opacity]="repeatableMenuOpen ? '1' : undefined"
    [ngClass]="{
      active:
        template.additionalBathroomInspectionTemplateAreaIds?.includes(area.id) ||
        template.additionalHalfBathRoomInspectionTemplateAreaIds?.includes(area.id) ||
        template.additionalBedroomInspectionTemplateAreaIds?.includes(area.id) ||
        template.additionalAnyBathRoomInspectionTemplateAreaIds?.includes(area.id) ||
        template.postCompleteSurveyInspectionTemplateAreaIds?.includes(area.id)
    }"
    (menuOpened)="repeatableMenuOpen = true"
    (menuClosed)="repeatableMenuOpen = false"
    mat-icon-button
    [matTooltip]="'Repeat options for ' + area.title"
    matTooltipShowDelay="500"
    [matMenuTriggerFor]="contextMenu"
    [matMenuTriggerData]="{ area: area }">
    <app-icon [svg]="EIcon.REPEAT_LAPS"></app-icon>
  </button>

  <button
    type="button"
    aria-label="icon-button"
    class="icon-button"
    mat-icon-button
    [matTooltip]="'Clone ' + area.title"
    matTooltipShowDelay="500"
    (click)="cloneClick()">
    <app-icon [svg]="EIcon.CONTENT_COPY"></app-icon>
  </button>
</ng-template>

<ng-template #deleteButtons>
  <button
    type="button"
    class="icon-button"
    [matTooltip]="'Delete ' + area.title"
    mat-icon-button
    (click)="removeClick()">
    <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
  </button>
</ng-template>

<mat-menu #contextMenu="matMenu" class="menu-with-backdrop">
  <ng-template matMenuContent let-area="area">
    <div class="repeatable-context-menu">
      <label mat-menu-item>Repeat for:</label>
      <button type="button" (click)="toggleRepeatable($event, RepeatableArea.Bedroom)" mat-menu-item>
        Bedroom
        <app-icon
          *ngIf="template.additionalBedroomInspectionTemplateAreaIds?.includes(area.id)"
          [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button type="button" (click)="toggleRepeatable($event, RepeatableArea.AnyBathRoom)" mat-menu-item>
        All Bathrooms
        <app-icon
          *ngIf="template.additionalAnyBathRoomInspectionTemplateAreaIds?.includes(area.id)"
          [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button type="button" (click)="toggleRepeatable($event, RepeatableArea.Bathroom)" mat-menu-item>
        Full Bathrooms
        <app-icon
          *ngIf="template.additionalBathroomInspectionTemplateAreaIds?.includes(area.id)"
          [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button type="button" (click)="toggleRepeatable($event, RepeatableArea.HalfBathroom)" mat-menu-item>
        Half Bathrooms
        <app-icon
          *ngIf="template.additionalHalfBathRoomInspectionTemplateAreaIds?.includes(area.id)"
          [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
      <button type="button" (click)="toggleRepeatable($event, RepeatableArea.PostCompleteSurvey)" mat-menu-item>
        Post-complete Survey
        <app-icon
          *ngIf="template.postCompleteSurveyInspectionTemplateAreaIds?.includes(area.id)"
          [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
    </div>
  </ng-template>
</mat-menu>
<mat-menu xPosition="before" #sparePartsMenu="matMenu" class="menu-with-backdrop">
  <div class="replacement-context-menu">
    <label appStopPropagation mat-menu-item>Replacement items list:</label>
    <div class="replacement-context-menu__content">
      <button
        preventKeyboardEvents
        (click)="handleClickSparePartGroupItem($event, group.id)"
        type="button"
        mat-menu-item
        *ngFor="let group of sparePartsGroupsMerged$ | async">
        {{ group.hierarchyPath }}
        <app-icon *ngIf="group.isActive" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <span mat-menu-item appStopPropagation class="subtext text-size s12 text-color gray7">
      Hold Shift for multiselect
    </span>
  </div>
</mat-menu>
