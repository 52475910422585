export class EnvironmentConfig {
  type: EnvironmentType;
  ver: string;
  authBaseUrl: string;
  apiUrl: string;
  openApiUrl: string;
  notificationsUrl: string;
  notificationsAllUrl: string;
  disableForm: boolean;
  showAuthorizeLogs: boolean;
  showBuildTime: boolean;
  showEasterEggs: boolean;
  deepLinkScheme: string;
  azureAuthConfig: {
    b2cDirectory: string;
    stsBase: string;
    tenant: string;
    policyKey: string;
    policyKeySignInEmail: string;
    stsServer: string;
    stsServerSignInEmail: string;
    clientID: string;
    b2cScopes: string;
    redirectUrl: string;
    postLogoutRedirectUrl: string;
    forgotPasswordUrl: string;
  };
}

export enum EnvironmentType {
  Demo,
  Dev,
  Local,
  Prod,
  QA,
}
