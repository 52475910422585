import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

import { MeasureType } from '@shared/interfaces/measure-type';

@Pipe({
  name: 'measureType',
})
export class MeasureTypePipe implements PipeTransform {
  transform(measureType: MeasureType, amount: number, shortFormat = false): string {
    if (isNumber(amount) && measureType) {
      if (!shortFormat) {
        switch (measureType) {
          case 'day':
          case 'ticket':
          case 'inspection':
          case 'unit': {
            return amount > 0 && amount <= 1 ? measureType : `${measureType}s`;
          }
          default: {
            return measureType;
          }
        }
      } else {
        switch (measureType) {
          case 'day': {
            return 'd';
          }
          case 'unit': {
            return '';
          }
          default: {
            return measureType;
          }
        }
      }
    }
    return '';
  }
}
