import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSelectionListChange } from '@angular/material/list';

import { EColorPalette } from '@shared/enums/color-palette.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

interface IBottomSheetData<T> {
  sections: IRadioButtonOption<T>[][];
  items: IRadioButtonOption<T>[];
  selectedValue: T | T[];
  label: string;
  allowSearch: boolean;
  multiSelect: boolean;
  allowAddNew: boolean;
  addNewLabel: string;
  onSearch: (term: string) => IRadioButtonOption<T>[];
}

@Component({
  selector: 'app-radio-button-bottom-sheet',
  templateUrl: './radio-button-bottom-sheet.component.html',
  styleUrls: ['./radio-button-bottom-sheet.component.scss'],
})
export class RadioButtonBottomSheetComponent<T> {
  public searchTerm = '';
  public filteredItems: IRadioButtonOption<T>[] = [];
  public searchIconColor = EColorPalette.cBlack;
  public searchInputCollapsed = true;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: IBottomSheetData<T>,
    private bottomSheetRef: MatBottomSheetRef<RadioButtonBottomSheetComponent<T>>
  ) {
    this.updateFilteredItems();
  }

  private updateFilteredItems(): void {
    const items = [...this.data.items];
    this.filteredItems = this.sortItemsBySelection(items);
  }

  private sortItemsBySelection(items: IRadioButtonOption<T>[]): IRadioButtonOption<T>[] {
    return items.sort((a, b) => {
      const aSelected = this.isSelected(a.value);
      const bSelected = this.isSelected(b.value);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  }

  onSearch(term: string): void {
    const searchResults = this.data.onSearch(term);
    this.filteredItems = this.sortItemsBySelection(searchResults);
  }

  isSelected(value: T): boolean {
    return Array.isArray(this.data.selectedValue)
      ? this.data.selectedValue.includes(value)
      : this.data.selectedValue === value;
  }

  onSelectionChange(event: MatSelectionListChange): void {
    if (this.data.multiSelect) {
      const selectedValues = Array.isArray(this.data.selectedValue) ? [...this.data.selectedValue] : [];

      event.options.forEach(option => {
        const index = selectedValues.indexOf(option.value);
        if (option.selected && index === -1) {
          selectedValues.push(option.value);
        } else if (!option.selected && index > -1) {
          selectedValues.splice(index, 1);
        }
      });

      this.data.selectedValue = selectedValues;
    } else {
      this.close(event.options[0].value);
    }
  }

  onAddNew(): void {
    this.close('add_new');
  }

  close(result?: any): void {
    if (this.data.multiSelect) {
      this.bottomSheetRef.dismiss(this.data.selectedValue);
    } else {
      this.bottomSheetRef.dismiss(result);
    }
  }

  selectAll(): void {
    if (!this.data.multiSelect) return;

    const allValues = this.data.sections
      ? this.data.sections.flat().map(item => item.value)
      : this.data.items.map(item => item.value);

    this.data.selectedValue = allValues;
    this.updateFilteredItems();
  }

  clearAll(): void {
    if (!this.data.multiSelect) return;

    this.data.selectedValue = [];
    this.updateFilteredItems();
  }

  isAllSelected(): boolean {
    if (!this.data.multiSelect) return false;

    const allValues = this.data.sections
      ? this.data.sections.flat().map(item => item.value)
      : this.data.items.map(item => item.value);

    return (
      Array.isArray(this.data.selectedValue) &&
      allValues.length > 0 &&
      allValues.every(value => (this.data.selectedValue as T[]).includes(value))
    );
  }

  toggleSelectAll(): void {
    if (this.isAllSelected()) {
      this.clearAll();
    } else {
      this.selectAll();
    }
  }

  hasMultipleItems(): boolean {
    return this.data.sections ? this.data.sections.flat().length > 1 : this.data.items.length > 1;
  }
}
