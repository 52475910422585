<div *ngIf="item.pageType" class="arrow"></div>

<div class="header">
  <div class="header-main">
    <ng-content select="[dragHandle]"></ng-content>
    <div
      #itemName
      [attr.contenteditable]="editMode"
      class="header-control"
      (click)="enterEditMode()"
      (blur)="leaveEditMode(itemName.textContent)"
      (keydown)="nameChanged($event)"
      [attr.placeholder]="TemplateEditorDefaultValue">
      <ng-container *ngIf="item?.title">
        <div [appFadeTextOnWidth]="240" [titleLength]="item.title?.length" [matTooltip]="item.title">
          {{ item.title }}
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="item.pageType; else areaButtons">
      <button
        aria-label="copy-button"
        type="button"
        class="icon-button clone"
        [matTooltip]="'Clone ' + item.title"
        mat-icon-button
        (click)="onSaveArea(); cloneClick()">
        <app-icon [svg]="EIcon.CONTENT_COPY"></app-icon>
      </button>
      <button
        aria-label="dots-button"
        type="button"
        (click)="onSaveArea()"
        [style.opacity]="actionItemMenuOpen ? '1' : undefined"
        (menuOpened)="actionItemMenuOpen = true"
        (menuClosed)="actionItemMenuOpen = false"
        class="icon-button"
        mat-icon-button
        [matMenuTriggerFor]="actionItemMenu">
        <app-icon [svg]="EIcon.VERTICAL_DOTS"></app-icon>
      </button>
      <button
        aria-label="delete-button"
        type="button"
        class="icon-button remove"
        [matTooltip]="'Delete ' + item.title"
        matTooltipShowDelay="500"
        mat-icon-button
        (click)="onSaveArea(); removeClick()">
        <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
      </button>
    </ng-container>
  </div>
  <div class="header-actions">
    <button
      aria-label="photo-button"
      type="button"
      class="icon-button"
      [ngClass]="{ active: item.compulsoryItems.includes('photoRequired') }"
      [matTooltip]="'Require photo for this item'"
      matTooltipShowDelay="500"
      mat-icon-button
      (click)="onSaveArea(); togglePhotoRequired()">
      <app-icon [svg]="EIcon.PHOTO_CAMERA"></app-icon>
    </button>
    <button
      aria-label="comment-button"
      type="button"
      class="icon-button"
      [ngClass]="{ active: item.compulsoryItems.includes('commentRequired') }"
      [matTooltip]="'Require comment for this item'"
      matTooltipShowDelay="500"
      mat-icon-button
      (click)="onSaveArea(); toggleCommentRequired()">
      <app-icon [svg]="EIcon.COMMENT_CIRCLE"></app-icon>
    </button>
    <button
      aria-label="rating-button"
      type="button"
      class="icon-button"
      (click)="onSaveArea()"
      (menuOpened)="openRatingPopup = true"
      (menuClosed)="openRatingPopup = false"
      [matMenuTriggerFor]="ratingMenu"
      [matTooltip]="'Add rating group to item'"
      mat-icon-button
      [ngClass]="{ active: ratingGroup }">
      <app-icon [svg]="EIcon.STAR_HAPPY"></app-icon>
    </button>
    <button
      aria-label="comment-button"
      type="button"
      class="icon-button"
      [matTooltip]="questions?.length >= 35 ? 'Max 35 questions per item' : 'Add question to item'"
      matTooltipShowDelay="500"
      mat-icon-button
      (click)="onSaveArea(); addQuestion()"
      [disabled]="questions?.length >= 35">
      <app-icon [svg]="EIcon.COMMENT_QUESTION"></app-icon>
    </button>

    <button
      aria-label="spare-parts-button"
      type="button"
      class="icon-button"
      matTooltip="Replacement items list"
      (click)="onSaveArea()"
      (menuOpened)="openSparePartsPopup = true"
      (menuClosed)="openSparePartsPopup = false"
      [matMenuTriggerFor]="sparePartsMenu"
      matTooltipShowDelay="500"
      [ngClass]="{ active: isSparePartsGroupButtonActive }"
      mat-icon-button>
      <app-icon [svg]="EIcon.INVENTORY"></app-icon>
    </button>

    <button
      aria-label="instructions-button"
      type="button"
      class="icon-button"
      [matTooltip]="'Add instructions'"
      matTooltipShowDelay="500"
      mat-icon-button
      [ngClass]="{ active: isShowInstructions }"
      (click)="onSaveArea(); toggleInstructions()">
      <app-icon [svg]="EIcon.INFO"></app-icon>
    </button>
    <div
      *ngIf="grandParent.scoringModel === 1"
      class="w-100 display-flex justify-content-end grid-gap-2"
      [formGroup]="scoringParametersForm">
      <ng-container *ngIf="!isDropdownOpen">
        <div
          class="scoring-parameters-dropdown scoring-parameters-dropdown-placeholder full-capsule display-flex align-items-center"
          (mouseover)="isDropdownOpen = true">
          <div class="scoring-parameters-dropdown-text">Weight:</div>
          <div class="scoring-parameters-dropdown-text">
            {{
              scoringParametersForm.get('scoreWeight')?.value === 100
                ? 'Auto'
                : scoringParametersForm.get('scoreWeight')?.value + '%'
            }}
          </div>
          <mat-icon class="scoring-parameters-dropdown-arrow">arrow_drop_down</mat-icon>
        </div>
      </ng-container>
      <app-radio-button-dropdown
        *ngIf="isDropdownOpen"
        class="scoring-parameters-dropdown full-capsule"
        label="Weight:"
        [isLabelInside]="true"
        formControlName="scoreWeight"
        [items]="scoreWeightItems"
        [attrAppendTo]="'body'"
        [allowClear]="false"
        [allowSearch]="false"
        [autoSortList]="false"
        [isSmallFont]="true"
        [isSmallPadding]="true"
        [attrPlaceholder]="'weight'"
        [unsupportedValueLabel]="'Custom'"
        [dividerPosition]="0"
        [containerCss]="'display-flex flex-column'"
        [containerWidthCss]="'full-capsule'"
        [valueCss]="'small-font'"></app-radio-button-dropdown>
    </div>
    <button
      aria-label="toggle-button"
      type="button"
      class="icon-button"
      mat-icon-button
      (click)="onSaveArea(); expandWorker.toggleExpand()"
      (dblclick)="onSaveArea(); expandWorker.toggleExpandAll()"
      [matTooltip]="'Hint: double click to ' + (expandWorker.isExpanded ? 'collapse' : 'expand') + ' all'"
      matTooltipShowDelay="500">
      <app-icon
        [iconHeight]="16"
        [iconWidth]="8"
        [iconColor]="EColorPalette.cGray5"
        [svg]="expandWorker.isExpanded ? EIcon.ANGLE_UP : EIcon.ANGLE_DOWN"></app-icon>
    </button>
  </div>
</div>
<div
  class="items-list-wrapper expandable"
  *ngIf="expandWorker.isContentVisible"
  [class.display-none]="!expandWorker.isExpanded">
  <div class="items-list">
    <app-wysiwyg-editor
      *ngIf="isShowInstructions"
      [readonly]="!instructionsEditMode"
      (onFocus)="enterInstructionsEditMode()"
      (onBlur)="leaveInstructionsEditMode(item.instructions)"
      [(ngModel)]="item.instructions"
      [placeholder]="TemplateEditorInstructionsDefaultValue"></app-wysiwyg-editor>

    <div
      *ngIf="isTAndMExist && (inspectionProPackageEnabled$ | async)"
      class="time-and-material-panel"
      [formGroup]="timeAndMaterialForm">
      <app-masked-number-input
        [labelInside]="true"
        [label]="'Labor'"
        formControlName="laborCost"
        prefix="$"
        postfix="/hr"></app-masked-number-input>
      <app-masked-number-input
        [labelInside]="true"
        [label]="'Time'"
        formControlName="timeCost"
        postfix="min"></app-masked-number-input>
      <app-masked-number-input
        [labelInside]="true"
        [label]="'Materials'"
        formControlName="materials"
        prefix="$"></app-masked-number-input>
    </div>

    <app-template-editor-rating-group
      *ngIf="ratingGroup"
      [ratingGroup]="ratingGroup"
      (ratingGroupChange)="ratingGroupChange($event)"
      [parent]="item"
      [greatGrandParent]="grandParent"></app-template-editor-rating-group>

    <app-template-editor-question-item
      *ngFor="let question of questions; index as i; trackBy: trackBy"
      [questionItem]="question"
      [index]="i"
      (enterPressed)="addQuestion($event)"
      (questionItemChange)="questionChange($event)"
      (remove)="questionRemove($event)"></app-template-editor-question-item>
  </div>
</div>

<ng-template #areaButtons>
  <ng-content select="[actionButtons]"></ng-content>
  <button
    aria-label="dots-button"
    type="button"
    [style.opacity]="actionItemMenuOpen ? '1' : undefined"
    (menuOpened)="actionItemMenuOpen = true"
    (menuClosed)="actionItemMenuOpen = false"
    class="icon-button"
    mat-icon-button
    [matMenuTriggerFor]="actionItemMenu">
    <app-icon [svg]="EIcon.VERTICAL_DOTS"></app-icon>
  </button>
  <ng-content select="[deleteButtons]"></ng-content>
</ng-template>

<mat-menu #ratingMenu="matMenu" class="menu-with-backdrop rating-groups">
  <label class="rating-groups__group rating-groups__group--title" mat-menu-item>Rating groups:</label>
  <button
    type="button"
    class="rating-groups__group"
    *ngFor="let group of ratingGroups$ | async"
    mat-menu-item
    (click)="addRatingGroup(group)">
    {{ group.name }}
  </button>
  <mat-divider></mat-divider>
  <button
    type="button"
    [disabled]="!ratingGroup"
    class="rating-groups__group"
    mat-menu-item
    (click)="removeRatingGroup()">
    Remove rating group
  </button>
</mat-menu>
<mat-menu #actionItemMenu="matMenu" class="menu-with-backdrop rating-groups">
  <button type="button" class="rating-groups__group" mat-menu-item (click)="toggleTimeAndMaterial()">
    Time & Materials
  </button>
</mat-menu>

<mat-menu xPosition="before" #sparePartsMenu="matMenu" class="menu-with-backdrop">
  <div class="replacement-context-menu">
    <label appStopPropagation mat-menu-item>Replacement items list:</label>
    <div class="replacement-context-menu__content" *ngIf="!isParentless">
      <button preventKeyboardEvents (click)="handleClickUseSameForParent($event)" type="button" mat-menu-item>
        <span>Use same as for {{ parent.title }}</span>
        <app-icon *ngIf="isUseSameForParentSparePartsButtonActive" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button preventKeyboardEvents type="button" mat-menu-item>
        Custom replacement list
        <app-icon *ngIf="isCustomListSparePartsButtonActive" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button preventKeyboardEvents (click)="handleClickNotUseSpareParts($event)" type="button" mat-menu-item>
        Not available
        <app-icon *ngIf="isDoNotUseSparePartsButtonActive" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="replacement-context-menu__content">
      <button
        type="button"
        mat-menu-item
        preventKeyboardEvents
        (click)="handleClickSparePart($event, group.id)"
        *ngFor="let group of sparePartsGroupsPageMerged$ | async">
        <span>
          {{ group.hierarchyPath }}
        </span>
        <app-icon *ngIf="group.isActive" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
    </div>
    <mat-divider></mat-divider>

    <label appStopPropagation mat-menu-item>Available if:</label>
    <div class="replacement-context-menu__content">
      <button preventKeyboardEvents type="button" mat-menu-item (click)="handleToogleAllSpareParts($event, true)">
        <span>Any condition</span>
        <app-icon *ngIf="isAllConditionsSelected" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button
        *ngIf="getAlivableConditions && !isAllConditionsSelected"
        preventKeyboardEvents
        type="button"
        mat-menu-item>
        Selected conditions: {{ getAlivableConditions }}

        <app-icon [svg]="EIcon.CHECK_YES"></app-icon>
      </button>

      <button preventKeyboardEvents type="button" mat-menu-item (click)="handleToogleAllSpareParts($event, false)">
        Never
        <app-icon *ngIf="!getAlivableConditions" [svg]="EIcon.CHECK_YES"></app-icon>
      </button>
    </div>
    <mat-divider></mat-divider>

    <span mat-menu-item appStopPropagation class="subtext text-size s12 text-color gray7">
      All/Selected only one can be checked at a time
    </span>
  </div>
</mat-menu>
