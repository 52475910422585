<div class="search-input-container" [ngClass]="containerCss" (click)="expand($event)" [class.collapsed]="collapsed">
  <input
    #input
    class="search-input"
    [class.search-input--without-clear]="!control.value"
    [formControl]="control"
    [ngClass]="inputCss"
    type="text"
    [placeholder]="attrPlaceholder" />

  <div class="search-input__icons">
    <app-icon
      class="search-input__search-icon"
      [iconColor]="iconColor"
      [svg]="EIcon.ICON_SEARCH"
      [iconWidth]="26"
      [iconHeight]="26"></app-icon>
  </div>
</div>
