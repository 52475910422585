import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PuSubscribable } from '@app/utils/pu-subscribe';

@Injectable({
  providedIn: 'root',
})
export class GenerateThumbnailService extends PuSubscribable {
  generateThumbnail(file: File): Observable<{ thumbnail: Blob; duration?: number }> {
    const isVideo = file.type.startsWith('video/');
    return isVideo ? this.generateVideoThumbnail(file) : this.compressImage(file);
  }

  private generateVideoThumbnail(file: File, captureTime = 1): Observable<{ thumbnail: Blob; duration: number }> {
    return new Observable(observer => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const url = URL.createObjectURL(file);

      video.src = url;
      video.crossOrigin = 'anonymous';
      video.muted = true;
      video.playsInline = true;
      video.autoplay = false;

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      let seeked = false;

      const failTimeout = setTimeout(() => {
        URL.revokeObjectURL(url);
        observer.error(new Error('Thumbnail generation timed out'));
      }, 7000);

      video.onloadedmetadata = async () => {
        if (isSafari) {
          try {
            await video.play();
            video.pause();
          } catch (err) {
            console.warn('Safari autoplay issue:', err);
          }
        }
        video.currentTime = captureTime;
      };

      video[isSafari ? 'ontimeupdate' : 'onseeked'] = () => {
        if (seeked) return;
        seeked = true;

        clearTimeout(failTimeout);
        video.pause();

        setTimeout(() => {
          canvas.width = video.videoWidth || 1280;
          canvas.height = video.videoHeight || 720;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            blob => {
              URL.revokeObjectURL(url);
              if (blob) {
                observer.next({ thumbnail: blob, duration: video.duration });
                observer.complete();
              } else {
                observer.error(new Error('Failed to generate thumbnail'));
              }
            },
            'image/jpeg',
            0.5
          );
        }, 100);
      };

      video.onerror = () => {
        clearTimeout(failTimeout);
        URL.revokeObjectURL(url);
        observer.error(new Error('Error loading video'));
      };
    });
  }

  private compressImage(file: File): Observable<{ thumbnail: Blob }> {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    return new Observable<{ thumbnail: Blob }>(observer => {
      reader.onload = e => {
        img.src = e.target?.result as string;
      };

      reader.onerror = () => {
        observer.error('Error reading image file');
      };

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        this.compressCanvasToBlob(canvas, 0.5)
          .subscribe({
            next: blob => observer.next({ thumbnail: blob }),
            error: err => observer.error(err),
            complete: () => observer.complete(),
          })
          .untilDestroyed(this);
      };

      reader.readAsDataURL(file);
    });
  }

  private compressCanvasToBlob(canvas: HTMLCanvasElement, quality: number): Observable<Blob> {
    return new Observable<Blob>(observer => {
      canvas.toBlob(
        blob => {
          if (blob) {
            observer.next(blob);
            observer.complete();
          } else {
            observer.error('Failed to compress canvas');
          }
        },
        'image/jpeg',
        quality
      );
    });
  }
}
