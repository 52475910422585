import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PendingService {
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _loadingMap: Map<string, boolean> = new Map<string, boolean>();
  private _loadingMapSubject = new BehaviorSubject<Map<string, boolean>>(this._loadingMap);

  pending$: Observable<boolean> = this._loading$.pipe(delay(0));

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('Url must be provided here');
    }

    if (url.endsWith('/api/notification')) {
      return;
    }

    if (loading) {
      this._loadingMap.set(url, loading);
      this._loading$.next(true);
    } else if (!loading && this._loadingMap.has(url)) {
      this._loadingMap.delete(url);
      this.updateLoadingState();
    }
  }

  clearPending(url: string): void {
    if (this._loadingMap.has(url)) {
      this._loadingMap.delete(url);
      this.updateLoadingState();
    }
  }

  private updateLoadingState(): void {
    this._loading$.next(this._loadingMap.size > 0);
  }

  isPendingUrls(urls: string[]): boolean {
    return urls.some(url => this._loadingMap.has(url));
  }

  isPendingExcludingUrls(excludedUrls: string[]): boolean {
    return Array.from(this._loadingMap.keys()).some(url => !excludedUrls.includes(url));
  }

  isPendingExcludingUrls$(excludedUrls: string[]) {
    return this._loadingMapSubject
      .asObservable()
      .pipe(map(loadingMap => Array.from(loadingMap.keys()).some(url => !excludedUrls.includes(url))));
  }
}
