import { Injectable } from '@angular/core';
import { EMPTY, Observable, fromEvent } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { EventThrottleService } from './event-throttle.service';

enum THROTTLES {
  SCROLL = 50,
  RESIZE = 300,
  MOUSEMOVE = 10,
}

@Injectable({ providedIn: 'root' })
export class NativeEventService {
  scroll$: Observable<Document> = EMPTY;
  resize$: Observable<Window> = EMPTY;

  constructor(private eventThrottleService: EventThrottleService) {
    if (!document || !window) {
      console.warn('NativeEventService method was called on the server. This operation is not supported in SSR');
      return;
    }
    this.scroll$ = this.initScroll;
    this.resize$ = this.initResize;
  }

  private get initScroll(): Observable<Document> {
    return this.eventThrottleService.throttleEvent(this.documentScroll$, THROTTLES.SCROLL).pipe(shareReplay(1));
  }

  private get initResize(): Observable<Window> {
    return this.eventThrottleService.throttleEvent(this.windowResize$, THROTTLES.RESIZE).pipe(shareReplay(1));
  }

  private get documentScroll$(): Observable<Document> {
    return fromEvent(document, 'scroll', { passive: true }).pipe(
      map(e => e.target as Document),
      startWith(document)
    ) as Observable<Document>;
  }

  private get windowResize$(): Observable<Window> {
    return fromEvent(window, 'resize', { passive: true }).pipe(
      map(e => e.target as Window),
      startWith(window)
    ) as Observable<Window>;
  }
}
