import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { sortBy } from 'lodash';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  combineLatest,
  finalize,
  forkJoin,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RestapiService, RestapiServiceWithoutTimezone } from '@app/services/restapi.service';
import { SurveysService } from '@app/services/surveys.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import {
  TemplateEditorDialogComponent,
  TemplateEditorDialogData,
  TemplateEditorDialogResult,
} from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-dialog.component';
import {
  AreaModel,
  AreaPageElementRatingGroupModel,
  AreaSurveyModel,
  RestCustomInspectionModel,
  RestTemplateEditorRatingGroup,
  TemplateEditorRatingGroup,
  TemplateEditorRatingGroupItem,
  TemplateEditorSparePartsGroup,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { ConfirmationModalData } from '@shared/interfaces/modal-data';
import { RestTemplateAreaModel, RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { ModalsService } from '@ui-components/modals/modals.service';

import { getInitialAreaData } from '../models/share-methods.function';

class UploadResult {
  errors: {
    errorType: number;
    message: string;
  }[];
  state: 'Success' | 'Error';
}

// TODO remove ALL direct API calls from this service. Put it in ApiTemplateService

@Injectable()
export class TemplateEditorDialogService extends PuSubscribable {
  private readonly route = 'inspections/';
  private _templatesLoading = new BehaviorSubject<boolean>(false);
  templatesLoading$ = this._templatesLoading.asObservable();
  private _templateDeleting = new BehaviorSubject<boolean>(false);
  templateDeleting$ = this._templateDeleting.asObservable();

  private _templateCreating = new BehaviorSubject<boolean>(false);
  templateCreating$ = this._templateCreating.asObservable();

  private _questionIdFocused = new BehaviorSubject<string>(null);
  questionIdFocused$ = this._questionIdFocused.asObservable();
  private _ratingGroups: ReplaySubject<TemplateEditorRatingGroup[]>;

  private _sparePartsGroups: ReplaySubject<TemplateEditorSparePartsGroup[]>;

  private _itemFocused = new BehaviorSubject<AreaModel>(null);
  itemFocused$ = this._itemFocused.asObservable();

  requestTemplateCreating = new EventEmitter<void>();

  constructor(
    private simpleService: RestapiServiceWithoutTimezone,
    private service: RestapiService,
    private modalsService: ModalsService,
    private surveysService: SurveysService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {
    super();
  }

  focusQuestion(id: string) {
    this._questionIdFocused.next(id);
  }

  focusItem(item: AreaModel) {
    if (item) {
      this._itemFocused.next(item);
    }
  }

  leaveFocus(id: number) {
    setTimeout(() => {
      if (this._itemFocused.value?.id == id) {
        this._itemFocused.next(null);
      }
    }, 500);
  }

  openTemplateEditorModal(data: TemplateEditorDialogData) {
    return this.dialog.open<TemplateEditorDialogComponent, TemplateEditorDialogData, TemplateEditorDialogResult>(
      TemplateEditorDialogComponent,
      {
        data: data,
        width: '744px',
        maxHeight: '100vh',
        panelClass: 'template-editor-modal-container',
        disableClose: true,
        autoFocus: 'dialog',
      }
    );
  }

  getTemplates(preserveInspectionId?: number) {
    this._templatesLoading.next(true);
    return this.service
      .getData<RestTemplateModel[]>(
        this.route + 'template/list?filterUnavailableTemplates=false&doNotReturnContent=true'
      )
      .pipe(
        map(templates => {
          if (!templates?.length) {
            return [];
          }
          const filteredTemplates = templates.filter(
            template =>
              (template.isAvailable && !template.isDeleted && !template.isScheduledForDeletion) ||
              template.id === preserveInspectionId
          );
          return filteredTemplates.sort((a, b) => {
            if (a.name === b.name) return 0;
            return a.name > b.name ? 1 : -1;
          });
        }),
        finalize(() => {
          this._templatesLoading.next(false);
        }),
        catchError(err => this.handleError(err, SnackbarErrorMessage.LoadingTemplatesList))
      );
  }

  private getRatingGroupsInternal() {
    this.service
      .getData<RestTemplateEditorRatingGroup[]>(this.route + 'ratingGroups')
      .pipe(
        map(ratingGroups => {
          return ratingGroups.map(group => {
            const { itemsJson, ...rest } = group;
            return { ...rest, items: JSON.parse(itemsJson) as TemplateEditorRatingGroupItem[] };
          });
        })
      )
      .subscribe({
        next: ratingGroups => this._ratingGroups.next(ratingGroups),
        error: error => this._ratingGroups.error(error),
      })
      .untilDestroyed(this);
  }

  private prepareSparePartsGroupsViewInternal(
    sourceData: TemplateEditorSparePartsGroup[]
  ): TemplateEditorSparePartsGroup[] {
    sourceData = sourceData.filter(x => x.propertyOrPortfolioId == null);
    const hashByParentId = sourceData.reduce(function (map, obj) {
      let list: TemplateEditorSparePartsGroup[];
      const key = obj.parentGroupId ?? -1;

      if ((list = map[key]) == null) {
        map[key] = list = [];
      }

      list.push(obj);

      return map;
    }, {});

    const dataToProcess = hashByParentId[-1] as TemplateEditorSparePartsGroup[];

    for (let counter = 0; counter < dataToProcess.length; counter++) {
      //adding parent to hierarchy name
      const parent = dataToProcess[counter];
      const children = hashByParentId[parent.id] as TemplateEditorSparePartsGroup[];

      if (children != null) {
        children.forEach(child => {
          child.hierarchyPath = (child.hierarchyPath != null ? ' > ' : '') + parent.title + ' > ' + child.title;

          dataToProcess.push(child);
        });
      }
    }

    sourceData
      .filter(item => item.hierarchyPath == null)
      .forEach(item => {
        item.hierarchyPath = item.title;
      });

    return sortBy(sourceData, [group => group.hierarchyPath?.toLowerCase()]);
  }

  private getSparePartsGroupsInternal() {
    this.service
      .getData<TemplateEditorSparePartsGroup[]>('SparePartGroup')
      .subscribe({
        next: sparePartGroups => this._sparePartsGroups.next(this.prepareSparePartsGroupsViewInternal(sparePartGroups)),
        error: error => this._sparePartsGroups.error(error),
      })
      .untilDestroyed(this);
  }

  getRatingGroups() {
    if (!this._ratingGroups) {
      this._ratingGroups = new ReplaySubject<TemplateEditorRatingGroup[]>(1);
      this.getRatingGroupsInternal();
    }
    return this._ratingGroups.asObservable();
  }

  getSparePartsGroups() {
    if (!this._sparePartsGroups) {
      this._sparePartsGroups = new ReplaySubject<TemplateEditorSparePartsGroup[]>(1);
      this.getSparePartsGroupsInternal();
    }
    return this._sparePartsGroups.asObservable();
  }

  updateRatingGroup(ratingGroup: AreaPageElementRatingGroupModel) {
    if (!ratingGroup.ratingGroupId) {
      return EMPTY;
    }
    const restRatingGroup: RestTemplateEditorRatingGroup = {
      id: ratingGroup.ratingGroupId,
      name: ratingGroup.items.map(item => item.item.text).join('/'),
      itemsJson: JSON.stringify(ratingGroup.items),
    };

    return this.service.post<number>(this.route + 'ratingGroups', restRatingGroup).pipe(
      tap(() => this.getRatingGroupsInternal()),
      catchError(err => this.handleError(err, SnackbarErrorMessage.WhileUpdatingRatingGroup))
    );
  }

  getTemplate(templateId: number) {
    return this.service.getData<RestTemplateModel>(this.route + 'template/' + templateId);
  }

  addArea(area: RestTemplateAreaModel, reloadAction?: string) {
    const serverRequest = { ...area, id: undefined };
    return this.surveysService.add(serverRequest).pipe(
      retry({ count: 1, delay: 300 }),
      catchError(err => this.handleTemplateError(err, reloadAction)),
      switchMap(id => this.surveysService.get(id).pipe(map(area => [id, area] as const)))
    );
  }

  removeArea(id: number) {
    return this.surveysService.delete(id);
  }

  removeTemplate(template: RestTemplateModel) {
    this._templateDeleting.next(true);
    return this.service.post<number>('inspections/template/delete', template.id).pipe(
      catchError(err => {
        if (err?.error?.['ErrorCode'] === 'inspection_template_delete_unavailable') {
          this.snackbarService.error(SnackbarErrorMessage.RemovingTemplateInUse);
        } else {
          this.snackbarService.error(SnackbarErrorMessage.RemovingTemplate);
        }

        return throwError(() => err);
      }),
      tap(() => {
        this.snackbarService.success(`${template.name} deleted`);
      }),
      finalize(() => {
        this._templateDeleting.next(false);
      })
    );
  }

  updateArea(area: AreaModel, reloadAction?: string) {
    const { areaSurvey, ...rest } = area;
    // filter empty elements
    const areaSurveyToSave: AreaSurveyModel = {
      ...areaSurvey,
      pages: [
        ...areaSurvey?.pages.map(page => {
          return { ...page, elements: [...(page?.elements.filter(element => element.title !== '') ?? [])] };
        }),
      ],
    };
    const updatedArea: RestTemplateAreaModel = { ...rest, areaSurveyJson: JSON.stringify(areaSurveyToSave) };
    return this.surveysService
      .update(updatedArea)
      .pipe(catchError(err => this.handleError(err, SnackbarErrorMessage.UpdatingTemplate)));
  }

  updateAreasPosition(areas: AreaModel[], reloadAction: string) {
    const serverCalls = areas
      .filter((e, i) => {
        const shouldBeUpdated = e.position != i || e.id < 0;
        e.position = i;
        return shouldBeUpdated;
      })
      .map(area => (area.id > 0 ? this.updateArea(area) : this.addArea(area, reloadAction)));
    return forkJoin(serverCalls);
  }

  openRemovalTemplateModal(inspectionTemplate: RestTemplateModel, data: ConfirmationModalData = {}) {
    return this.modalsService.openConfirmationModal(
      {
        ...{
          title: `Delete ${inspectionTemplate.name} template?`,
          titleCss: 'fade-truncate',
          content: `This template will be deleted forever and ever. Are you feeling this confident? 💪`,
          cancelLabel: 'Yikes, no!',
          confirmLabel: 'Yes, delete it',
          confirmColor: 'warn',
        },
        ...data,
      },
      360
    );
  }

  // CI1 methods

  addCustomInspectionTemplateWithPredefinedItems(data: RestCustomInspectionModel) {
    this._templateCreating.next(true);
    return combineLatest([
      this.service.post<number>(this.route + 'template/addCustomInspection', data),
      this.getRatingGroups(),
    ]).pipe(
      switchMap(([id, ratingGroups]) => {
        const area: RestTemplateAreaModel = {
          title: `Beautiful Area`,
          inspectionTemplateId: id,
          position: 0,
          areaSurveyJson: JSON.stringify(
            getInitialAreaData(`Beautiful Area`, false, 'Incredible Item', true, ratingGroups[0])
          ),
        } as RestTemplateAreaModel;

        return this.addArea(area);
      }),
      map(([_areaId, area]) => area.inspectionTemplateId),
      finalize(() => this._templateCreating.next(false))
    );
  }

  editCustomInspectionTemplate(inspectionId: number, data: RestCustomInspectionModel, reloadAction: string) {
    return this.service
      .post<RestCustomInspectionModel>(this.route + 'template/editCustomInspection/' + inspectionId, data)
      .pipe(
        retry({ count: 1, delay: 300 }),
        catchError(err => this.handleTemplateError(err, reloadAction))
      );
  }

  editTemplate(data: RestTemplateModel) {
    return this.service
      .post<RestTemplateModel>(this.route + 'template/edit', data)
      .pipe(catchError(err => this.handleError(err, SnackbarErrorMessage.UpdatingTemplate)));
  }

  downloadTemplateFile(id: number) {
    return this.simpleService.download(this.route + `template/downloadInspectionTemplateRawReport/${id}`);
  }

  uploadTemplateFile(id: number, data: File) {
    return this.simpleService.upload<UploadResult>(this.route + `template/uploadInspectionTemplateRaw/${id}`, data);
  }

  private handleTemplateError(err: HttpErrorResponse, reloadAction: string): Observable<never> {
    err?.error?.message && reloadAction
      ? this.snackbarService.error(
          `Your changes did not save. <a class="text-color white underline" data-toast-action="${reloadAction}">Reload</a>`
        )
      : this.snackbarService.error(SnackbarErrorMessage.UpdatingTemplate);

    return throwError(() => err);
  }

  private handleError(err: HttpErrorResponse, type: SnackbarErrorMessage): Observable<never> {
    this.snackbarService.error(type);
    return throwError(() => err);
  }
}
