<div
  class="radio-button-dropdown-container"
  [ngClass]="[containerCss, attrDisable ? 'disabled' : '']"
  [class.has-fade]="hasFade">
  <ng-container *ngIf="!isLabelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
  <ng-container *ngIf="!displaySkeleton">
    <div
      class="radio-button-dropdown-content display-flex align-items-center w-100"
      (click)="handleDropdownClick($event)"
      [ngClass]="[
        ngSelectCss,
        markAsInvalid || (errors && showValidationErrorOrSubmitted) ? 'invalid' : '',
        attrDisable ? 'disabled' : '',
        isLabelInside ? 'inside' : '',
        !activeOnFocus && hasValue && !(attrDisable || _listLoading) && highlightWhenActive ? 'active' : '',
        activeOnFocus && _focused && highlightWhenActive ? 'active' : ''
      ]">
      <ng-container *ngIf="showSwitch" [ngTemplateOutlet]="switchTemplate"></ng-container>
      <ng-container *ngIf="isLabelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
      <mat-spinner *ngIf="_listLoading" class="ml-4" color="accent" diameter="20"></mat-spinner>
      <ng-select
        #ngSelect
        class="select-container"
        [class.select-container--small-font]="isSmallFont"
        [class.small-font-padding]="isSmallPadding"
        [formControl]="control"
        [ngClass]="containerWidthCss"
        [style.padding-left]="paddingLeft$ | async"
        [style.margin-left]="isLabelInside ? '-' + labelWidthN + 'px' : 'initial'"
        [matTooltip]="displayValue | escapeHtml"
        [matTooltipDisabled]="!showValueTooltip || !hasValue"
        [closeOnSelect]="!multiSelect"
        [multiple]="multiSelect"
        [clearOnBackspace]="false"
        [searchable]="allowSearch"
        [clearable]="allowClear"
        [placeholder]="attrPlaceholder"
        [bindLabel]="'label'"
        [bindValue]="'value'"
        [compareWith]="compareFn"
        [readonly]="attrDisable || _listLoading"
        (change)="onChanged($event)"
        (clear)="onClear()"
        [appendTo]="attrAppendTo"
        [searchFn]="searchFunction.bind(this)"
        (focusin)="_focused = true"
        (focusout)="_focused = false"
        (open)="onOpen()"
        (close)="onClose()">
        <ng-container *ngIf="allowSelectAll">
          <ng-template ng-header-tmp>
            <div>
              <a href="javascript:void(0)" (click)="onSelectAll()">Select All</a>
              &nbsp;&nbsp;
              <a href="javascript:void(0)" (click)="onClearAll()">Clear All</a>
            </div>
          </ng-template>
        </ng-container>

        <ng-template ng-notfound-tmp>
          <div class="not-found">No items found</div>
        </ng-template>

        <ng-container *ngIf="allowAddNew">
          <ng-template ng-footer-tmp>
            <button class="no-underline button-text edit-dropdown" type="button" (click)="onAddNew()">
              {{ addNewLabel }}
            </button>
          </ng-template>
        </ng-container>

        <ng-template ng-label-tmp>
          <ng-container
            [ngTemplateOutlet]="customValueTemplate || defaultValueTemplate"
            [ngTemplateOutletContext]="{ value: control.value }"></ng-container>
        </ng-template>

        <ng-template ng-multi-label-tmp>
          <div
            *ngIf="isShowDisplayValue"
            class="display-flex grid-gap-8 align-items-center dropdown-value-style"
            [ngClass]="[valueCss, isLabelInside ? 'inside' : '']"
            [matTooltipDisabled]="!displayLabelAsValue"
            [matTooltip]="displayValue">
            {{ displayValue }}
          </div>
        </ng-template>

        <ng-container
          [ngTemplateOutlet]="controlSectionItems?.length ? separatorListListTemplate : defaultListTemplate"
          [ngTemplateOutletContext]="{ itemList: controlItems }"></ng-container>

        <ng-template #defaultListTemplate let-itemList="itemList" let-showSeparator="showSeparator">
          <ng-option *ngIf="itemList[0]?.groupLabel" class="dropdown-option" [ngClass]="optionCss" disabled="disabled">
            {{ itemList[0]?.groupLabel }}
          </ng-option>
          <ng-container *ngFor="let item of itemList; let i = index">
            <ng-option
              class="dropdown-option"
              [class.dropdown-option--small-font]="isSmallFont"
              [ngClass]="optionCss"
              [value]="item.value"
              [disabled]="item.disabled">
              <div class="display-flex grid-gap-8 align-items-center option-container">
                <div class="checkbox-icon"></div>
                <ng-container
                  [ngTemplateOutlet]="customListItemTemplate ?? defaultListItemTemplate"
                  [ngTemplateOutletContext]="{ item: item }"></ng-container>
              </div>
            </ng-option>
            <ng-option
              *ngIf="dividerPosition === i"
              class="dropdown-option"
              [class.dropdown-option--small-font]="isSmallFont"
              [ngClass]="optionCss"
              disabled="disabled">
              <mat-divider></mat-divider>
            </ng-option>
          </ng-container>

          <ng-option
            *ngIf="showSeparator"
            class="dropdown-option"
            [class.dropdown-option--small-font]="isSmallFont"
            [ngClass]="optionCss"
            disabled="disabled">
            <mat-divider></mat-divider>
          </ng-option>
        </ng-template>

        <ng-template #separatorListListTemplate>
          <ng-container *ngFor="let item of controlSectionItems; let last = last">
            <ng-container
              [ngTemplateOutlet]="defaultListTemplate"
              [ngTemplateOutletContext]="{ itemList: item, showSeparator: !last }"></ng-container>
          </ng-container>
        </ng-template>
      </ng-select>
    </div>
    <app-input-error
      *ngIf="errorSection && errors"
      [errors]="errors"
      [visible]="markAsInvalid || (errors && showValidationErrorOrSubmitted)"></app-input-error>
  </ng-container>
  <ng-container *ngIf="displaySkeleton">
    <div class="content-placeholder skeleton-loader w-100" [ngClass]="placeholderCss"></div>
  </ng-container>
</div>

<ng-template #switchTemplate>
  <div class="display-flex align-items-center justify-content-center dropdown-switch-container" *ngIf="showSwitch">
    <mat-button-toggle-group class="collections-properties-toggle-container" [value]="switchStatus">
      <mat-button-toggle
        class="collections-properties-toggle"
        [value]="true"
        [matTooltip]="'Collections'"
        (click)="onToggleSwitch(true)">
        <app-icon
          [svg]="EIcon.STAR"
          [iconWidth]="18"
          [iconHeight]="18"
          [iconColor]="switchStatus ? EColorPalette.cGreen : EColorPalette.cGray5"></app-icon>
      </mat-button-toggle>
      <mat-button-toggle
        class="collections-properties-toggle"
        [value]="false"
        [matTooltip]="'Properties'"
        (click)="onToggleSwitch(false)">
        <app-icon
          [svg]="EIcon.HOME"
          [iconWidth]="18"
          [iconHeight]="18"
          [iconColor]="!switchStatus ? EColorPalette.cGreen : EColorPalette.cGray5"></app-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>

<ng-template #labelTemplate>
  <ng-container *ngIf="!displaySkeleton">
    <div *ngIf="label || icon" #labelElement class="label-container label-height">
      <label
        *ngIf="label"
        class="dropdown-label"
        [ngClass]="[
          labelCss,
          isLabelInside ? 'inside' : '',
          attrDisable ? 'disable' : '',
          labelRequired ? 'label-required' : ''
        ]"
        [matTooltip]="labelTooltip"
        [matTooltipShowDelay]="tooltipShowDelay">
        {{ label }}
      </label>
      <app-icon [matTooltip]="iconTooltip" *ngIf="icon" [svg]="icon" [iconHeight]="24" [iconWidth]="24"></app-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="displaySkeleton && label">
    <div class="label-placeholder skeleton-loader" [ngStyle]="{ 'width.px': label.length * 10 }"></div>
  </ng-container>
</ng-template>

<ng-template #defaultListItemTemplate let-item="item">
  <div
    *ngIf="isSmallFont"
    [attr.data-label]="isLabelInside"
    class="dropdown-option small-font"
    [ngClass]="[
      optionCss,
      isLabelInside ? 'inside' : '',
      attrDisable ? 'disable' : '',
      item.disabled ? 'dropdown-option-disabled' : ''
    ]"
    [innerHTML]="item.label | escapeHtml"
    [title]="item.tooltip || item.label | escapeHtml"></div>
  <div
    *ngIf="!isSmallFont"
    [attr.data-label]="isLabelInside"
    class="dropdown-option"
    [ngClass]="[
      optionCss,
      isLabelInside ? 'inside' : '',
      attrDisable ? 'disable' : '',
      item.disabled ? 'dropdown-option-disabled' : ''
    ]"
    [innerHTML]="item.label | escapeHtml"
    [title]="item.tooltip || item.label | escapeHtml"></div>
</ng-template>

<ng-template #defaultValueTemplate>
  <div
    class="display-flex grid-gap-8 align-items-center dropdown-value-style"
    [ngClass]="[valueCss, isLabelInside ? 'inside' : '', attrDisable ? 'dropdown-value-style-disabled' : '']"
    [class.small-font]="isSmallFont">
    {{ displayValue | escapeHtml: allowAngelBrackets }}
  </div>
</ng-template>
