import { Injectable } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs';

import { DeviceType } from './constants/device-size.constants';
import { isDesktop, isDesktopSmallOrSmaller, isMobile, isTablet, mapWidthToDevice } from './constants/device-type';
import { NativeEventService } from '../native-event.service';

interface DeviceInfo {
  isMobileUserAgent: boolean;
  isTabletUserAgent: boolean;
  width: number;
  height: number;
  orientation: 'portrait' | 'landscape';
}

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private readonly userAgent = navigator.userAgent.toLowerCase();
  private readonly isIOS = /ipad|iphone|ipod/.test(this.userAgent);
  private readonly isAndroid = /android/.test(this.userAgent);
  private readonly isWindowsPhone = /windows phone/.test(this.userAgent);
  private readonly isTabletUserAgent =
    /ipad/.test(this.userAgent) || (/android/.test(this.userAgent) && !/mobile/.test(this.userAgent));

  windowEvent$: Observable<Window> = this.nativeEventService.resize$.pipe(startWith(window));

  private deviceInfo$: Observable<DeviceInfo> = this.windowEvent$.pipe(
    map(w => ({
      isMobileUserAgent: this.isIOS || this.isAndroid || this.isWindowsPhone,
      isTabletUserAgent: this.isTabletUserAgent,
      width: w.innerWidth,
      height: w.innerHeight,
      orientation: w.innerHeight > w.innerWidth ? ('portrait' as const) : ('landscape' as const),
    })),
    distinctUntilChanged(
      (prev, curr) => prev.width === curr.width && prev.height === curr.height && prev.orientation === curr.orientation
    ),
    shareReplay(1)
  );

  currentWidth$ = this.deviceInfo$.pipe(
    map(info => info.width),
    distinctUntilChanged()
  );

  orientation$ = this.deviceInfo$.pipe(
    map(info => info.orientation),
    distinctUntilChanged(),
    shareReplay(1)
  );

  deviceType$: Observable<DeviceType> = this.deviceInfo$.pipe(
    map(info => {
      if (info.isMobileUserAgent && !info.isTabletUserAgent) {
        return mapWidthToDevice(Math.min(info.width, info.height));
      }
      if (info.isTabletUserAgent) {
        return 'tablet' as DeviceType;
      }
      return mapWidthToDevice(info.width);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isMobile$: Observable<boolean> = this.deviceType$.pipe(map(isMobile), shareReplay(1));
  isTablet$: Observable<boolean> = this.deviceType$.pipe(map(isTablet), shareReplay(1));
  isDesktop$: Observable<boolean> = this.deviceType$.pipe(map(isDesktop), shareReplay(1));

  isDesktopSmallOrSmaller$: Observable<boolean> = this.deviceType$.pipe(
    map(isDesktopSmallOrSmaller),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isMobileOrTablet$ = combineLatest([this.isMobile$, this.isTablet$]).pipe(
    map(([isMobile, isTablet]) => isMobile || isTablet)
  );

  isTabletOrDesktop$ = combineLatest([this.isTablet$, this.isDesktop$]).pipe(
    map(([isTablet, isDesktop]) => isTablet || isDesktop)
  );

  constructor(private nativeEventService: NativeEventService) {}
}
