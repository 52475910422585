import { Pipe, PipeTransform } from '@angular/core';

import { EmptyValues } from '@shared/enums/common.enum';

const CUSTOM_CURRENCY_DOTS_COMMAS_PATTERN = /\B(?=(\d{3})+(?!\d))/g;

@Pipe({
  name: 'customCurrencyDotsCommas',
})
export class CustomCurrencyDotsCommasPipe implements PipeTransform {
  transform(value: string | number | null): string {
    if (!value || value === EmptyValues.DASH) {
      return String(value);
    }

    const parts = String(value).split('.');
    const wholePart = parts[0];
    const fractionalPart = parts.length > 1 ? '.' + parts[1] : '';
    const withCommas = wholePart.replace(CUSTOM_CURRENCY_DOTS_COMMAS_PATTERN, ',');

    return '$' + withCommas + fractionalPart;
  }
}
