import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  CountryCodes,
  CountryPhoneCodes,
  FinlandValidPhoneNumberLength,
  LatviaValidPhoneNumberLength,
  ValidPhoneNumberLength_10,
} from '@shared/constants/country-phone-codes.const';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { parsePhoneNumber } from '@ui-components/validators/phone.validator';

@UntilDestroy()
@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputModule, RadioButtonDropdownModule, MatIconModule, NgIf, NgStyle, NgClass, InputErrorModule],
})
export class PhoneNumberInputComponent extends CustomControlAbstract<string> implements OnInit {
  @Input() attrLabel: string;
  @Input() defaultCode: number;
  @Input() displaySkeleton = false;
  @Input() phoneNumberAsIdentity = false;

  @Output() phoneNumberInvalid = new EventEmitter<boolean>();
  protected readonly countryCodes = CountryCodes;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  phoneCodeControl = new FormControl<number>(null);
  phoneNumberControl = new FormControl<string>(null);
  defaultMaskPattern = '(000) 000-0000';
  maskPattern = '(000) 000-0000';
  phoneCode: number;
  validPhoneNumberLength = ValidPhoneNumberLength_10;
  notValidFormat = false;

  ngOnInit(): void {
    if (this.defaultCode !== undefined && this.defaultCode !== null) {
      this.phoneCodeControl.setValue(this.defaultCode);
    }

    this.initControlBase();

    this.control.registerOnDisabledChange(isDisabled => {
      this.setDisabled(isDisabled);
    });

    this.setDisabled(this.control.disabled);
    this.parsePhoneNumber(this.control.value);

    this.onPhoneNumberControlChange();
    this.onPhoneCodeControlChange();
  }

  writeValue(value: any): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  private setDisabled(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneNumberControl.disable();
      this.phoneCodeControl.disable();
    } else {
      this.phoneNumberControl.enable();
      this.phoneCodeControl.enable();
    }
  }

  private validateAndEmitValue() {
    this.phoneCode = this.phoneCodeControl.value;
    if (this.phoneCode === CountryPhoneCodes.Latvia) {
      this.maskPattern = '(000) 000-00';
      this.validPhoneNumberLength = LatviaValidPhoneNumberLength;
    } else if (this.phoneCode === CountryPhoneCodes.Finland) {
      this.maskPattern = '(000) 000-000';
      this.validPhoneNumberLength = FinlandValidPhoneNumberLength;
    } else if (this.phoneCode === CountryPhoneCodes.Russia) {
      this.maskPattern = '000 000 00 00';
      this.validPhoneNumberLength = ValidPhoneNumberLength_10;
    } else if (this.phoneCode === CountryPhoneCodes.Mexico) {
      this.maskPattern = '(00) 00 00 00 00';
      this.validPhoneNumberLength = ValidPhoneNumberLength_10;
    } else {
      this.maskPattern = this.defaultMaskPattern;
      this.validPhoneNumberLength = ValidPhoneNumberLength_10;
    }
    const phoneNumberRaw = this.phoneNumberControl.value;
    if (phoneNumberRaw && this.phoneCode) {
      const phoneNumber = phoneNumberRaw.replace(/\D/g, '');
      this.onChanged(`+${this.phoneCode}${phoneNumber}`);
    } else {
      this.onChanged(``);
    }

    this.notValidFormat = phoneNumberRaw && this.phoneNumberControl.invalid;

    this.phoneNumberInvalid.emit(this.notValidFormat);
  }

  private parsePhoneNumber(value: string) {
    const { phoneCode, phoneNumber } = parsePhoneNumber(value, this.validPhoneNumberLength);
    if (phoneCode) {
      this.phoneCodeControl.setValue(parseInt(phoneCode), { emitEvent: false });
    }
    this.phoneNumberControl.setValue(phoneNumber, { emitEvent: false });
  }

  private onPhoneNumberControlChange(): void {
    this.phoneNumberControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        this.validateAndEmitValue();
      })
      .untilDestroyed(this);
  }

  private onPhoneCodeControlChange(): void {
    this.phoneCodeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.phoneNumberControl.setValue(null, { emitEvent: false });
          this.validateAndEmitValue();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }
}
