import { InspectionSectionsExpandState } from './interfaces';

export namespace InspectionsListConstants {
  export enum TimeFrame {
    Week = 1,
    Month,
    Month_3,
    Month_6,
    Year,
    AllTime,
    Custom,
    ThisWeek,
    NextTwoWeeks,
    ThisMonth,
    ThisQuarter,
    ThisYear,
  }

  export enum IncludeArchived {
    Yes = 1,
    No = 0,
  }

  export const displayedOneTimeColumns = [
    'unit.name',
    'unit.propertyName',
    'inspectionType',
    'assignee',
    'status',
    'rooms',
    'dueDate',
    'completedAt',
    'notifyWhenDone',
    'expired',
    'repeats',
    'actions',
  ];

  export const displayedRepeatingColumns = [
    'unit.name',
    'unit.propertyName',
    'inspectionType',
    'assignee',
    'until',
    'rooms',
    'dueDate',
    'repeats',
    'notifyWhenDone',
    'actions',
  ];

  export const displayedDynamicColumns = [
    'step',
    'unit.propertyName',
    'unit.portfolioName',
    'inspectionType',
    'assignee',
    'due',
    'notify',
    'until',
    'notifyWhenDone',
    'actions',
  ];

  export const displayedInReviewColumns = [
    'unit.name',
    'unit.propertyName',
    'inspectionType',
    'reviewer',
    'submitted',
    'rooms',
    'dueDate',
    'repeats',
    'actions',
  ];

  export const timeFrames = [
    { value: TimeFrame.Week, label: 'past 7 days' },
    { value: TimeFrame.Month, label: 'past 30 days' },
    { value: TimeFrame.Month_3, label: 'past 3 months' },
    { value: TimeFrame.Month_6, label: 'past 6 months' },
    { value: TimeFrame.Year, label: 'past year' },
    { value: TimeFrame.AllTime, label: 'all time' },
    { value: TimeFrame.Custom, label: 'custom' },
    { value: TimeFrame.ThisWeek, label: 'this week' },
    { value: TimeFrame.NextTwoWeeks, label: 'next two weeks' },
    { value: TimeFrame.ThisMonth, label: 'this month' },
    { value: TimeFrame.ThisQuarter, label: 'this quarter' },
    { value: TimeFrame.ThisYear, label: 'this year' },
  ];

  export const includeArchivedList = [
    { value: IncludeArchived.Yes, label: 'Yes' },
    { value: IncludeArchived.No, label: 'No' },
  ];

  export const sectionsExpandInitialState: InspectionSectionsExpandState = {
    Standard: true,
  };
}

export const ASSIGNEE_FILTER_DIVIDER_POSITION = 1;
