import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({ standalone: true, selector: '[appFadeTextOnWidth]' })
export class CheckRoleDirective implements AfterViewInit, OnChanges {
  @Input() appFadeTextOnWidth: string | number = 250;
  @Input() titleLength: number;
  @Input() onRecalculate: any;

  @HostListener('window:resize')
  onResize(): void {
    this.checkWidthAndApplyStyles();
  }

  constructor(private el: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.checkWidthAndApplyStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['titleLength']?.firstChange) this.checkWidthAndApplyStyles();

    if (changes['onRecalculate'] && !changes['onRecalculate'].firstChange) this.checkWidthAndApplyStyles();
  }

  private checkWidthAndApplyStyles(): void {
    const element = this.el.nativeElement;
    const parentElement = element.parentElement;

    const maxWidth = this.getMaxWidthInPixels(parentElement);
    const scrollWidth = element.scrollWidth;

    if (scrollWidth > maxWidth) {
      this.renderer.setStyle(element, 'max-width', `${maxWidth}px`);
      this.renderer.setStyle(element, 'overflow', 'hidden');
      this.renderer.setStyle(element, 'white-space', 'nowrap');
      this.renderer.setStyle(element, '-webkit-mask-image', 'linear-gradient(90deg, #000 80%, transparent)');
      this.renderer.setStyle(element, 'mask-image', 'linear-gradient(90deg, #000 80%, transparent)');
    } else {
      this.renderer.removeStyle(element, 'max-width');
      this.renderer.removeStyle(element, 'overflow');
      this.renderer.removeStyle(element, 'white-space');
      this.renderer.removeStyle(element, '-webkit-mask-image');
      this.renderer.removeStyle(element, 'mask-image');
    }

    if (this.onRecalculate) this.cdr.detectChanges();
  }

  private getMaxWidthInPixels(parentElement: HTMLElement): number {
    if (typeof this.appFadeTextOnWidth === 'number') return this.appFadeTextOnWidth;

    if (typeof this.appFadeTextOnWidth === 'string' && this.appFadeTextOnWidth.endsWith('%')) {
      const percent = parseFloat(this.appFadeTextOnWidth);
      if (!isNaN(percent) && parentElement) {
        const parentWidth = parentElement.offsetWidth;
        return (percent / 100) * parentWidth;
      }
    }
    return 250;
  }
}
